import React from 'react';
import AppButton from '../../shared/components/app-button/AppButton';
import {
  PayCover,
  PaySubscription,
  PaySubscriptionBox,
  PaySubscriptionBoxContainer,
  PaySubscriptionContainer,
  PaySubscriptionDate,
  PaySubscriptionPrice,
  PaySubscriptionSocial,
  PaySubscriptionText,
  PaySubscriptionTitle,
  PayWrapper,
} from './index.styles';
import instagram from '../../assets/icons/Instagram.svg';
import facebook from '../../assets/icons/facebook.svg';
import telegram from '../../assets/icons/telegram.svg';
import lock from '../../assets/icons/lock_close.svg';
import { useAuth } from '../../store/auth';
import { useNavigate } from 'react-router-dom';
import { RouterKeys } from '../../router/keys';

const PayContainer = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const navigateToPrice = async () => {
    await navigate(`${RouterKeys.HOME}`);
    const element: any = document.getElementById('priceId');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <PayWrapper>
      <PayCover>
        <img src={lock} alt="" />
      </PayCover>
      <PaySubscriptionContainer>
        <PaySubscription>
          <PaySubscriptionTitle>Доступ до всіх статей</PaySubscriptionTitle>
          <PaySubscriptionBoxContainer>
            <PaySubscriptionBox>
              <PaySubscriptionDate>3 місяці</PaySubscriptionDate>
              <PaySubscriptionPrice>999 грн</PaySubscriptionPrice>
            </PaySubscriptionBox>
            <PaySubscriptionBox>
              <PaySubscriptionDate>6 місяців</PaySubscriptionDate>
              <PaySubscriptionPrice>1699 грн</PaySubscriptionPrice>
            </PaySubscriptionBox>
          </PaySubscriptionBoxContainer>
          <AppButton
            text="Купити підписку"
            onClick={() => (isAuth ? navigateToPrice() : navigate('/sign-in'))}
          />
        </PaySubscription>
        <PaySubscriptionSocial>
          <PaySubscriptionText>
            Підпишіться на соціальні мережі
          </PaySubscriptionText>
          <img src={instagram} alt="instagram" />
          <img src={facebook} alt="facebook" />
          <img src={telegram} alt="telegram" />
        </PaySubscriptionSocial>
      </PaySubscriptionContainer>
    </PayWrapper>
  );
};

export default PayContainer;
