import React, { FC } from 'react';
import { ButtonText, ButtonWrapper } from './index.styles';

type IFilterButton = {
  text: string;
  onClick: any;
  width?: string;
  id: any;
};

const FilterButton: FC<IFilterButton> = ({
  text,
  onClick,
  width = '230px',
  id,
}) => (
  <ButtonWrapper onClick={() => onClick(id)} width={width}>
    <ButtonText> {text}</ButtonText>
  </ButtonWrapper>
);

export default FilterButton;
