import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabButtons = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
`;

export const TabButton = styled.button<{ isActive: boolean }>`
  background-color: transparent;
  border: none;
  font-family: Montserrat-semiBold;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#000' : '#686868')};
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  outline: none;
  border-bottom: ${({ isActive }) => (isActive ? '1px solid #000' : 'тщту')};
`;

export const TabContent = styled.div`
  padding: 10px;
  border-radius: 4px;
`;

export const ArticlesContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

`;
