import styled from 'styled-components';

export const UserSpan = styled.div`
  color: #0d1282;
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
`;

export const TableContainer = styled.div`
  margin: 20px;
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const TableHead = styled.thead`
  background-color: #f2f2f2;
`;

export const TableHeadCell = styled.th`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;
