export enum AdminEndpoints {
  LOGIN = 'auth-admin/login',
  VERIFY_TOKEN = 'auth-admin/verify-session',
  CREATE_POST = 'articles',
  UPDATE_POST = 'articles',
  CREATE_WEBINAR = 'webinars',
  GET_ALL_CATEGORIES = 'categories',
  UPLOAD_FILE = 'cloudinary/upload-image',
  UPLOAD_MAIN_IMAGE = 'upload-image',
  UPLOAD_VIDEO = 'upload-video',
  GET_ARTICLES = 'articles/admin',
  ALL_BLOCKED_USERS = 'user/all-user-block',
  ADD_USER_TO_BLOCK = 'user/add-block',
  REMOVE_USER_FROM_BLOCK = 'user',
  DELETE_ARTICLES = 'articles',
  UPDATE_ARTICLES_SLIDER = 'articles/slider',
}

export enum AuthEndpoints {
  LOGOUT = 'auth-diya/logout',
  LOGIN = 'auth-user/login',
  GOOGLE_LOGIN = 'auth-google/login',
  REGISTER = 'auth-user/signup',
  CHECK_SESSION = 'auth-user/verify-session',
}

export enum UserEndpoints {
  GET_ARTICLES = 'articles',
  GET_ARTICLE_BY_ID_ADMIN = 'articles/admin',
  GET_ARTICLES_SLIDER = 'articles/slider',
  GET_USER = 'user',
  GET_ALL_USERS = 'user/all',
  GET_WEBINARS = 'webinars',
  GET_WEBINAR_PURCHASES = 'user/webinar-purchases',
  CHANGE_PASSWORD = 'auth-user/change-password',
  ADD_FAVORITES = 'favorites/article',
  WEBINAR_PAYMENT = 'payment/create-webinar',

  VERIFY_EMAIL = 'auth-diya/verify-email',
  VERIFY_PHONE = 'auth-phone/verifyPhone',
  CONFIRM_EMAIL = 'auth-diya/confirm-email',
  SIGN_UP = 'auth-diya/sign-up',
  LOGIN_VALIDATE = 'auth-diya/login-validate',
  SIGNUP_PHONE = 'auth-phone/verifyPhoneSignup',
  RECAPTCHA_VERIFY = 'auth-phone/verifyRecaptcha',
  LOGIN_PHONE = 'auth-phone/login',
  ADD_NEW_DEVICE_LINK = 'fingerprint/generate-link-add',
  CHANGE_PASSWORD_PHONE = 'auth-phone/changePassword',
  RESET_PASSWORD_PHONE = 'auth-phone/resetPassword',
  ADD_NEW_DEVICE = 'fingerprint/add',
}

export enum ForgotPasswordEndpoints {
  RESET_PASSWORD = 'auth-user/reset-password',
  VERIFY_CODE = 'auth-user/verify-code',
  RESEND_VERIFICATION_CODE = 'auth-user/resend-code',
  SET_NEW_PASSWORD = 'auth-user/set-password',
}

export enum ChangePasswordEndpoints {
  CHANGE_PASSWORD = 'app/consumer/changePassword',
  CHECK_PASSWORD = 'app/consumer/check-password',
}
