import { createGlobalStyle } from 'styled-components';
import { COLORS } from '../../shared/theme';

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    font-size: 16px;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  body {
    font-family: Montserrat-Regular;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    background-color: ${COLORS.background};
    
  }

  ul[class],
  ol[class] {
    list-style: none;
  }
  swiper-container {
    z-index: 0;
 }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img {
    max-width: 100%;
    display: block;
  }

  .swiper-pagination-bullet-active {
    background-color: #686868 !important;
    width: 13px;
    height: 13px;
}
.swiper-pagination-bullet{
  background-color: #D3D3D3 !important;
  width: 13px;
  height: 13px;
  @media only screen and (max-width:400px ) {
    width: 9px;
    height: 9px;
  }
}

.swiper-pagination {
  position: relative;
  text-align: center;
  margin-top: 40px;
  z-index: 10;
  @media only screen and (max-width:400px ) {
    margin: 20px 20px 0 0;
  }
}

  article > * + * {
    margin-top: 1em;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  `;
