import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {
  Container,
  Image,
  SliderContainer,
  SlideText,
  SlideTextName,
} from './slider.styled';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { userService } from '../../services/user.service';
import { showToastError } from '../toast/toast';
import { LoaderContainer } from '../../../modules/articles/index.styles';
import AppLoader from '../loader/loader';

interface IArticle {
  id: number;
  featured_image: string;
  author: string;
  title: string;
}

function HorizontalSlider() {
  const [isLoading, setIsLoading] = useState(true);
  const [listOfArticles, setListOfArticles] = useState<IArticle[]>();

  const { refetch } = useQuery<any, any>(
    ['articles'],
    () => userService.getArticlesSlider(),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setListOfArticles(res.data.data);
        setIsLoading(false);
      },
      onError: () => {
        showToastError('Щось пішло не так');
      },
    }
  );

  if (isLoading)
    return (
      <LoaderContainer>
        <AppLoader />
      </LoaderContainer>
    );

  return (
    <SliderContainer>
      <Swiper
        pagination={{ clickable: true }}
        slidesPerView={3}
        spaceBetween={20}
        navigation
        loop={true}
        centeredSlides={true}
        initialSlide={2} // Start with the second slide as centered
        //effect="coverflow" // Apply coverflow effect
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          slideShadows: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {listOfArticles?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Link
                to={`/articles/${item.id}`}
                style={{ textDecoration: 'none' }}
              >
                <Container>
                  <Image src={item.featured_image} alt="Image 1" />
                  <SlideTextName>{item.author}</SlideTextName>
                  <SlideText>{item.title}</SlideText>
                </Container>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SliderContainer>
  );
}

export default HorizontalSlider;
