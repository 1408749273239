export const STORAGE_KEYS = {
  JWT_TOKEN: 'JWT_TOKEN',
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'USER_EMAIL',
};

export const USER_KEYS = {
  LOGIN_DIYA: 'LOGIN_DIYA',
};

export const VIDEO_KEYS = {
  GET_VIDEO_BY_ID: 'GET_VIDEO_BY_ID',
};
