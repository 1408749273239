import React from 'react';
import {
  BannerContainer,
  BannerDesc,
  BannerText,
  BannerTitle,
  SliderContainer,
  UkraineImage,
} from './Banner.styles';
import ukraineIcon from '../../../../assets/icons/ukraine.svg';
import ImageSlider from '../../../../shared/components/sliders/image-slider';
import { useWindowSize } from '../../../../shared/hooks/useScreenSize';

const Banner = () => {
  const { width } = useWindowSize();

  return (
    <BannerContainer>
      <BannerText>
        <BannerTitle>
          Українська науково-освітня платформа
          <br /> для лікарів стоматологів
        </BannerTitle>
        <BannerDesc>
          Найсучасніші статті, вебінари та протоколи з усього світу,
          <br /> українською мовою на основі доказової медицини.
        </BannerDesc>
        {/*<DiiaGerb>
          <DiiaImage src={diiaIcon} alt="Diia" />
          <DiiaImage src={gerb} width="150" alt="Герб" />
        </DiiaGerb>*/}
        <UkraineImage src={ukraineIcon} alt="ukraine" />
      </BannerText>
      {width > 657 && (
        <SliderContainer>
          <ImageSlider initialSlide="0" />
          <ImageSlider reverseDirection={true} />
          <ImageSlider initialSlide="6" />
        </SliderContainer>
      )}
    </BannerContainer>
  );
};

export default Banner;
