import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../../../shared/components/app-button/AppButton';
import { FormInput } from '../../../../shared/components/form-input';
import { adminService } from '../../../../shared/services/admin/admin.service';
import { useAdminAuth } from '../../../../store/admin-auth';
import { LoginContainer } from './index.styles';

const PaymentForm = () => {
  const navigate = useNavigate();

  const { isAuth } = useAdminAuth();
  //useAdminAuthCheck();

  useEffect(() => {
    if (isAuth) navigate('/admin');
  }, [isAuth]);

  const mutation = useMutation(
    async (data: any) =>
      adminService.login({
        email: data.email,
        password: data.password,
      }),
    {
      onSuccess: (res) => {
        useAdminAuth.getState().updateAuth(true)();
        navigate('/admin');
      },
      onError: (error: any) => {
        useAdminAuth.getState().updateAuth(false)();
      },
    }
  );

  const submit = async (values: any) => {
    try {
      mutation.mutate({ email: values.email, password: values.password });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LoginContainer>
    <Form
      onSubmit={submit}
      initialValues={{ email: '', password: '' }}
      //validate={validate}
      render={({ handleSubmit }) => (
        <>
          <div>
            <label>email</label>
            <FormInput name="email" passedValue={''} />
          </div>
          <div>
            <label>password</label>
            <FormInput name="password" passedValue={''} />
          </div>
          <div>
            <AppButton text="Sign in" onClick={() => handleSubmit()} />
          </div>
        </>
      )}
    />
    </LoginContainer>
  );
};

export default PaymentForm;
