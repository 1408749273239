import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  ArticlesWrapper,
} from '../../../modules/article/index.styles';
import { ArticlesListContainer } from '../../../modules/articles/index.styles';
import {
  ArticlesTitle,
  ArticlesTitleText,
} from '../../../modules/home/components/articles/home-articles.style';
import { userService } from '../../services/user.service';
import ArticleCard from '../article-card/article';
import { showToastError } from '../toast/toast';

const Recommendation = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [listOfArticles, setListOfArticles] = useState<any>();
  const navigate = useNavigate();

  const articles = useQuery<any, any>(
    ['articles', { limit, page }],
    () => userService.getArticles(limit, page),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setListOfArticles(res.data.data);
      },
      onError: () => {
        showToastError('Щось пішло не так');
      },
    }
  );

  const chooseArticleHandle = (id: number) => {
    navigate(`/articles/${id}`);
  };

  return (
    <>
      <ArticlesTitle>
        <ArticlesTitleText style={{ margin: 0 }}>
          Рекомендації
        </ArticlesTitleText>
      </ArticlesTitle>
      <ArticlesWrapper style={{ marginTop: 90 }}>
        <ArticlesListContainer>
          {listOfArticles !== undefined &&
            listOfArticles.map((article: any) => {
              return (
                <ArticleCard
                  onClick={chooseArticleHandle}
                  id={article.id}
                  name={article.author}
                  title={article.title}
                  image={article.featured_image}
                  category={article.categories[0]}
                />
              );
            })}
        </ArticlesListContainer>
        <ToastContainer />
      </ArticlesWrapper>
    </>
  );
};

export default Recommendation;
