import styled from 'styled-components';
import { COLORS } from '../../theme';

export const Container = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${COLORS.greyPrimary};
  border-radius: 32px;
  color: ${({ isWhite }) => (isWhite ? 'white' : 'black')};
  height: 360px;
  padding: 40px 60px;
  @media only screen and (max-width:1336px ) {
    padding: 20px 50px;
    height: 300px;
  }
  @media only screen and (max-width:1030px ) {
    padding: 20px 30px;
    height: 260px;
  }
  @media only screen and (max-width: 630px) {
    padding: 15px 15px;
    height: 160px;
    border-radius: 16px;
  }
  @media only screen and (max-width: 480px) {
    padding: 10px 15px;
    height: 140px;
  }
  @media only screen and (max-width: 630px) {
    padding: 10px 15px;
  }
`;

export const PriceData = styled.div`
  font-family: 'Montserrat-Medium';
  font-size: 24px;
  @media only screen and (max-width:1030px ) {
    font-size: 21px;
  }
  @media only screen and (max-width: 679px) {
    font-size: 19px;
  }
  @media only screen and (max-width: 589px) {
    font-size: 12px;
  }
`;

export const PriceText = styled.div`
  font-family: 'Montserrat-Bold';
  font-size: 40px;
  @media only screen and (max-width:1030px ) {
    font-size: 34px;
  }
  @media only screen and (max-width: 630px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 589px) {
    font-size: 18px;
  }
`;
