import { AuthEndpoints, UserEndpoints } from '../constants/endpoints-keys';
import { HttpFactoryService } from './http-factory.service';
import { HttpService } from './http.service';
import { mainAxios } from './mainAxios';

class FingerprintService {
  constructor(private readonly httpService: HttpService) {}

  public async addNewDeviceLink(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.ADD_NEW_DEVICE_LINK}`,
      body,
      {
        headers: {
          'authorization-iqdental': process.env.REACT_APP_TOKEN,
        },
      }
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async addNewDevice(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.ADD_NEW_DEVICE}`,
      body,
      {
        headers: {
          'authorization-iqdental': process.env.REACT_APP_TOKEN,
        },
      }
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }
}

const factory = new HttpFactoryService();
export const fingerprintService = new FingerprintService(
  factory.createHttpService()
);
