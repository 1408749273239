import React, { FC } from 'react';
import ButtonLoader from '../loader/ButtonLoader';
import { ButtonWrapper, DiiaButtonWrapper, DiiaIcon } from './AppButton.styled';
import diiaIcon from '../../../assets/icons/diia-outline.svg';

type IDiiaButton = {
  text: string;
  onClick: () => void;
  width?: string;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: boolean;
};

const DiiaButton: FC<IDiiaButton> = ({
  text,
  onClick,
  width = '100%',
  disabled,
  isLoading = false,
  icon = true,
}) => (
  <DiiaButtonWrapper
    onClick={onClick}
    type="submit"
    width="100%"
    disabled={disabled}
  >
    {isLoading ? (
      <ButtonLoader />
    ) : (
      <>
        {text}
        {icon && <DiiaIcon src={diiaIcon} alt="Diia" />}
      </>
    )}
  </DiiaButtonWrapper>
);

export default DiiaButton;
