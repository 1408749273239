import React, { FC, ChangeEvent } from 'react';
import {
  CheckboxInput,
  CheckboxLabel,
  CustomCheckbox,
  LabelText,
} from './index.styles';

interface IAppCheckbox {
  name: string;
  label: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: boolean;
}

const AppCheckbox: FC<IAppCheckbox> = ({ name, label, checked, onChange }) => {
  return (
    <CheckboxLabel>
      <CheckboxInput
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <CustomCheckbox checked={checked}>{checked && '✔'}</CustomCheckbox>
      <LabelText>{label}</LabelText>
    </CheckboxLabel>
  );
};

export default AppCheckbox;
