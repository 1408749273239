import React, { FC, useEffect, useRef, useState } from 'react';
import {
  NameWrapper,
  UserToolsContainer,
  UserToolsImage,
  UserToolsModal,
  UserToolsModalImage,
  UserToolsModalItem,
  UserToolsWrapper
} from './UserTools.styled';
import arrow from '../../../../assets/icons/arrowDown.svg';
import logoutIcon from '../../../../assets/icons/logout.svg';
import userIcon from '../../../../assets/icons/user.svg';
import { useQuery } from 'react-query';
import { userService } from '../../../services/user.service';
import { STORAGE_KEYS } from '../../../constants/app-keys';
import ButtonLoader from '../../loader/ButtonLoader'

type IUserTools = {
  logoutHandler: () => void;
  goToUser: () => void;
};

const UserTools: FC<IUserTools> = ({  logoutHandler, goToUser }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dropdownRef: any = useRef();
  const [user, setUser] = useState<any>();
  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME);

  const { isLoading } = useQuery<any, any>(
    ['user'],
    () => userService.getUser(),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setUser(res.data);
      },
      onError: () => {},
    }
  );


  //const { name, surname } = useContext(UserContext);

  useEffect(() => {
    const handler = (e: Event) => {
      if (!dropdownRef.current?.contains(e.target)) {
        setIsOpenModal(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => document.removeEventListener('mousedown', handler);
  }, []);

  return (
    <UserToolsWrapper ref={dropdownRef}>
      <UserToolsContainer onClick={() => setIsOpenModal(!isOpenModal)}>
        <NameWrapper>
        {user?.name !== undefined ? user.name.split(' ')[1] : <ButtonLoader/>}
        </NameWrapper>
        <UserToolsImage src={arrow} alt="" />
      </UserToolsContainer>
      {isOpenModal && (
        <UserToolsModal>
          <UserToolsModalItem
            onClick={() => {
              setIsOpenModal(false);
              goToUser();
            }}
          >
            <UserToolsModalImage src={userIcon} alt="" />
            Свій кабінет
          </UserToolsModalItem>
          <UserToolsModalItem
            onClick={() => {
              setIsOpenModal(false);
              logoutHandler();
            }}
          >
            <UserToolsModalImage src={logoutIcon}  alt="" />
            Вийти
          </UserToolsModalItem>
        </UserToolsModal>
      )}
    </UserToolsWrapper>
  );
};

export default UserTools;
