import { AuthEndpoints, UserEndpoints } from '../constants/endpoints-keys';
import { HttpFactoryService } from './http-factory.service';
import { HttpService } from './http.service';
import { mainAxios } from './mainAxios';

class UserService {
  constructor(private readonly httpService: HttpService) {}

  public async getWebinars(limit: number, page: number) {
    const res = await this.httpService.get(
      `${UserEndpoints.GET_WEBINARS}?limit=${limit}&skip=${(page - 1) * limit}`
    );
    return res;
  }

  public async getWebinarById(id: any) {
    return await this.httpService.get(`${UserEndpoints.GET_WEBINARS}/${id}`);
  }

  public async getWebinarsByCategory(id: any, limit: number, page: number) {
    return await this.httpService.get(
      `${UserEndpoints.GET_WEBINARS}/${id}/category?limit=${limit}&skip=${
        (page - 1) * limit
      }`
    );
  }

  public async getArticles(limit: number, page: number) {
    const res = await this.httpService.get(
      `${UserEndpoints.GET_ARTICLES}?limit=${limit}&skip=${(page - 1) * limit}`
    );
    return res;
  }

  public async getArticlesByCategory(id: any, limit: number, page: number) {
    return await this.httpService.get(
      `${UserEndpoints.GET_ARTICLES}/${id}/category?limit=${limit}&skip=${
        (page - 1) * limit
      }`
    );
  }

  public async getArticlesById(id: any) {
    const res = await this.httpService.get(
      `${UserEndpoints.GET_ARTICLES}/${id}`
    );
    return res;
  }

  public async checkSession(): Promise<any> {
    return this.httpService.get(AuthEndpoints.CHECK_SESSION);
  }

  public async logout(): Promise<any> {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.get(`${baseUrl}/${AuthEndpoints.LOGOUT}`, {
      withCredentials: true,
    });
    //return this.httpService.get(AuthEndpoints.LOGOUT);
  }

  public async getUser(): Promise<any> {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.get(`${baseUrl}/${UserEndpoints.GET_USER}`, {
      withCredentials: true,
    });
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async changePassword(body: any) {
    return this.httpService.post(UserEndpoints.CHANGE_PASSWORD, body);
  }

  public async changeNameAndMobile(body: any) {
    return this.httpService.put(UserEndpoints.GET_USER, body);
  }

  public async addFavoritesArticle(id: any) {
    return this.httpService.post(UserEndpoints.ADD_FAVORITES, {
      articleId: id,
    });
  }

  public async verifyPhone(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.VERIFY_PHONE}`,
      body
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async signUp(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    console.log(body);
    return await mainAxios.post(`${baseUrl}/${UserEndpoints.SIGN_UP}`, body);
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async confirmEmail(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.CONFIRM_EMAIL}`,
      body
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async signupPhone(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.SIGNUP_PHONE}`,
      body
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async verifyRecaptchaToken(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.RECAPTCHA_VERIFY}`,
      body
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async changePasswordPhone(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.CHANGE_PASSWORD_PHONE}`,
      body
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async resetPasswordPhone(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.RESET_PASSWORD_PHONE}`,
      body
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async loginByPhone(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.LOGIN_PHONE}`,
      body
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async addNewDeviceLink(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.ADD_NEW_DEVICE_LINK}`,
      body,
      {
        headers: {
          'authorization-iqdental': process.env.REACT_APP_TOKEN,
        },
      }
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }

  public async addNewDevice(body: any) {
    const baseUrl: string = process.env.REACT_APP_SERVER_URL || '';
    return await mainAxios.post(
      `${baseUrl}/${UserEndpoints.ADD_NEW_DEVICE}`,
      body,
      {
        headers: {
          'authorization-iqdental': process.env.REACT_APP_TOKEN,
        },
      }
    );
    //return this.httpService.get(UserEndpoints.GET_USER);
  }
}

const factory = new HttpFactoryService();
export const userService = new UserService(factory.createHttpService());
