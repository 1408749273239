import React, { FC } from 'react';
import ButtonLoader from '../loader/ButtonLoader';
import { ButtonWrapper } from './AppButton.styled';

type IAppButton = {
  text: string;
  onClick: () => void;
  width?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const AppButton: FC<IAppButton> = ({
  text,
  onClick,
  width = '100%',
  disabled,
  isLoading = false,
}) => (
  <ButtonWrapper
    onClick={onClick}
    type="submit"
    width={width}
    disabled={disabled}
  >
    {isLoading ? <ButtonLoader /> : text}
  </ButtonWrapper>
);

export default AppButton;
