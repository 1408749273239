import React, { useState } from 'react';
import { paymentService } from '../../services/payment.service';
import LiqPayFormComponent from '../form-liqpay';

interface IPaymentData {
  data: string;
  signature: string;
}

const PaymentComponent = () => {
  const [paymentData, setPaymentData] = useState<IPaymentData | null>(null);
  const [amount, setAmount] = useState(0);

  const getPaymentForm = async () => {
    const response = await paymentService.createPayment(amount);
    setPaymentData(response?.data);
  };
  return (
    <div>
      <input
        type="number"
        value={amount}
        onChange={e => setAmount(Number(e.target.value))}
        placeholder="Enter amount"
      />
      <button onClick={getPaymentForm}>Получить форму оплаты</button>
      {paymentData && (
        <LiqPayFormComponent
          data={paymentData.data}
          signature={paymentData.signature}
        />
      )}
    </div>
  );
};

export default PaymentComponent;
