import styled from 'styled-components';
import { COLORS } from '../../../shared/theme';

export const SubWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.primaryBlue};
  text-align: center;
  color: white;
  border-radius: 8px;
  padding: 60px 0;
  margin-bottom: 30px;
  @media only screen and (max-width:415px ) {
    padding: 23px 0;
  }
`;

export const UnSubWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.secondaryGrey};
  text-align: center;
  color: white;
  border-radius: 8px;
  padding: 60px 0;
  margin-bottom: 30px;
  @media only screen and (max-width:415px ) {
    padding: 23px 0;
  }
`;

export const SubHeader = styled.div`
  font-family: Montserrat-Bold;
  font-size: 36px;
  @media only screen and (max-width:818px ) {
    font-size: 29px;
  }
  @media only screen and (max-width:415px ) {
    font-size: 24px;
  }
`;

export const UnSubHeader = styled.div`
  font-family: Montserrat-Bold;
  font-size: 36px;
  margin-bottom: 20px;
  @media only screen and (max-width:818px ) {
    font-size: 29px;
  }
  @media only screen and (max-width:415px ) {
    font-size: 24px;
  }
`;

export const SubText = styled.div`
  font-family: Montserrat-Regular;
  font-size: 24px;
  margin: 16px 0 12px 0;
  @media only screen and (max-width:818px ) {
    font-size: 19px;
  }
  @media only screen and (max-width:415px ) {
    font-size: 14px;
  }
`;

export const SubExpire = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 28px;
  @media only screen and (max-width:818px ) {
    font-size: 21px;
  }
  @media only screen and (max-width:415px ) {
    font-size: 19px;
  }
`;
