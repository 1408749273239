import styled from 'styled-components';
import { COLORS } from '../../theme';

export const ButtonWrapper = styled.button<{
  width: string;
  disabled: boolean | undefined;
}>`
  width: ${({ width }) => width};
  font-family: 'Montserrat-Medium';
  background: ${({ disabled }) =>
    disabled ? COLORS.secondaryGrey : COLORS.primaryBlue};
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  font-size: 24px;
  color: ${({ theme }) => theme.COLORS.primaryWhite};
  padding: 12px 24px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#0D3ABD'};
  }
  &:active {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#011D46'};
  }
  @media only screen and (max-width: 1030px) {
    padding: 10px 20px;
  }
  @media only screen and (max-width: 1030px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 818px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 613px) {
    padding: 10px 4px;
  }
`;

export const SocialButtonWrapper = styled.button<{ width: string }>`
  width: ${({ width }) => width};
  font-family: 'Montserrat-Medium';
  background: ${COLORS.white};
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.black};
  padding: 12px 54px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  &:hover {
    border-color: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#0D3ABD'};
  }
  &:active {
    border-color: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#011D46'};
  }
  @media only screen and (max-width: 1118px) {
    padding: 12px 30px;
  }
  @media only screen and (max-width: 1030px) {
    padding: 8px 20px;
  }
  @media only screen and (max-width: 1030px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 818px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 408px) {
    font-size: 11px;
  }
`;

export const ButtonGreenWrapper = styled.button<{ width: string }>`
  width: ${({ width }) => width};
  font-family: 'Montserrat-Medium';
  background: ${({ theme }) => theme.COLORS.green};
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
  border-radius: 20px;
  color: ${({ theme }) => theme.COLORS.primaryWhite};
  padding: 9px 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#0D3ABD'};
  }
  &:active {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#011D46'};
  }
`;

export const ButtonOutlineWrapper = styled.button<{ width: string }>`
  width: ${({ width }) => width};
  font-family: 'Montserrat-Medium';
  border: 1px solid ${COLORS.secondaryGrey};
  border-radius: 8px;
  color: ${({ theme }) => theme.COLORS.blackText};
  padding: 12px 0;
  font-size: 24px;
  background: ${COLORS.transparent};
  text-align: center;
  cursor: pointer;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#A0AAC2'};
  }
  &:active {
    background: ${({ disabled }) =>
      disabled ? COLORS.secondaryGrey : '#011D46'};
  }

  @media only screen and (max-width: 1118px) {
    padding: 12px 30px;
  }
  @media only screen and (max-width: 1030px) {
    padding: 8px 20px;
  }
  @media only screen and (max-width: 1030px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 818px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 408px) {
    font-size: 11px;
  }
`;

export const DiiaButtonWrapper = styled.button<{
  width: string;
  disabled: boolean | undefined;
}>`
  width: ${({ width }) => width};
  font-family: 'Diia-Regular';
  background: #000;
  border-radius: 16px;
  display: flex;
  font-size: 24px;
  color: ${({ theme }) => theme.COLORS.primaryWhite};
  padding: 12px 24px;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  gap: 10px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: linear-gradient(to right, #04c65d, #5b5aff);
  }
  &:active {
    background: linear-gradient(to right, #007eff, #aa2b8e);
  }
  &:disabled {
    pointer-events: none;
  }
  @media only screen and (max-width: 1030px) {
    padding: 10px 20px;
  }
  @media only screen and (max-width: 1030px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 818px) {
    font-size: 14px;
  }
`;

export const DiiaIcon = styled.img`
  width: 40px;
  height: 40px;
`;
