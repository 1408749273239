import styled from 'styled-components';
import { COLORS, MEDIA } from '../../../theme';

export const UserToolsImage = styled.img`
  margin: 0 0 0 10px;
  &:hover {
    filter: brightness(10%) contrast(148%);
  }
`;

export const UserToolsModalImage = styled.img`
  margin: 0 10px 0 0;
  &:hover {
    filter: invert(50%) sepia(99%) saturate(5630%) hue-rotate(246deg) brightness(80%) contrast(148%);
  }
`;

export const UserToolsWrapper = styled.div`
  z-index: 200;
  //@media only screen and (max-width: ${MEDIA.tablet_s}) {
  //  position: relative;
  //  right: 0;
  //  top: 0;
  //}
`;

export const UserToolsContainer = styled.div`
  background: ${COLORS.primaryWhite};
  border-radius: 33px;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: space-between;
  &:hover ${UserToolsImage} {
    filter: brightness(10%) contrast(148%);
  }
 
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  @media only screen and (max-width:818px ) {
    font-size: 15px;
  }
`;

export const UserToolsModal = styled.div`
  background: ${COLORS.primaryWhite};
  border-radius: 8px;
  border: 1px solid #A0AAC2;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0 0 30px 0px;
  right: 30px;
  text-align: center;
  padding: 18px 29px;
  //@media only screen and (max-width: ${MEDIA.tablet_s}) {
  //  position: absolute;
  //  top: 75px;
  //}
`;

export const UserToolsModalItem = styled.div`
  display: flex;
  color: ${COLORS.blackText};
  font-size: 16px;
  margin: 5px 0 0 0;
  cursor: pointer;
  &:hover {
    color: ${COLORS.purple};
  }
  &:hover ${UserToolsModalImage} {
    filter: invert(50%) sepia(99%) saturate(5630%) hue-rotate(246deg) brightness(80%) contrast(148%);
  }
`;
