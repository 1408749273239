export class RouterKeys {
  public static ALL_MATCH = '/*';

  public static ROOT = '/';
  public static ADD_NEW_DEVICE = 'add-new-device';

  public static HOME = '/';

  public static USER = 'user';

  public static GET_STARTED = 'get-started';

  public static SIGN_IN = 'sign-in';

  public static LOGIN = 'login';

  public static REGISTRATION = 'registration';

  public static SIGN_UP = 'sign-up';

  public static SUCCESS = 'success';

  public static FORGOT_PASSWORD = '/forgot-password';

  public static FORGOT_PASSWORD_CODE = '/forgot-password/code';

  public static FORGOT_PASSWORD_PASSWORD = '/forgot-password/password';

  public static FORGOT_PASSWORD_SUCCESS = '/forgot-password/success';

  public static CHECK_EMAIL = 'check-email';

  public static VERIFY = 'verify';

  public static PAYMENT = 'payment';

  public static ARTICLES = `articles`;

  public static WEBINARS = `webinars`;

  public static ARTICLE_BY_ID = `article/:id`;

  public static WEBINAR_BY_ID = `webinar/:id`;

  public static WEBINAR_VIDEO_BY_ID = `webinar/video`;

  public static POLICY = `/policy`;

  public static OFFER_CONTRACT = `/offer-contract`;

  //ADMIN ROUTERS
  public static ADMIN = 'admin';

  public static ADMIN_CREATE_WEBINARS = 'admin/webinars/create';

  public static ADMIN_WEBINARS = 'admin/webinars';

  public static ADMIN_CREATE_ARTICLES = 'admin/articles/create';
  public static ADMIN_UPDATE_ARTICLES = 'admin/articles/update/:id';

  public static ADMIN_ARTICLES = 'admin/articles';

  public static ADMIN_LOGIN = 'admin/sign-in';

  public static ADMIN_BLACKLIST = 'admin/blacklist';

  public static ADMIN_REGISTER = 'admin/sign-up';

  public static ADMIN_ALL_MATCH = 'admin/*';
}
