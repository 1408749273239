import styled from 'styled-components'; 

export const LoginContainer = styled.div`
  display: flex;
  height: 100hv;
  width: 100%;
  justify-content: center;
  background: white;
 
`;
