import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { AdminContainerWrapper } from './index.styles';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const AdminContainer = () => {
  return (
    <AdminContainerWrapper>
      <Navbar />
      <Sidebar />
      <Outlet />
    </AdminContainerWrapper>
  );
};

export default AdminContainer;
