import React, { useState } from 'react';
import * as yup from 'yup';
import { useWindowSize } from 'shared/hooks/useScreenSize';
import {
  DiiaContainerForm,
  ImageContainer,
  LoginFormContainer,
  LoginImage,
  LoginTitle,
  MailForm,
  SideText,
  SideTextTitle,
  SideTitle,
} from './index.styles';

// @ts-ignore
// import backImage from '../../../assets/image/202312241515.gif';
import backImage from '../../../assets/image/logo-phone.jpg';
import { FormInput } from 'shared/components/form-input';
import { Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { userService } from 'shared/services/auth.service';
import SignUpForm from 'shared/components/signup';
import SignInForm from 'shared/components/signin';
import { phoneValidationSchema } from './validate-phone';

const AppLogin = () => {
  let phoneString = '';
  const [phone, setPhone] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const [formError, setFormError] = useState('');
  const [clickVerify, setClickVerify] = useState(false);
  const [verifyAuth, setVerifyAuth] = useState(false);

  const verify = useMutation(
    async (body: any) => userService.verifyPhone(body),
    {
      onSuccess: (res: any) => {
        setIsPhoneVerified(res.data);
        setVerifyAuth(true);
      },

      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  const verifyPhone = async (values: { phoneConfirm: string }) => {
    setFormError('');
    try {
      await phoneValidationSchema.validate(values, { abortEarly: false });
      localStorage.setItem('phone', values.phoneConfirm);
      setPhone(values.phoneConfirm);
      phoneString = values.phoneConfirm;
      verify.mutate({
        phone: values.phoneConfirm,
      });
      setClickVerify(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setFormError(error?.errors?.[0]);
      }
    }
  };

  const { width } = useWindowSize();
  return (
    <>
      <LoginFormContainer>
        <LoginTitle>Увійти</LoginTitle>
        <DiiaContainerForm>
          <MailForm>
            <Form
              onSubmit={verifyPhone}
              initialValues={{
                phoneConfirm: localStorage.getItem('phone') || '',
              }}
              // validate={() => {}} //from Alex
              render={({ handleSubmit }) => (
                <>
                  <label>Контактный номер телефона</label>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '4px',
                      height: '100%',
                    }}
                  >
                    <FormInput name="phoneConfirm" passedValue="380" />
                    <button
                      disabled={clickVerify}
                      style={{
                        height: '50px',
                        borderRadius: '8px',
                        borderWidth: '1px',
                        color: 'white',
                        background: 'black',
                        cursor: 'pointer',
                      }}
                      onClick={handleSubmit}
                    >
                      Клац**
                    </button>
                  </div>
                  {!isPhoneVerified && (
                    <SideText>
                      Ні хто не подзвонив?{' '}
                      <span
                        onClick={handleSubmit}
                        style={{
                          fontSize: 14,
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        Повторить дзвінок
                      </span>
                    </SideText>
                  )}

                  {formError && (
                    <div style={{ color: 'red', marginTop: '8px' }}>
                      {formError}
                    </div>
                  )}
                </>
              )}
            />
          </MailForm>
          {/*{isCodeSended && (*/}
          {/*  <RegistrationForm*/}
          {/*    handleEmailConfirm={handleEmailConfirm}*/}
          {/*    email={email}*/}
          {/*  />*/}
          {/*)}*/}
          {/*{!isPhoneVerified ? <SignUpForm phone={phone} /> : <></>}*/}
          {/*{verifyAuth && isPhoneVerified && <SignInForm phone={phone} />}*/}

          {verifyAuth &&
            (isPhoneVerified ? (
              <SignInForm phone={phone} />
            ) : (
              <SignUpForm phone={phone} />
            ))}
        </DiiaContainerForm>
      </LoginFormContainer>
      {width > 800 && (
        <ImageContainer>
          <LoginImage
            src={backImage}
            alt="img"
            style={{ borderRadius: '32px' }}
          />
          {/*{isPhoneVerified ? (*/}
          {/*  <LoginImage*/}
          {/*    src={backImage}*/}
          {/*    alt="img"*/}
          {/*    style={{ borderRadius: '32px' }}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <>*/}
          {/*    <div>*/}
          {/*      <SideTitle>Альтернативний метод входу</SideTitle>*/}
          {/*      <SideText>*/}
          {/*        Ми створили альтернативний вхід для тих в кого{' '}*/}
          {/*        <span>відсутні ID картка, або закордоний паспорт</span> в Дії,*/}
          {/*        адже без цих документів ви не можете використовувати дія*/}
          {/*        підпис.*/}
          {/*      </SideText>*/}
          {/*      <SideTextTitle />*/}
          {/*      /!*<SideText>*!/*/}
          {/*      /!*  Цей метод входу не працює на телефонах, для нього вам потрібно*!/*/}
          {/*      /!*  мати 2 девайса(ПК чи планшет).*!/*/}
          {/*      /!*</SideText>*!/*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*)}*/}
        </ImageContainer>
      )}
    </>
  );
};

export default AppLogin;
