import styled from 'styled-components';

export const PolicyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PolicyContainer = styled.div`
  max-width: 1240px;
  margin: 0 20px;
  //  flex-direction: column;
`;

export const PolicyTitle = styled.p`
  font-family: Montserrat-SemiBold;
  font-size: 17px;
  margin: 10px 0 10px 0;
`;

export const PolicyText = styled.p`
  font-family: Montserrat-Medium;
  font-size: 14px;
`;

export const PolicyTextTitle = styled.p`
  font-family: Montserrat-Medium;
  font-size: 14px;
  margin: 10px 0;
`;
