import Cookies from 'js-cookie';
import { UserEndpoints } from '../constants/endpoints-keys';
import { HttpFactoryService } from './http-factory.service';
import type { HttpService } from './http.service';

class PaymentService {
  constructor(private readonly httpService: HttpService) {}

  public async getToken() {
    const token = Cookies.get('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return config;
  }

  public async createPaymentSub(amount: number, idSub: number) {
    return await this.httpService.get('payment/create-sub', {
      params: {
        amount: amount,
        idSub: idSub,
      },
      ...(await this.getToken()),
    });
  }
  public async createWebinarPaymentWebinar(amount: number, idWebinar: number) {
    return await this.httpService.get('payment/create-webinar', {
      params: {
        amount: amount,
        idWebinar: idWebinar,
      },
      ...(await this.getToken()),
    });
  }
}

const factory = new HttpFactoryService();
export const paymentService = new PaymentService(factory.createHttpService());
