import styled from 'styled-components';
import { COLORS } from '../../../../shared/theme';

export const HomeContainer = styled.div`
  width: 100%;
`;

export const BannerContainer = styled.div`
  width: 100%;
  max-width: 1800px;
  height: 630px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #010101 0%, #011d45 50%, #013482 100%);
  @media only screen and (max-width: 981px) {
    height: 450px;
  }
`;

export const BannerText = styled.div`
  color: ${COLORS.primaryWhite};
  font-size: 28px;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  @media only screen and (max-width: 981px) {
    margin: 0 20px;
  }
`;

export const BannerTitle = styled.h1`
  font-family: Montserrat-SemiBold;
  color: ${COLORS.primaryWhite};
  font-size: 36px;
  @media only screen and (max-width: 1200px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 981px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 657px) {
    font-size: 27px;
  }
  @media only screen and (max-width: 452px) {
    font-size: 19px;
  }
  @media only screen and (max-width: 337px) {
    font-size: 14px;
  }
`;

export const BannerDesc = styled.h2`
font-family: Montserrat-Medium;
  color: ${COLORS.primaryWhite};
  font-size: 18px;
  @media only screen and (max-width: 981px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 657px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 452px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 337px) {
    font-size: 9px;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  gap: 40px;
  pointer-events: none;
  margin: 0 120px 0 0;
  z-index: 1;
  @media only screen and (max-width: 1365px) {
    gap: 20px;
  }
  @media only screen and (max-width: 1074px) {
    margin: 0 40px 0 0;
  }
  @media only screen and (max-width: 981px) {
    gap: 10px;
  }
  @media only screen and (max-width: 679px) {
    margin: 0 10px 0 0;
  }
`;

export const UkraineImage = styled.img`
  margin:  100px 0 0 0;
  @media only screen and (max-width: 981px) {
    margin:  50px 0 0 0;
    width: 150px;
  }
`;

export const DiiaGerb = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 120px;
`;

export const DiiaImage = styled.img`
 width: 80px;
 @media only screen and (max-width: 440px) {
  width: 30px;
}
`;