import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import AppLoader from '../../shared/components/loader/loader';
import { showToastError } from '../../shared/components/toast/toast';
import { useAuthCheck } from '../../shared/hooks/use-auth-check.hook';
import { useWindowSize } from '../../shared/hooks/useScreenSize';
import { userService } from '../../shared/services/user.service';
import {
  ArticleAuthor,
  ArticleAuthorTitle,
  ArticleCategory,
  ArticleReturnBack,
  ArticleReturnImage,
  ArticleTitle,
} from '../article/index.styles';
import {
  WebinarPlan,
  WebinarPlanText,
  WebinarPlanTitle,
  WebinarsContainer,
  WebinarsWrapper,
} from '../webinar/index.styles';
import arrow from '../../assets/icons/arrow-rigth.svg';
import ReactPlayer from 'react-player';
import { useGetVideoById } from '../../shared/hooks/useGetVideoById';
import { videoCipherService } from '../../shared/services/video-cipher.service';
import { VIDEO_KEYS } from '../../shared/constants/app-keys';

const WebinarVideoPage = () => {
  const playerRef = useRef(null);
  let { id } = useParams<any>();
  const [playbackInfo, setPlaybackInfo] = useState('');
  const [otp, setOtp] = useState('');
  const [webinarData, setWebinarData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useLayoutEffect(() => {
    setIsLoading(true);
    setIsVideoLoading(true);
  }, []);

  const onReady = (event: any) => {
    // Disable user interactions with the video player
    event.target.setOptions &&
      event.target.setOptions({
        playerVars: { controls: 0, disablekb: 1, modestbranding: 1 },
      });
  };

  //useAuthCheck();

  const webinarVideoData = useQuery<any, any>(
    ['webinarsById', { id }],
    () => userService.getWebinarById(id),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setWebinarData(res.data);
        setIsLoading(false);
        //setTotalItems(res.data.total);
      },
      onError: () => {
        setIsLoading(false);
        navigate('/');
      },
    }
  );

  const { getVideoById } = useGetVideoById(
    videoCipherService.getVideoById,
    VIDEO_KEYS.GET_VIDEO_BY_ID
  );

  useEffect(() => {
    const fetchData = async () => {
      if (webinarData?.id_video && !isLoading) {
        const data = {
          idVideo: webinarData?.id_video,
        };
        try {
          const response = await getVideoById.mutateAsync(data);
          if (response?.otp) {
            setOtp(response.otp);
          }
          if (response?.playbackInfo) {
            setPlaybackInfo(response.playbackInfo);
            setIsVideoLoading(false);
          }
        } catch (error) {
          console.error('Failed:', error);
          setIsVideoLoading(false);
        }
      }
    };

    fetchData();
  }, [webinarData?.id_video]);

  if (isLoading) return <AppLoader />;
  return (
    <WebinarsWrapper>
      {/*<YouTube
        videoId={'VnjdikpgR0E'}
        //  containerClassName="youtube-container"
        onReady={onReady}
        ref={playerRef}
      />*/}
      {/*<ReactPlayer*/}
      {/*    url="https://www.youtube.com/watch?v=vg1OP8mJAM8"*/}
      {/*    controls={true}*/}
      {/*    width="640px"*/}
      {/*    height="360px"*/}
      {/*  />*/}

      <WebinarsContainer>
        <ArticleReturnBack onClick={() => navigate(-1)}>
          <ArticleReturnImage src={arrow} alt="" />
          Назад
        </ArticleReturnBack>
        {isVideoLoading ? (
          <AppLoader />
        ) : (
          otp &&
          playbackInfo && (
            <div>
              <iframe
                src={`https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`}
                style={{ border: '0', width: '720px', height: '405px' }}
                allow="encrypted-media"
                allowFullScreen
              ></iframe>
            </div>
          )
        )}
        <ArticleTitle>{webinarData?.title && webinarData.title}</ArticleTitle>
        <ArticleAuthor>
          <ArticleAuthorTitle>Автор:</ArticleAuthorTitle>{' '}
          {webinarData?.author && webinarData?.author}
        </ArticleAuthor>
        <ArticleCategory>
          {webinarData?.categories[0].name && webinarData.categories[0].name}
        </ArticleCategory>
        {/*<WebinarContainer>
          <WebinarImageContainer>
            <WebinarImage src={sliderImag1} alt="" />
            {width >= 1050 && (
              <AppButton
                text="Купити"
                onClick={() => navigate(`/${RouterKeys.WEBINAR_VIDEO_BY_ID}/2`)}
                width={'100%'}
              />
            )}
          </WebinarImageContainer>
        </WebinarContainer>*/}
        <WebinarPlan>
          <WebinarPlanTitle>Програма вебінару</WebinarPlanTitle>
          <WebinarPlanText>
            {webinarData?.plan && webinarData.plan}
          </WebinarPlanText>
        </WebinarPlan>
        {/*<Recommendatio />*/}
      </WebinarsContainer>
      {/*<iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/VnjdikpgR0E?rel=0`}
          frameBorder="0"
          allowFullScreen
          title=""
        ></iframe>*/}
    </WebinarsWrapper>
  );
};

export default WebinarVideoPage;
