import React, { FC } from 'react';
import { PageButton, PageItem, PaginationList } from './index.styles';

function Pagination({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}: any) {

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <PaginationList>
      {pages.map(page => (
        <PageItem key={page}>
          <PageButton
            isActive={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </PageButton>
        </PageItem>
      ))}
    </PaginationList>
  );
}

export default Pagination;
