import styled from 'styled-components';

export const Image = styled.img`
  //max-height: 100%;
  width: 100%;
  margin-top: 20px;
  height: 230px;
  overflow: hidden;
  border-radius: 8px;
  @media only screen and (max-width: 370px) {
    height: 100%;
  }
`;
export const SliderContainer = styled.div`
  width: 100%;
`;

export const SliderTextWrapper = styled.div`
  @media only screen and (max-width: 407px) {
    font-family: 'Montserrat-SemiBold';
    font-size: 14px;
    padding: 8px 12px;
  }
`;

export const SlideText = styled.div`
  font-size: 18px;
  font-family: 'Montserrat-Bold';
  color: #000000;
  margin-top: 10px;
  width: 100%;
  line-height: 1;
  overflow-wrap: break-word;
  min-height: 55px;
  text-align: start;
  @media only screen and (max-width: 407px) {
    font-family: 'Montserrat-SemiBold';
    font-size: 14px;
    color: #000000;
  }
`;

export const ArticleContainer = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 360px;
  cursor: pointer;
  border: none;
  background-color: #ffff;
  height: 370px;
  border-radius: 8px;
  padding-top: 20px;
  margin-bottom: 20px;
  transition: background-color 0.5s;
  &:hover ${SlideText} {
    text-shadow: rgba(13, 18, 130, 0.5) 1px 0 10px;
  }
  @media only screen and (max-width: 407px) {
    //width: 100%;
    padding: 0px;
    height: auto;
    margin-bottom: 20px;
  }
`;

export const SlideTextName = styled.div`
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  margin-top: 10px;
  color: #0d3abd;
  line-height: 14px;
  text-align: start;
  @media only screen and (max-width: 407px) {
    font-size: 12px;
  }
`;

export const SlideCategory = styled.div`
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  margin-top: 10px;
  color: #686868;
  line-height: 14px;
  text-align: start;
`;
