import styled from 'styled-components';
import { COLORS } from '../../../../shared/theme';

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 80px 0 0 0;
  //@media only screen and (max-width: 1300px) {
  //  margin: 400px 0 0 0;
  //}
  //@media only screen and (max-width: 962px) {
  //  margin: 350px 0 0 0;
  //}
  //@media only screen and (max-width: 759px) {
  //  margin: 300px 0 0 0;
  //}
`;

export const PriceContainer = styled.div`
  max-width: 1240px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PriceTitle = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 36px;
  margin: 34px 0 10px 0;
  @media only screen and (max-width: 1030px) {
    font-size: 30px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 610px) {
    font-size: 18px;
    margin-top: 10px;
  }
`;

export const PriceImage = styled.img`
  @media only screen and (max-width: 1030px) {
    width: 55px;
  }
  //@media only screen and (max-width: 610px) {
  //  width: 35px;
  //}
`;

export const PriceDesc = styled.div`
  font-family: OpenSans-Regular;
  text-align: center;
  font-size: 24px;
  color: ${COLORS.greyPrimary};
  margin: 0 20px 48px 20px;
  @media only screen and (max-width: 1030px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 720px) {
    font-size: 15px;
  }
`;

export const PriceBoxContainer = styled.div`
  display: flex;
  gap: 72px;
  @media only screen and (max-width: 763px) {
    gap: 42px;
  }
  @media only screen and (max-width: 446px) {
    gap: 15px;
    flex-direction: column;
  }
`;
