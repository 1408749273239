import axios from 'axios';
import { getCookie } from '../utils';

const token = getCookie('token');
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

class VideoCipherService {
  async getVideoById(data: {
    idVideo: string;
  }): Promise<{ otp: string; playbackInfo: string }> {
    try {
      const response = await axios.post('/cipher-video', data);
      return response?.data;
    } catch (e) {
      throw e;
    }
  }
}

export const videoCipherService = new VideoCipherService();
