import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  //width: 400px;
  //height: 450px;
  padding: 20px;
  filter: blur(3px);
  transform: scale(1);
  transition:
    transform 0.4s,
    filter 0.4s;
  transition:
    box-shadow 0.4s,
    filter 0.4s;
  .swiper-slide-active & {
    transform: scale(1.1);
    filter: blur(0);
  }
  //@media only screen and (max-width:1300px ) {
  //  width: 350px;
  //  height: 400px;
  //  padding: 0px;
  //}
  @media only screen and (max-width: 760px) {
    gap: 12px;
  }
  @media only screen and (max-width: 550px) {
    padding: 10px 24px;
    align-items: center;
  }
  @media only screen and (max-width: 400px) {
    padding: 10px 24px;
  }
  @media only screen and (max-width: 382px) {
    padding: 14px 44px;
    padding-top: 14px;
  }
  @media only screen and (max-width: 330px) {
    padding: 16px 60px;
  }
  //@media only screen and (max-width:320px ) {
  //  //margin-right: 20px;
  //}
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 490px;
  border-radius: 5px;
`;
export const SliderContainer = styled.div`
  width: 100%;
  //position: absolute;
  //overflow: hidden;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  @media only screen and (max-width: 400px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: 400px) {
    padding: 0 30px;
  }
`;

export const SlideText = styled.p`
  font-size: 18px;
  font-family: 'Montserrat-SemiBold';
  margin-top: 10px;
  color: #000;
  margin-left: 10px;
  line-height: 1;
  text-align: start;
  @media only screen and (max-width: 1003px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 550px) {
    font-size: 12px;
    // margin-top: 5px;
    text-align: center;
  }
  @media only screen and (max-width: 400px) {
    font-size: 10px;
    margin-left: 0px;
  }
`;

export const SlideTextName = styled.div`
  font-size: 18px;
  margin-left: 10px;
  font-family: 'Montserrat-Medium';
  margin-top: 10px;
  color: #0d3abd;
  line-height: 14px;
  text-align: start;
  @media only screen and (max-width: 1003px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 760px) {
    // font-size: 10px;
    margin-top: 0px;
  }
  @media only screen and (max-width: 400px) {
    // font-size: 9px;
    // margin-left: 10px;
  }
`;

export const BannerImage = styled.img`
  width: 190px;
  height: 190px;
  border-radius: 15px;
  @media only screen and (max-width: 1392px) {
    width: 140px;
    height: 140px;
  }
  @media only screen and (max-width: 981px) {
    width: 90px;
    height: 90px;
  }
`;
