import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SidebarLogo } from 'shared/components/admin/AdminSidebar.styled';
import { RouterKeys } from 'router/keys';

import Logo from '../../../assets/icons/logo.svg';
import {
  NavbarAdminContainer,
  NavbarAdminUser,
  NavbarAdminWrapper,
} from 'shared/components/admin/AdminNavbar.styled';

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <NavbarAdminWrapper>
      <NavbarAdminContainer>
        <SidebarLogo onClick={() => navigate(RouterKeys.ADMIN)}>
          <img src={Logo} alt="logo" />
        </SidebarLogo>
      </NavbarAdminContainer>
    </NavbarAdminWrapper>
  );
};

export default Navbar;
