import AuthWrapper from 'modules/auth/login';
import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHelp from '../help';
import { FormContainer } from './index.styled';

const SignInContainer = () => {
  return (
    <FormContainer>
      <AuthWrapper>
        <Outlet />
      </AuthWrapper>
      <AppHelp />
    </FormContainer>
  );
};

export default SignInContainer;
