import styled from 'styled-components';
import { COLORS, MEDIA } from '../../theme';

export const NavbarAdminWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-items: stretch;
  width: 100%;
  z-index: 66666;
  box-shadow: rgba(13, 21, 132, 0.24) 0px 10px 8px;
  background-color: #fcfcfc;
`;

export const NavbarAdminContainer = styled.div`
  flex-grow: 1;
`;

export const NavbarAdminUser = styled.div``;
