import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterKeys } from '../../../router/keys';
import AppButton from '../../../shared/components/app-button/AppButton';
import { useWindowSize } from '../../../shared/hooks/useScreenSize';
import { useAuth } from '../../../store/auth';
import { UnSubHeader, UnSubWrapper } from './subscription.styles';

const UnActiveSubscription = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const navigateToPrice = async () => {
    await navigate(`${RouterKeys.HOME}`);
    const element: any = document.getElementById('priceId');
    element.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <UnSubWrapper>
      <UnSubHeader>Підписка неактивна</UnSubHeader>
      <AppButton
        text="Оформити підписку"
        onClick={() => (isAuth ? navigateToPrice() : navigate('/sign-in'))}
        width={width < 415 ? '190px' : '350px'}
      />
    </UnSubWrapper>
  );
};

export default UnActiveSubscription;
