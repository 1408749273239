import React from 'react';
import { PolicyContainer, PolicyText, PolicyTextTitle, PolicyTitle, PolicyWrapper } from './index.styles';

const PolicyPage = () => {
  return (
    <PolicyWrapper>
      <PolicyContainer>
      <PolicyTitle></PolicyTitle>
        <PolicyTitle>Вступ</PolicyTitle>
        <PolicyText>1. Конфіденційність користувачів нашого веб-сайту (IQDental.com.ua) має велике значення для нас, і ми докладаємо всіх зусиль для забезпечення її захисту. Дані правила пояснюють, як ми використовуємо вашу персональну інформацію.</PolicyText>
        <PolicyText>2. Погоджуючись із використанням файлів-cookie при першому відвіданні нашого веб-сайту (IQDental.com.ua) , відповідно до положень даних Правил, ви надаєте нам дозвіл на використання файлів-cookie при кожному вашому наступному візиті.</PolicyText>
        <PolicyTitle>Права інтелектуальної власності</PolicyTitle>
        <PolicyText>Копіювати статті, протоколи чи записувати вебінари з платформи IQDental.com.ua – заборонено. Ми залишаємо за собою право блокувати користувачів за плагіат інформації. Будьте чемними та відносьтесь до нашої інтелектуальної праці з повагою. Давайте підвищувати рівень української стоматології разом. </PolicyText>
        <PolicyTitle>Збір персональних даних</PolicyTitle>
        <PolicyText></PolicyText>
        <PolicyText> Збору, зберіганню та використанню підлягають наступні типи персональних даних:</PolicyText>
        <PolicyText></PolicyText>
        <PolicyText>1. Інформація про ваш комп’ютер, включаючи вашу IP-адресу, географічне розташування, тип і версію браузера та операційну систему;</PolicyText>
        <PolicyText>2. Інформація про ваші відвідування та використання цього веб-сайту, включаючи реферальні джерела, протяжність візитів, переглянуті сторінки та шляхи навігації по сайту;</PolicyText>
        <PolicyText>3. Інформація про адресу вашої електронної пошти, яку ви використали для реєстрації на нашому веб-сайті;</PolicyText>
        <PolicyText>4. Інформація, яку ви ввели під час створення профілю на нашому веб-сайті – наприклад, ваше ім’я, зображення у вашому профілі, стать, дата народження, сімейний статус, хобі та інтереси, освіта та місце роботи;</PolicyText>
        <PolicyText>5. Інформація, така, як ваше ім’я та адреса електронної пошти, які ви вказали під час оформлення підписок на наші поштові повідомлення та/або розсилки;</PolicyText>
        <PolicyText>6. Інформація, яку ви ввели під час використання сервісів нашого веб-сайту;</PolicyText>
        <PolicyText>7. Інформація, яка генерується при використанні нашого веб-сайту, включаючи інформацію про час, частоту та умови його використання;</PolicyText>
        <PolicyText>8. Інформація стосовно будь-яких ваших покупок, використаних сервісів або транзакцій, які ви провели через наш веб-сайт, включаючи ваше ім’я, адресу, номер телефону, адресу електронної поштової скриньки та інформацію про кредитну карту;</PolicyText>
        <PolicyText>9. Інформація, яку ви розмістили на нашому сайті з метою публікації її в Інтернеті, включаючи ваше ім’я користувача, зображення профілю та зміст вашої публікації;</PolicyText>
        <PolicyText>10. Інформація, що міститься в будь-яких повідомленнях, які ви надсилали нам електронною поштою або через наш веб-сайт, включаючи зміст повідомлення та мета дані;</PolicyText>
        <PolicyText>11. Будь-яка інша персональна інформація, яку ви надіслали нам. Перед тим, як розкрити для нас персональну інформацію третьої особи, ви маєте отримати згоду цієї особи як на розкриття, так і на обробку цієї інформації у відповідності до даних правил.</PolicyText>
        <PolicyTitle>Використання вашої персональної інформації</PolicyTitle>
        <PolicyText>Персональна інформація, яку ви передані нам через наш веб-сайт, буде використовуватися задля цілей, зазначених в цих правилах або на відповідних сторінках веб-сайту. Ми можемо використовувати вашу персональну інформацію в наступних цілях:</PolicyText>
        <PolicyText>1. адміністрування нашого веб-сайту та бізнесу;</PolicyText>
        <PolicyText>2. персоналізація нашого веб-сайту для вас;</PolicyText>
        <PolicyText>3. надання вам можливості користуватися сервісами, доступними на нашому веб-сайті;</PolicyText>
        <PolicyText>4. надсилання вам товарів, придбаних через наш-веб-сайт;</PolicyText>
        <PolicyText>5. надання вам послуг, придбаних через наш веб-сайт;</PolicyText>
        <PolicyText>6. надсилання вам повідомлень, рахунків та нагадувань про сплату, та отримання платежів від вас;</PolicyText>
        <PolicyText>7. надсилання вам немаркетингових комерційних повідомлень;</PolicyText>
        <PolicyText>8. надсилання вам електронною поштою повідомлень, які ви спеціально запросили;</PolicyText>
        <PolicyText>9. надсилання вам електронної розсилки, якщо ви її замовили (ви в будь-який момент можете повідомити нас, що більше не бажаєте отримувати електронні розсилки від нас);</PolicyText>
        <PolicyText>10. надсилання вам маркетингових повідомлень стосовно нашої ділової активності або ділової активності старанно відібраних сторонніх компаній, яка, на нашу думку, може вас зацікавити, шляхом публікацій або, якщо ви конкретно надали на це згоду – шляхом надсилання електронної пошти або за рахунок використання подібних технологій (ви в будь-який момент можете повідомити нас, що більше не бажаєте отримувати маркетингові повідомлення);</PolicyText>
        <PolicyText>11. надання стороннім компаніям статистичної інформації про наших користувачів (проте, ці сторонні компанії не матимуть змоги ідентифікувати жодного окремого користувача з цих даних);</PolicyText>
        <PolicyText>12. обробка запитів та скарг, зроблених вами або на вас, і які стосуються нашого веб-сайту;</PolicyText>
        <PolicyText>13. з метою забезпечення безпеки нашого сайту та попередження шахрайства;</PolicyText>
        <PolicyText>14. з метою перевірки відповідності умовам та правилам, які регламентують використання нашого веб-сайту (включаючи моніторинг приватних повідомлень, надісланих через сервіс приватних повідомлень нашого веб-сайту) та в інших цілях.</PolicyText>
        <PolicyTextTitle>Якщо ви надали персональну інформацію для публікації на нашому веб-сайті, ми опублікуємо її. В іншому випадку, ми використовуватимемо цю інформацію у відповідності до ліцензії, яку ви нам надали.</PolicyTextTitle>
        <PolicyTextTitle>Ваші налаштування конфіденційності можуть використовуватись для обмеження публікації ваших персональних даних на нашому веб-сайті, і можуть регулюватися за допомогою засобів управління конфіденційністю на веб-сайті.</PolicyTextTitle>
        <PolicyTextTitle>Без вашої чітко вираженої згоди ми не будемо передавати вашу персональну інформацію жодній сторонній компанії для прямого маркетингового використання цією, або будь-якою іншою сторонньою компанією.</PolicyTextTitle>
        <PolicyTextTitle></PolicyTextTitle>
        <PolicyTitle>Розкриття персональної інформації</PolicyTitle>
        <PolicyTextTitle>Ми лишаємо за собою право розкрити вашу персональну інформацію для будь-якого з наших працівників, керівників, страхувальників, професійних радників, агентів, постачальників або субпідрядників, в об’ємі та цілях, визначених в цих правилах.</PolicyTextTitle>
        <PolicyTextTitle>Ми лишаємо за собою право розкрити вашу персональну інформацію:</PolicyTextTitle>
        <PolicyText>1. в тих випадках, в яких цього від нас вимагає закон;</PolicyText>
        <PolicyText>2. у зв’язку з будь-яким поточними або майбутніми судовими процесами;</PolicyText>
        <PolicyText>3. з метою встановлення, реалізації або захисту наших законних прав (включаючи надання інформації іншим сторонам задля запобігання шахрайству або зниження кредитних ризиків);</PolicyText>
        <PolicyText>4. покупцеві (або потенційному покупцеві) будь-якого бізнесу або активів, які ми продаємо (або збираємося продати); та будь-якій особі, яка, як ми обґрунтовано вважаємо, може подати запит до суду або іншого уповноваженого органу про розкриття цих персональних даних і, на нашу обґрунтовану думку, цей суд або уповноважений орган видасть розпорядження на розкриття цих персональних даних.
Ми не будемо розкривати вашу персональну інформацію третім особам, за виключенням випадків, зазначених в цих правилах.</PolicyText>
<PolicyTitle>Збереження персональної інформації</PolicyTitle>
<PolicyTextTitle>Цей розділ встановлює правила та процедури компанії щодо збереження персональної інформації.</PolicyTextTitle>
<PolicyTextTitle>Дані правила та процедури призначені для забезпечення виконання нами наших юридичних зобов’язань щодо збереження та видалення персональної інформації.Персональна інформація, яку ми обробляємо з певною метою або в певних цілях не повинна зберігатися довше, ніж потрібно для досягнення цієї мети або цих цілей. </PolicyTextTitle>
<PolicyTitle>Захист вашої персональної інформації</PolicyTitle>
        <PolicyText>1. Ми будемо вживати достатні технічні та організаційні заходи для попередження втрати, протиправного використання чи підробки вашої персональної інформації.</PolicyText>
        <PolicyText>2. Всю надану вами персональну інформацію ми будемо зберігати на наших захищених (як паролем, так і фаєрволами) серверах.</PolicyText>
        <PolicyText>3. Всі електронні фінансові транзакції, здійснені за допомогою нашого сайту, будуть захищені технологією шифрування даних.
</PolicyText>
        <PolicyText>4. Ви підтверджуєте своє ознайомлення з тим фактом, що передача інформації через Інтернет є по суті є незахищеною, і ми не можемо гарантувати захист даних, надісланих через всесвітню мережу.</PolicyText>
        <PolicyText>5. Ви несете повну відповідальність за збереження свого пароля для доступу на наш веб-сайт в таємниці. Ми ніколи не будемо запитувати ваш пароль (за виключенням випадків, коли ви намагаєтесь увійти до свого облікового запису на нашому сайті).</PolicyText>
        <PolicyTitle>Зміни та поправки</PolicyTitle>
        <PolicyText>Ми лишаємо за собою право періодично вносити зміни та поправки в ці правила, та публікувати їх нову редакцію на нашому сайті. Ви повинні періодично перевіряти цю веб-сторінку, щоб пересвідчитись, що розумієте зміст змін, внесених до цих правил. Ми також можемо проінформувати вас про внесення змін до цих правил шляхом надсилання електронної пошти або через систему передачі приватних повідомлень нашого сайту.</PolicyText>
        <PolicyTitle>Зміни та поправки</PolicyTitle>
        <PolicyTitle>Ціни та вартість послуг</PolicyTitle>
        <PolicyText>1. Вартість конкретного Продукту Виконавця, пакету Послуг/Продуктів Виконавця визначається Виконавцем та розміщується на веб-сторінці замовлення послуг на Сайті Виконавця або в електронних повідомленнях, надісланих ним Користувачу.</PolicyText>
        <PolicyText>2. Загальна вартість Договору складається із загальних сум, оплачених Користувачем.</PolicyText>
        <PolicyText>3. Надання Послуг/надання доступу до Продукту здійснюється за умови 100% передоплати Продукту Виконавця, пакету Послуг/Продукту Виконавця.</PolicyText>
        <PolicyText>4. Про можливість здійснення оплати Продукту Виконавця частинами, Користувачу слід уточнювати безпосередньо у Виконавця.</PolicyText>
        <PolicyText>5. Вартість послуг може бути вказана у еквіваленті іноземної валюти. Остаточна вартість Послуг/Продукту фіксується у виставленому Виконавцем рахунку та відображається на веб-сторінці оплати послуг на Сайті Виконавця.</PolicyText>
        <PolicyText>6. Оплата наданих Виконавцем Послуг здійснюється Користувачем у безготівковій формі у національній валюті України (UAH) або іншим способом, узгодженим між Сторонами.</PolicyText>
        <PolicyTitle>Умови та спосіб оплати</PolicyTitle>
        <PolicyText>1. У разі оплати Послуг на Сайті Виконавця, в момент оформлення замовлення відбувається разове списання суми вартості замовлених Послуг/Продукту Виконавця на наведених нижче умовах.</PolicyText>
        <PolicyText>2. Країна здійснення платежу відповідає країні реєстрації платника.</PolicyText>
        <PolicyText>3. Валюта платежу – українська гривня (UAH). Ціна може бути виражена в еквіваленті іноземної валюти.</PolicyText>
        <PolicyText>4. При оплаті Послуг з допомогою мобільного банкінгу сума списання визначається виходячи з курсу міжнародних платіжних систем VISA або MasterCard. Крім того, сума списання може включати додаткові комісії банків та платіжних систем.</PolicyText>
        <PolicyTitle>Невиконання платіжних зобов'язань:</PolicyTitle>
        <PolicyText>1. Виконавець залишає за собою право пред'являти претензії щодо порушення встановлених строків оплати Послуг Користувачем.</PolicyText>
        <PolicyText>2. У разі неможливості списання коштів з платіжної картки Користувача або чергового платежу, Виконавець має право не починати надання Послуг/надання доступу до Продукту або призупинити їх надання до моменту їх належної оплати.</PolicyText>
        <PolicyText>3. Користувач може ознайомитись із доступними способами оплати Послуг/Продуктів Виконавця на Сайті Виконавця.</PolicyText>
        <PolicyText>4. У разі оплати послуг банківським переказом, Користувач зобов'язаний повідомити Виконавця про здійснений платіж і надіслати зображення/скрін документу, що підтверджують оплату Послуг/Продукту Виконавця за адресою електронної пошти, вказаною на Сайті Виконавця.</PolicyText>
        <PolicyText>5. Виконавець зберігає за собою право, в будь-який момент, на свій розсуд, змінити спосіб оплати Послуг/Продукту Виконавця, що купується, і/або відмовитися від запропонованого раніше способу оплати, запропонувавши альтернативні способи оплати.</PolicyText>
      </PolicyContainer>
    </PolicyWrapper>
  );
};

export default PolicyPage;
