import styled from 'styled-components';

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 10px 0 20px 0;
`;

// Define a styled input for the checkbox
export const CheckboxInput = styled.input`
  margin-right: 8px;
`;

// Define a styled custom checkbox
export const CustomCheckbox = styled.div<{checked: boolean}>`
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
//  background-color: ${({ checked }) => (checked ? '#007bff' : 'transparent')};
  align-items: center;
  color: ${(checked) => (checked ? '#000' : '#333')};
`;

// Define a styled label text
export const LabelText = styled.span`
  font-size: 16px;
  font-family: OpenSans-Regular;
  margin-left: 10px;
`;