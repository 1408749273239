import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import Router from '../../router/router';

import * as theme from '../../shared/theme';
import * as Styled from './app.styled';
import AppHelp from '../../shared/components/help';
import { useLocation } from 'react-router-dom';

const queryClient = new QueryClient();
const apiGoogle = process.env.REACT_APP_API_GOOGLE as string;
const AppContainer = () => {
  const [page, setPage] = useState(false);
  const location = useLocation();
  const hideWidgetPaths = [
    '/articles',
    '/sign-in',
    '/webinars',
    '/login',
    '/articles/',
  ];
  const shouldHideWidget = (pathname: string) => {
    console.log('pathname', pathname);
    return hideWidgetPaths.some(path => pathname.startsWith(path));
  };

  useEffect(() => {
    setPage(shouldHideWidget(location.pathname));
  }, [location.pathname]);

  return (
    <>
      <GoogleOAuthProvider clientId={`${apiGoogle}`}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Styled.GlobalStyles />
            <Router />
            {!page && <AppHelp />}
          </QueryClientProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default AppContainer;
