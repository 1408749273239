import React, { FC } from 'react';
import {
  ArticleContainer,
  Image,
  SlideCategory,
  SliderTextWrapper,
  SlideText,
  SlideTextName,
} from './article.styles';

type IArticleCard = {
  image: string;
  name: string;
  title: string;
  onClick: any;
  id: number;
  category: any;
};

const ArticleCard: FC<IArticleCard> = ({
  image,
  name,
  title,
  onClick,
  id,
  category,
}) => {
  return (
    <ArticleContainer onClick={() => onClick(id)}>
      <Image src={image} alt="Image 1" />
      <SliderTextWrapper>
        <SlideTextName>{name}</SlideTextName>
        <SlideText>
          {title.length > 59 ? title.slice(0, 60) + '...' : title}
        </SlideText>
        <SlideCategory>{category.name}</SlideCategory>
      </SliderTextWrapper>
    </ArticleContainer>
  );
};

export default ArticleCard;
