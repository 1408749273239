import styled from 'styled-components';

export const ArticlesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
	overflow: hidden; 
  position: relative;
`;

export const ArticlesContainer = styled.div`
  max-width: 1240px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;



export const ArticlesTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 50px 0;
  @media only screen and (max-width:1392px ) {
    margin: 20px 0;
    justify-content: center;
  }
  @media only screen and (max-width:610px ) {
    width: 100%;
    margin: 10px 0;
    justify-content: center;
  }
`;
export const ArticlesTitleText = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 36px;
  margin: 0 0 5px 16px;
  @media only screen and (max-width:1030px ) {
    font-size: 30px;
    margin: 0 0 5px 9px;
  }
  @media only screen and (max-width:610px ) {
    font-size: 18px;
    margin: 0 0 3px 5px;
  }
`;

export const ArticlesImage = styled.img`
  
  @media only screen and (max-width:1030px ) {
    width: 55px;
    margin: 0 0 0 30px;
  }
  @media only screen and (max-width:610px ) {
    width: 35px;
    margin: 0 0 0 0px;
  }
`;
export const ArticlesFilter = styled.div`
//width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 20px;
margin-bottom: 40px;
@media only screen and (max-width: 1392px) {
  margin: 0 30px 20px 0;
}
@media only screen and (max-width: 1016px) {
  max-width: 800px;
}
@media only screen and (max-width: 780px) {
  max-width: 600px;
}
@media only screen and (max-width: 981px) {
  gap: 10px;
  margin: 0 0 15px 0;
}
`;

export const ArticlesSlider = styled.div`
  width: 100%;
`;
