import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import sliderImag1 from '../../../assets/image/slider-1.PNG';
import sliderImag2 from '../../../assets/image/slider-2.PNG';
import sliderImag3 from '../../../assets/image/slider-3.PNG';
import sliderImag4 from '../../../assets/image/slider-4.JPG';
import sliderImag5 from '../../../assets/image/slider-5.JPG';
import sliderImag6 from '../../../assets/image/slider-6.JPG';
import sliderImag7 from '../../../assets/image/slider-7.PNG';
import sliderImag8 from '../../../assets/image/slider-8.JPG';
import sliderImag9 from '../../../assets/image/slider-9.JPG';
import sliderImag10 from '../../../assets/image/slider-10.PNG';
import sliderImag11 from '../../../assets/image/slider-11.PNG';
import sliderImag12 from '../../../assets/image/slider-12.JPG';
import sliderImag13 from '../../../assets/image/slider-13.JPG';
import sliderImag14 from '../../../assets/image/slider-14.JPG';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { BannerImage } from './slider.styled';
import { useWindowSize } from '../../hooks/useScreenSize';

const sliderItems = [
  {
    sliderImage: sliderImag1,
  },
  {
    sliderImage: sliderImag2,
  },
  {
    sliderImage: sliderImag3,
  },
  {
    sliderImage: sliderImag4,
  },
  {
    sliderImage: sliderImag5,
  },
  {
    sliderImage: sliderImag6,
  },
  {
    sliderImage: sliderImag7,
  },
  {
    sliderImage: sliderImag8,
  },
  {
    sliderImage: sliderImag9,
  },
  {
    sliderImage: sliderImag10,
  },
  {
    sliderImage: sliderImag11,
  },
  {
    sliderImage: sliderImag12,
  },
  {
    sliderImage: sliderImag13,
  },
  {
    sliderImage: sliderImag14,
  },
];

export default function ImageSlider({ reverseDirection = false, initialSlide = '0' }: any) {
  const { width } = useWindowSize();
  return (
    <>
      <Swiper
        height={700}
        spaceBetween={width < 982 ? -400 : width <= 1392 ? -200 : 0}
        slidesPerView={3}
        noSwiping={true}
        noSwipingClass="allow-swiping"
        centeredSlides={true}
        speed={8000}
        initialSlide={initialSlide}
        loop={true}
        reverseDirection={false}
        direction="vertical"
        autoplay={{
          reverseDirection: reverseDirection,
          delay: 0,
          disableOnInteraction: true,
        }}
        navigation={false} // Enable navigation arrows
        pagination={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {sliderItems.map((item: any, index) => {
          return (
            <SwiperSlide key={index} style={{ zIndex: 1 }}>
              <BannerImage src={item.sliderImage} />
            </SwiperSlide>
          );
        })}
        {/*<SwiperSlide style={{ zIndex: 1 }}>
          <BannerImage src={sliderImag1} />
        </SwiperSlide>
        <SwiperSlide style={{ zIndex: 1 }}>
          <BannerImage src={sliderImag2} />
        </SwiperSlide>
        <SwiperSlide style={{ zIndex: 1 }}>
          <BannerImage src={sliderImag3} />
        </SwiperSlide>
        <SwiperSlide style={{ zIndex: 1 }}>
          <BannerImage src={sliderImag1} />
        </SwiperSlide>
        <SwiperSlide style={{ zIndex: 1 }}>
          <BannerImage src={sliderImag2} />
        </SwiperSlide>
        <SwiperSlide style={{ zIndex: 1 }}>
          <BannerImage src={sliderImag3} />
        </SwiperSlide>*/}
      </Swiper>
    </>
  );
}
