import React, { FC } from 'react';
import {
  ArticlesContainer,
  ArticlesFilter,
  ArticlesImage,
  ArticlesTitle,
  ArticlesTitleText,
  ArticlesWrapper,
} from './home-articles.style';
import logo from '../../../../assets/icons/shortLogo.svg';
import FilterButton from '../../../../shared/components/filter-button';
import HorizontalSlider from '../../../../shared/components/sliders/horisontal-slider';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../../../shared/hooks/useScreenSize';
import { useGetAllCategories } from '../../../../shared/hooks/use-get-all-categories.hook';
import { IOptions } from '../../../../shared/types';

const HomeArticles: FC<any> = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const { options } = useGetAllCategories();

  return (
    <ArticlesWrapper>
      <ArticlesContainer>
        <ArticlesTitle>
          <ArticlesImage src={logo} alt="articles" />
          <ArticlesTitleText>Статті</ArticlesTitleText>
        </ArticlesTitle>
        <ArticlesFilter>
          {width >= 610 ? (
            options?.map((button: IOptions, index: any) => {
              return (
                <FilterButton
                  key={index}
                  onClick={() => navigate(`/articles?category=${button.id}`)}
                  text={button.name}
                  id={button.id}
                  width={width <= 981 ? '180px' : '230px'}
                />
              );
            })
          ) : (
            <FilterButton
              key={'index'}
              onClick={() => navigate('/articles')}
              text={'Категорії'}
              id={1}
              width={width <= 440?`${width * 0.7}px`:'400px'}
            />
          )}
        </ArticlesFilter>
        <HorizontalSlider />
      </ArticlesContainer>
    </ArticlesWrapper>
  );
};

export default HomeArticles;
