import React, { FC } from 'react';
import { ButtonOutlineWrapper } from './AppButton.styled';

type IAppOutlineButton = {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  width?: string;
};

const AppOutlineButton: FC<IAppOutlineButton> = ({ text, onClick, width = '100%' }) => (
  <ButtonOutlineWrapper onClick={onClick} type="submit" width={width}>
    {text}
  </ButtonOutlineWrapper>
);

export default AppOutlineButton;
