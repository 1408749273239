import React, { useLayoutEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { userService } from '../../shared/services/user.service';
import heart from '../../assets/icons/heart.svg';
import heartFilled from '../../assets/icons/heart-filed.svg';
import arrow from '../../assets/icons/arrow-rigth.svg';

import {
  ArticleAuthor,
  ArticleAuthorTitle,
  ArticleCategory,
  ArticleReturnBack,
  ArticleReturnImage,
  ArticleSave,
  ArticleContainer,
  ArticlesWrapper,
  ArticleTitle,
  HTMLCONTAINER,
  TextContainer,
} from './index.styles';
import AppLoader from '../../shared/components/loader/loader';
import { ToastContainer } from 'react-toastify';
import { showToastError } from '../../shared/components/toast/toast';
import PayContainer from './pay-container';
import { HomeContainerWrapper } from '../../shared/components/home-container/index.styled';
import Navbar from '../../shared/components/Navbar/Navbar';
import Footer from '../../shared/components/footer';
import { useAuth } from '../../store/auth';

const ArticlePage = () => {
  let { id } = useParams<any>();
  const [html, setHtml] = useState();
  const [isPayable, setIsPayable] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [articleData, setArticleData] = useState<any>();
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  const { data } = useQuery<any, any>(
    'article',
    () => userService.getArticlesById(id),
    {
      keepPreviousData: true,
      onSuccess(res) {
        setIsPayable(res.data.is_paid);
        setHtml(res.data.content);
        setArticleData(res.data);
        setIsLoading(false);
      },
      onError(err) {
        showToastError('Щось пішло не так');
      },
    }
  );

  useLayoutEffect(() => {
    setIsLoading(true);
  }, []);

  const findFavorites = (allArticles: any) => {
    if (allArticles)
      return allArticles.find((article: any) => article.id === Number(id));
  };

  const { refetch, isLoading: isUserLoading } = useQuery<any, any>(
    'user',
    () => userService.getUser(),
    {
      keepPreviousData: true,
      onSuccess(res) {
        res.data.subscription ? setIsSubscribe(true) : setIsSubscribe(false);
        if (isAuth) setIsSaved(findFavorites(res.data.favorites_articles));
      },
    }
  );

  const saveToFavoriteById = useMutation(
    async () => userService.addFavoritesArticle(Number(id)),
    {
      onSuccess: (res: any) => {
        setIsSaved(prev => !prev);
        //refetch();
      },
      onError: (error: any) => {},
    }
  );

  if (isLoading) return <AppLoader />;

  const saveHandler = () => {
    saveToFavoriteById.mutate();
  };

  const showPayableItems = (): boolean => {
    if (!isPayable) return false;
    if (!isAuth && isPayable) return true;
    if (isAuth && isPayable && isSubscribe) return false;
    if (isAuth && isPayable && !isSubscribe) return true;
    return true;
  };

  return (
    <HomeContainerWrapper>
      <Navbar />

      <ArticlesWrapper>
        <ArticleContainer>
          <ArticleReturnBack onClick={() => navigate(-1)}>
            <ArticleReturnImage src={arrow} alt="" />
            Назад
          </ArticleReturnBack>
          <ArticleTitle>
            {articleData?.title && articleData?.title}
          </ArticleTitle>
          <ArticleAuthor>
            <ArticleAuthorTitle>Автор:</ArticleAuthorTitle>{' '}
            {articleData?.author && articleData?.author}
          </ArticleAuthor>
          <ArticleCategory>
            {articleData?.categories[0].name && articleData?.categories[0].name}
          </ArticleCategory>
          {isAuth && (
            <ArticleSave onClick={saveHandler}>
              <img src={isSaved ? heartFilled : heart} alt="save" /> Зберегти
            </ArticleSave>
          )}
          <TextContainer ispayable={showPayableItems()}>
            {!isLoading && html && (
              <HTMLCONTAINER dangerouslySetInnerHTML={{ __html: html }} />
            )}
          </TextContainer>
        </ArticleContainer>
        <ToastContainer />
        {showPayableItems() && <PayContainer />}
      </ArticlesWrapper>
      {!showPayableItems() && <Footer />}
    </HomeContainerWrapper>
  );
};

export default ArticlePage;
