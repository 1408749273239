import { toast } from 'react-toastify';

export const showToastError = (text: string) => {
  toast.error(text, {
    position: 'top-center',
    autoClose: 3000, // Duration in milliseconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
};

export const showToastSuccess = (text: string) => {
  toast.success(text, {
    position: 'bottom-left',
    autoClose: 3000, // Duration in milliseconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
};
