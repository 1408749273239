import styled from 'styled-components';

export const WebinarsWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.01);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WebinarsContainer = styled.div`
  max-width: 1240px;
  margin-top: 50px;
  @media only screen and (max-width: 1265px) {
    max-width: 1100px;
  }
  @media only screen and (max-width: 1140px) {
    max-width: 1000px;
  }
  @media only screen and (max-width: 1050px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 838px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 633px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 437px) {
    max-width: 320px;
  }
  @media only screen and (max-width: 380px) {
    max-width: 300px;
  }
  @media only screen and (max-width: 333px) {
    max-width: 270px;
  }
`;

export const WebinarContainer = styled.div`
  width: 100%;
  // border: 1px solid #a0aac2;
  background-color: white;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  margin-top: 40px;
  @media only screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const WebinarImage = styled.img`
  width: 500px;
  height: auto;
  margin-bottom: 16px;
  border-radius: 20px;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
  @media only screen and (max-width: 633px) {
    max-width: 370px;
  }
`;

export const WebinarImageContainer = styled.div`
  min-width: 500px;
  @media only screen and (max-width: 633px) {
    min-width: 100%;
  }
`;

export const WebinarTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 20px;
  @media only screen and (max-width: 437px) {
    margin-left: 5px;
  }
`;
export const WebinarNameContainer = styled.div``;

export const WebinarName = styled.div`
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  @media only screen and (max-width: 437px) {
    font-size: 18px;
  }
`;

export const WebinarExp = styled.div`
  font-family: 'Montserrat-Medium';
  font-size: 24px;
  color: #686868;
  @media only screen and (max-width: 437px) {
    font-size: 18px;
  }
`;

export const WebinarPrice = styled.div`
  font-family: 'Montserrat-SemiBold';
  font-size: 24px;
`;

export const WebinarPlan = styled.div`
  margin-top: 40px;
`;

export const WebinarPlanTitle = styled.div`
  font-family: 'Montserrat-SemiBold';
  font-size: 28px;
  @media only screen and (max-width: 633px) {
    font-size: 21px;
  }
`;

export const WebinarPlanText = styled.div`
  font-family: 'Montserrat-Medium';
  font-size: 24px;
  @media only screen and (max-width: 633px) {
    font-size: 18px;
  }
`;

export const WebinarMobilePriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

export const WebinarMobilePriceText = styled.div`
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  color: #0d1282;
  @media only screen and (max-width: 437px) {
    font-size: 18px;
  }
`;
