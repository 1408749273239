import styled from 'styled-components';
import { COLORS, MEDIA } from '../../theme';

export const SidebarWrapper = styled.div`
  position: fixed;
  margin-top: 90px;
  height: 100vh;
  width: 310px;
`; // width: 100%;
//   background-color: ${COLORS.black};
//   flex: 1;
//   z-index: 666;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// //   @media only screen and (max-width: 643px) {
//     position: fixed;
//     top: 0;
//   }

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  height: 70%;
  margin: 0 0 0 8px;
  padding: 16px 12px 0 12px;

  border: 2px solid rgba(13, 21, 132, 0.14);
  border-radius: 12px;
  box-shadow: rgba(13, 21, 132, 0.24) 0px 2px 8px;
`;

export const SidebarSpanText = styled.span`
  color: #0d1282;
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
  @media only screen and (max-width: 1030px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 818px) {
    font-size: 14px;
  }
`;

export const SidebarLogo = styled.button`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 16px;
  font-size: 28px;
  line-height: 120%;
  display: flex;
  cursor: pointer;
  align-items: center;
  background: none;
  border: none;
  color: ${COLORS.blackText};
  //   @media only screen and (max-width: 1030px) {
  //     width: 150px;
  //   }
  //@media only screen and (max-width:818px ) {
  //  width: 120px;
  //}
`;

export const SidebarLink = styled.div`
  font-size: 24px;
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${COLORS.blackText};
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: 1030px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 818px) {
    font-size: 14px;
  }
`;
