import styled from 'styled-components';

export const FormContainer = styled.div`
  // flex: 1;
  //width: 450px;
  //min-width: 450px;
  @media only screen and (max-width: 943px) {
    //min-width: none;
  }
`;
