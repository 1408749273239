import styled, { keyframes } from 'styled-components';

// Define a keyframe animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Create a styled component for the loader
export const LoaderContainer = styled.div`
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderItem = styled.div`
  display: inline-block;
  width: 50px;
  margin: 100px 0;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const ButtonLoaderItem = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;