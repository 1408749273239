import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import React, { useEffect } from 'react';
import { SettingsContext } from './context/SettingsContext';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import Editor from './Editor';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import { TableContext } from './plugins/TablePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import './index.css';

function Editors({
  handlePost,
  initialContent,
}: {
  handlePost: (data: string) => void;
  initialContent?: string | null;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (initialContent) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();

        const parser = new DOMParser();
        const doc = parser.parseFromString(initialContent, 'text/html');

        const nodes = $generateNodesFromDOM(editor, doc);
        root.append(...nodes);
      });
    }
  }, [initialContent, editor]);

  return (
    <SharedHistoryContext>
      <TableContext>
        <SharedAutocompleteContext>
          <div className="editor-shell">
            <Editor handlePost={handlePost} />
          </div>
        </SharedAutocompleteContext>
      </TableContext>
    </SharedHistoryContext>
  );
}

export default function TextEditor({
  handlePost,
  initialContent,
}: {
  handlePost: (data: string) => void;
  initialContent?: string | null;
}): any {
  const initialConfig = {
    editorState: null,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <SettingsContext>
      <LexicalComposer initialConfig={initialConfig}>
        <Editors handlePost={handlePost} initialContent={initialContent} />
      </LexicalComposer>
    </SettingsContext>
  );
}
