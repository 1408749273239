import sliderImag1 from '../../assets/image/slide-1.JPG';
import sliderImag2 from '../../assets/image/slide-2.JPG';
import sliderImag3 from '../../assets/image/slide-3.JPG';
import sliderImag4 from '../../assets/image/slide-4.PNG';
import sliderImag5 from '../../assets/image/slide-5.JPG';
import sliderImag6 from '../../assets/image/slide-6.JPG';

export const getCookie = (name: string) => {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }

  return null;
};


export const calculateDaysLeft = (expirationDate: any) => {
  const expiration: any = new Date(expirationDate);
  const today: any = new Date();
  const differenceMs = expiration - today;
  const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return daysLeft;
};

export const articleConstButtons = [
  {
    id: 1,
    name: 'Ортопедія',
  },
  {
    id: 2,
    name: 'Терапія',
  },
  {
    id: 3,
    name: 'Анатомія',
  },
  {
    id: 4,
    name: 'B. O. P. T.',
  },
  {
    id: 5,
    name: 'Хірургія',
  },
  {
    id: 6,
    name: 'Ортодонтія',
  },
  {
    id: 7,
    name: 'Елайнери',
  },
  {
    id: 8,
    name: 'Пародонтологія',
  },
  {
    id: 9,
    name: 'Матерілознавство',
  },
  {
    id: 10,
    name: 'Ендодонтія',
  },
];

export const webinarsConstButtons = [
  {
    title: 'Ортопедія',
  },
  {
    title: 'Терапія',
  },
  {
    title: 'Анатомія',
  },
  {
    title: 'B. O. P. T.',
  },
  {
    title: 'Хірургія',
  },
  {
    title: 'Ортодонтія',
  },
  {
    title: 'Елайнери',
  },
  {
    title: 'Пародонтологія',
  },
  {
    title: 'Матерілознавство',
  },
  {
    title: 'Ендодонтія',
  },
];

export const horizontalSliderContent = [
  {
    image: 'http://res.cloudinary.com/dxovyhbrm/image/upload/v1716837014/test/gowp0ercukpwohhgndqk.png',
    id:48,
    name: 'Ignacio Pedrinaci; Javier Calatrava',
    title: 'Лікування порушення пасивного прорізування зубів. Багатофункціональні анатомічні прототипи (MAP)',
  },
  {
    image: 'http://res.cloudinary.com/dxovyhbrm/image/upload/v1716498782/test/fyoiwz1nywew7tgykd3c.png',
    name: 'Łukasz Lassmann; Zuzanna Nowak',
    id: 39,
    title: 'Застосування КПКТ та електронної аксіографії для оцінки суглобових параметрів - оглядове когортне дослідження',
  },
  {
    image: 'http://res.cloudinary.com/dxovyhbrm/image/upload/v1716471806/test/up572aunqkft88jvvnc8.jpg',
    id:37,
    name: 'Stefano Conti',
    title: 'BOPT - для стійкого здоровʼя пародонту та оптимальної естетики (частина 2)',
  },
  {
    image: 'http://res.cloudinary.com/dxovyhbrm/image/upload/v1716458202/test/s2hj9ocf8eawmdtdmgmh.png',
    id: 27,
    name: 'Ricardo J. Pinto; Krzysztof Chmielewski',
    title: 'Точність цифрових методів збору даних у повних арочних протезах з опорою на імплантати',
  },
  {
    image: 'http://res.cloudinary.com/dxovyhbrm/image/upload/v1716371322/test/ju8i3hjhn4rpfsinsnx4.jpg',
    id: 19,
    name: 'Sascha Hein',
    title: 'eLAB протокол',
  },
  {
    image: 'http://res.cloudinary.com/dxovyhbrm/image/upload/v1716293224/test/bh4i7hwhlouail26smbu.jpg',
    id: 4,
    name: 'Kyungmin Clara Lee | Jae Hyun Park',
    title: 'Цифрове застосування тривимірної діагностики та лікування за допомогою віртуального артикулятора',
  },
];
