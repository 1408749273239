import styled from 'styled-components';

export const AdminContentWrapper = styled.div`
  margin-left: 320px;
  margin-top: 90px;
  margin-right: 10px;
  border: 2px solid rgba(13, 21, 132, 0.14);
  border-radius: 12px;
  box-shadow: rgba(13, 21, 132, 0.24) 0px 2px 8px;
  width: 100%;
`;

export const AdminContentContainer = styled.div`
  padding: 20px;
`;
