import { useEffect } from 'react';
import { useAdminAuth } from '../../store/admin-auth';
import { adminService } from '../services/admin/admin.service';

export const useAdminAuthCheck = () => {
  useEffect(() => {
    (async () => {
      try {
        const res = await adminService.check();
        useAdminAuth.getState().updateAuth(true)();
      } catch (error) {
        useAdminAuth.getState().updateAuth(false)();
        console.log(error);
      }
    })();
  }, []);
};
