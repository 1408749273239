import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { showToastError } from '../../shared/components/toast/toast';
import { useAdminAuthCheck } from '../../shared/hooks/use-admin-auth-check.hook';
import { useAdminAuth } from '../../store/admin-auth';
import 'react-toastify/dist/ReactToastify.css';

import {
  AdminContentContainer,
  AdminContentWrapper,
} from 'shared/components/admin/AdminContent.styled';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadCell,
  TableRow,
  TableStyled,
  UserSpan,
} from 'shared/components/admin/AdminTable.styled';
import { userService } from 'shared/services/user.service';
import { useMutation, useQuery } from 'react-query';
import { adminService } from '../../shared/services/admin/admin.service';

const AdminPage = () => {
  const { isAuth } = useAdminAuth();
  useAdminAuthCheck();
  const navigate = useNavigate();

  const [totalUsers, setTotalUsers] = useState(0);
  const [listOfUsers, setListOfUsers] = useState<any>([]);

  const { isLoading, refetch } = useQuery<any, any>(
    ['all'],
    () => userService.getAllUsers(),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        console.log(res.data);
        setListOfUsers(res.data);
        setTotalUsers(res.data.length);
      },
      onError: () => {
        showToastError('Щось пішло не так');
      },
    }
  );

  useEffect(() => {
    if (!isAuth) navigate('sign-in');
  }, [isAuth]);

  const addUserToBlackList = useMutation(
    async (id: any) => adminService.addUserToBlackList(id),
    {
      onSuccess: res => {
        console.log('Видалено');
      },
      onError: (error: any) => {
        showToastError('не видалено');
        console.log(error);
      },
    }
  );

  const addHandler = (id: any) => {
    addUserToBlackList.mutate(Number(id));
  };

  return (
    <AdminContentWrapper>
      <AdminContentContainer>
        <UserSpan>Загальна кількість користувачів: {totalUsers}</UserSpan>
        <TableContainer>
          <TableStyled>
            <TableHead>
              <TableRow>
                <TableHeadCell>id</TableHeadCell>
                <TableHeadCell>Користувач</TableHeadCell>
                <TableHeadCell>Девайс</TableHeadCell>
                <TableHeadCell>Email</TableHeadCell>
                <TableHeadCell>В ЧС</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfUsers.map((user: any) => {
                const jsonUserInfo = JSON.parse(user['user_info']);
                const online = jsonUserInfo?.isMobile
                  ? 'mobile'
                  : jsonUserInfo?.isDesktop
                  ? 'desktop'
                  : '';
                return (
                  <TableRow key={user?.id}>
                    <TableCell>{user?.id}</TableCell>
                    <TableCell>{user?.name}</TableCell>
                    <TableCell>{user?.email?user?.email:'Шеринг'}</TableCell>
                    <TableCell>{online}</TableCell>
                    <TableCell>
                      <button onClick={() => addHandler(user?.id)}>
                        Добавити в список
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableStyled>
        </TableContainer>
      </AdminContentContainer>
    </AdminContentWrapper>
  );
};

export default AdminPage;
