import styled from 'styled-components';

export const UserWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

export const UserContainer = styled.div`
  width: 1240px;
  margin: 50px 20px;
  align-items: center;
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 1265px) {
    max-width: 1100px;
  }
  @media only screen and (max-width: 1120px) {
    max-width: 1000px;
  }
  @media only screen and (max-width: 1030px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 818px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 613px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 407px) {
    max-width: 290px;
  }
`;

export const UserHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @media only screen and (max-width: 626px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const UserHeaderTitle = styled.div`
  font-size: 24px;
  font-family: Montserrat-Medium;

  @media only screen and (max-width: 815px) {
    font-size: 16px;
  }
`;

export const UserHeaderLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebeced;
  margin: 20px 0 30px 0;
`;

export const SideText = styled.p`
  font-family: Montserrat-Regular;
  font-size: 14px;
  span {
    font-weight: bold;
  }
`;
