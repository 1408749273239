import styled from 'styled-components';

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 110px;
  position: relative;
`;

export const FooterContainer = styled.div`
  max-width: 1240px;
  display: flex;
  width: 100%;
  flex-direction: column;
  //margin-left: 40px;
  @media only screen and (max-width: 1415px) {
    width: 90%;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 40px;
  }
  @media only screen and (max-width: 400px) {
    margin-left: 15px;
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffff;
  margin: 30px 0;
  font-size: 14px;
  @media only screen and (max-width: 350px) {
    font-size: 12px;
  }
`;

export const FooterSocials = styled.div`
  display: flex;
  gap: 20px;
  margin: 100px 0;
  position: relative;
`;

export const FooterCredential = styled.div`
  width: 60%;
  display: flex;
  position: relative;
  align-items: start;
  justify-content: space-between;
  @media only screen and (max-width: 1415px) {
    margin: 0 50px 0 0;
  }
  @media only screen and (max-width: 350px) {
    flex-direction: column;
  }
`;

export const FooterMobileBox = styled.div`
  display: flex;
  //align-items: start;
  justify-content: space-between;
  width: 55%;
  @media only screen and (max-width: 831px) {
    flex-direction: column;
    gap: 30px;
  }
  @media only screen and (max-width: 350px) {
    gap: 5px;
  }
`;

export const FooterCredentialColumn = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  @media only screen and (max-width: 350px) {
    //margin-top: 20px;
  }
`;

export const FooterTitle = styled.div`
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #0d1282;
  margin-bottom: 28px;
  @media only screen and (max-width: 350px) {
    margin-bottom: 5px;
  }
`;

export const FooterText = styled.button`
  font-family: Montserrat-Regular;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
  background: none;
  text-wrap: nowrap;
  border: none;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 350px) {
    margin: 5px 0;
  }
`;

export const AddTextContainer = styled.div`
  align-items: center;
  font-family: Montserrat-Medium;
  color: #142a53;
  justify-content: center;
  transition:
    background-color 0.3s,
    transform 0.3s;

  font-size: 16px;
  cursor: pointer;
  &:hover {
  }
`;

export const AddContainer = styled.button`
  background-color: #c4d6f9;
  width: 100%;
  display: flex;
  border: none;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 50px;
  &:hover {
    ${AddTextContainer} {
      transform: scale(1.2);
    }
  }
`;

export const ModalContainer = styled.div`
  font-family: Montserrat-Medium;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  font-family: Montserrat-Medium;
  font-size: 22px;
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  margin-top: 30px;
  gap: 10px;
`;

export const ContactLink = styled.a`
  color: #3498db;
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContactPhoneNumber = styled.a`
  color: #3498db;
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContactEmail = styled.a`
  color: #3498db;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;