import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

export const fingerprintHandler = async () => {
  try {
    const publicKey = process.env.REACT_APP_PUBLIC_API_KEY || '';
    const fpPromise = FingerprintJS.load({ apiKey: publicKey });
    const fp = await fpPromise;
    const result = await fp.get({ extendedResult: true });
    return result?.visitorId;
  } catch (e) {
    console.log(e);
    return null;
  }
};
