import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';
import AdminPage from '../modules/admin';
import AdminCreateArticlesPage from '../modules/admin/admin-create-articles';
import AdminArticlesPage from '../modules/admin/admin-articles';
import AdminContainer from '../modules/admin/admin-container';
import AdminCreateWebinarsPage from '../modules/admin/admin-create-webinars';
import AdminUpdateArticlesPage from '../modules/admin/admin-update-articles';
import AdminEditWebinarsPage from '../modules/admin/admin-edit-webinars';
import AdminLoginPage from '../modules/admin/auth/login';
import ArticlePage from '../modules/article';
import ArticlesPage from '../modules/articles';
import LoginForm from '../modules/auth/login/sign-in';
import RegisterSuccessPage from '../modules/auth/registrations-success';
import HomePage from '../modules/home';
import PaymentPage from '../modules/payment';
import PolicyPage from '../modules/policy';
import UserPage from '../modules/user';
import WebinarPage from '../modules/webinar';
import WebinarVideoPage from '../modules/webinar-video';
import WebinarsPage from '../modules/webinars';
import HomeContainer from '../shared/components/home-container';
import { RouterKeys } from './keys';
import OfferPage from 'modules/offer-contract';
import SignInContainer from 'shared/components/sign-in-container';
import AppLogin from 'modules/auth/login/login';
import AdminBlackList from '../modules/admin/admin-blacklist';
import AddNewDevice from '../modules/add-new-device';

export const publicRoutes = (
  <>
    <Route path={RouterKeys.SIGN_IN} element={<LoginForm />} />

    <Route path={RouterKeys.ROOT} element={<HomeContainer />}>
      <Route path={RouterKeys.ROOT} element={<HomePage />} />
      <Route path={RouterKeys.ADD_NEW_DEVICE} element={<AddNewDevice />} />
      <Route path={RouterKeys.ARTICLES} element={<ArticlesPage />} />
      <Route path={RouterKeys.WEBINARS} element={<WebinarsPage />} />
      <Route path={`${RouterKeys.WEBINARS}/:id`} element={<WebinarPage />} />
      <Route path={`${RouterKeys.ARTICLES}/:id`} element={<ArticlePage />} />
      <Route
        path={`${RouterKeys.WEBINAR_VIDEO_BY_ID}/:id`}
        element={<WebinarVideoPage />}
      />
      <Route path={RouterKeys.PAYMENT} element={<PaymentPage />} />
      <Route
        path={`${RouterKeys.ALL_MATCH}`}
        element={<Navigate to={RouterKeys.HOME} />}
      />
    </Route>
  </>
);

export const privateRoutes = (
  <>
    <Route path={RouterKeys.ROOT} element={<HomeContainer />}>
      <Route path={RouterKeys.HOME} element={<HomePage />} />
      <Route
        path={`${RouterKeys.ADD_NEW_DEVICE}/:token`}
        element={<AddNewDevice />}
      />
      <Route path={RouterKeys.USER} element={<UserPage />} />
      <Route path={RouterKeys.ARTICLES} element={<ArticlesPage />} />
      <Route path={RouterKeys.WEBINARS} element={<WebinarsPage />} />
      <Route path={`${RouterKeys.WEBINARS}/:id`} element={<WebinarPage />} />
      <Route
        path={`${RouterKeys.WEBINAR_VIDEO_BY_ID}/:id`}
        element={<WebinarVideoPage />}
      />
      <Route path={RouterKeys.PAYMENT} element={<PaymentPage />} />
      <Route
        path={`${RouterKeys.ALL_MATCH}`}
        element={<Navigate to={RouterKeys.HOME} />}
      />
      <Route path={RouterKeys.POLICY} element={<PolicyPage />} />
      <Route path={RouterKeys.OFFER_CONTRACT} element={<OfferPage />} />
    </Route>
    <Route path={RouterKeys.SUCCESS} element={<RegisterSuccessPage />} />
    <Route path={`${RouterKeys.ARTICLES}/:id`} element={<ArticlePage />} />

    <Route path={RouterKeys.ROOT} element={<SignInContainer />}>
      <Route path={RouterKeys.SIGN_IN} element={<LoginForm />} />
      <Route path={RouterKeys.LOGIN} element={<AppLogin />} />
    </Route>
  </>
);

export const adminRoutes = (
  <>
    <Route path={RouterKeys.ADMIN_LOGIN} element={<AdminLoginPage />} />
    <Route path={RouterKeys.ROOT} element={<AdminContainer />}>
      <Route path={RouterKeys.ADMIN} element={<AdminPage />} />
      <Route
        path={RouterKeys.ADMIN_WEBINARS}
        element={<AdminEditWebinarsPage />}
      />
      <Route path={RouterKeys.ADMIN_BLACKLIST} element={<AdminBlackList />} />
      <Route
        path={RouterKeys.ADMIN_CREATE_WEBINARS}
        element={<AdminCreateWebinarsPage />}
      />
      <Route path={RouterKeys.ADMIN_ARTICLES} element={<AdminArticlesPage />} />
      <Route
        path={RouterKeys.ADMIN_CREATE_ARTICLES}
        element={<AdminCreateArticlesPage />}
      />
      <Route
        path={RouterKeys.ADMIN_UPDATE_ARTICLES}
        element={<AdminUpdateArticlesPage />}
      />
    </Route>
  </>
);
