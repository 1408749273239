import React from 'react';
import {
  PriceBoxContainer,
  PriceContainer,
  PriceDesc,
  PriceImage,
  PriceTitle,
  PriceWrapper,
} from './Price.styles';
import logo from '../../../../assets/icons/shortLogo.svg';
import PriceBox from '../../../../shared/components/price-box';

const Price = () => {
  return (
    <PriceWrapper>
      <PriceContainer id="price">
        <PriceImage src={logo} alt="price" />
        <PriceTitle> Підписка на статті</PriceTitle>
        <PriceDesc>
          Читайте статті та ознайомлюйтесь з сучасними протоколами без обмежень.
          Ми зібрали інформацію та досвід з усього світу, та подбаємо про те,
          щоб вона постійно оновлювалась.
        </PriceDesc>
        <PriceBoxContainer id="priceId">
          <PriceBox isWhite={false} price="999" data="3 місяці" id={1} />
          <PriceBox isWhite={false} price="1699" data="6 місяців" id={2} />
        </PriceBoxContainer>
      </PriceContainer>
    </PriceWrapper>
  );
};

export default Price;
