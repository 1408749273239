import styled from 'styled-components';
import { COLORS } from '../../theme';

export const NavbarWrapper = styled.div`
width: 100%;
  background-color: ${COLORS.primaryWhite};
  flex: 1;
  z-index: 666;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 643px) {
    position: fixed;
    top: 0;
  }
`;
export const NavbarContainer = styled.div`
  max-width: 1240px;
  height: 130px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  z-index: 30;
  @media only screen and (max-width:1265px ) {
    max-width: 1100px;
  }
  @media only screen and (max-width:1120px ) {
    max-width: 1000px;
  }
  @media only screen and (max-width:1030px ) {
    max-width: 800px;
  }
  @media only screen and (max-width:818px ) {
    max-width: 600px;
    height: 70px;
    //padding: 0 20px;
  }
  
`;

export const NavbarLogo = styled.button`
  font-size: 28px;
  line-height: 120%;
  display: flex;
  cursor: pointer;
  align-items: center;
  background: none;
  border: none;
  color: ${COLORS.blackText};
  @media only screen and (max-width:1030px ) {
    width: 150px;
  }
  @media only screen and (max-width:400px ) {
    width: 110px;
  }
`;

export const NavbarLinks = styled.div`
  display: flex;
  font-family: 'Montserrat-Medium';
  align-items: center;
  color: ${COLORS.blackText};
  gap: 40px;
  @media only screen and (max-width:1030px ) {
    gap: 20px;
  }
  @media only screen and (max-width:818px ) {
    gap: 10px;
  }
  @media only screen and (max-width:613px ) {
    display: none;
  }
`;

export const NavbarLink = styled.a`
  font-size: 24px;
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${COLORS.blackText};
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width:1030px ) {
    font-size: 18px;
  }
  @media only screen and (max-width:818px ) {
    font-size: 14px;
  }
`;

export const NavbarText = styled.div`
  font-size: 24px;
  line-height: 120%;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${COLORS.blackText};
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width:1030px ) {
    font-size: 18px;
  }
  @media only screen and (max-width:818px ) {
    font-size: 14px;
  }
`;


export const NavbarUser = styled.button`
  font-size: 24px;
  line-height: 120%;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  color: ${COLORS.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.primaryBlue};
`;


export const NavbarButtons = styled.div`

  @media only screen and (max-width:613px ) {
    display: none;
  }
`;