import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export const useGetVideoById = (
  fnGetVideoById: (data: {
    idVideo: string;
  }) => Promise<{ otp: string; playbackInfo: string }>,
  key: string
) => {
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const getVideoById = useMutation(fnGetVideoById, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: key });
    },
    onError: (e: { response: any }) => {
      if (e && e.response?.data?.message) {
        setError(e.response?.data?.message);
      } else {
        setError('An unknown error occurred');
      }
    },
  });
  const { status } = getVideoById;
  return { getVideoById, status, error };
};
