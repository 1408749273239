import styled from 'styled-components';
import { COLORS } from '../../../../shared/theme';

export const FAQWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
`;

export const FAQContainer = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  //flex-wrap: wrap;
  //justify-content: space-between;
  //display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 18px;
  margin: 16px 30px;
  padding: 40px;
  overflow: hidden;
  transition: box-shadow 0.5s;
  box-shadow: ${({ isOpen }) =>
    isOpen ? '0px 5px 16px 0px rgba(13, 18, 130, 0.50)' : 'none'};
  @media only screen and (max-width: 1358px) {
    width: 90%;
  }
  @media only screen and (max-width: 630px) {
    font-size: 15px;
    width: 90%;
    margin: 8px 15px;
  }
  @media only screen and (max-width: 450px) {
    padding: 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const TitleText = styled.h3`
  font-family: Montserrat-Medium;
  font-size: 24px;
  max-width: 80%;
  @media only screen and (max-width: 630px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 390px) {
    font-size: 14px;
    width: 70%;
  }
`;

export const ArrowContainer = styled.div<any>`
  width: 50px;
  height: 50px;
  transition: background-color 0.7s;
  background-color: ${props => (props.isOpen ? '#0D1282' : '#ffff')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${props =>
    !props.isOpen ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none'};
`;

export const Arrow = styled.div<any>`
  width: 16px;
  height: 16px;
  transition: color 0.5s;
  color: ${props => (props.isOpen ? 'white' : 'black')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Description = styled.p<any>`
  width: 60%;
  color: ${COLORS.secondaryGrey};
  transition: opacity 0.9s;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  display: ${props => (props.isOpen ? 'block' : 'none')};
  margin-top: 8px;
  font-family: Montserrat-Regular;
  font-size: 18px;
  @media only screen and (max-width: 630px) {
    font-size: 15px;
    width: 90%;
  }
  @media only screen and (max-width: 390px) {
    font-size: 12px;
  }
`;
