import React from 'react';
import HomeArticles from './components/articles/home-articles';
import HomeWebinars from './components/webinars/home-webinars';
import Banner from './components/banner/Banner';
import { HomeContainer } from './index.styles';
import Price from './components/price/Price';
import FAQ from './components/faq/FAQ';

const HomePage = () => {
  return (
    <HomeContainer>
      <Banner />
      <HomeArticles />
      <HomeWebinars />
      <Price />
      <FAQ />
    </HomeContainer>
  );
};

export default HomePage;
