import React from 'react';
import PriceBoxMobile from '../../shared/components/price-box-mobile';
import {
  ArticlesBanner,
  ArticlesBannerContent,
  ArticlesBannerHeader,
  ArticlesBannerPrice,
  ArticlesBannerText,
  ArticlesButton,
  ArticlesButtons,
  ArticlesButtonsContainer,
} from './index.styles';
type Props = {};

const BannerArticle = (props: Props) => {
  return (
    <ArticlesBanner>
      <ArticlesBannerContent>
        <ArticlesBannerText>
          <ArticlesBannerHeader>
            Доступ до всіх статей по підписці
          </ArticlesBannerHeader>
          <ArticlesButtonsContainer>
            <ArticlesButtons>
              <ArticlesButton>Передова інформація</ArticlesButton>
              <ArticlesButton>Цифрові рішення</ArticlesButton>
            </ArticlesButtons>
            <ArticlesButton>Сучасні протоколи</ArticlesButton>
          </ArticlesButtonsContainer>
        </ArticlesBannerText>
        <ArticlesBannerPrice>
          <PriceBoxMobile isWhite={true} price="999" data="3 місяця" id={1} />
          <PriceBoxMobile isWhite={true} price="1699" data="6 місяців" id={2} />
        </ArticlesBannerPrice>
      </ArticlesBannerContent>
    </ArticlesBanner>
  );
};

export default BannerArticle;
