export const MEDIA = {
  laptop_xl: '2560px',
  laptop_l: '1450px',
  laptop: '1024px',
  tablet: '768px',
  tablet_s: '719px',
  mobile_xl: '530px',
  mobile_l: '452px',
  mobile_m: '375px',
  mobile_s: '320px'
};
