import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  AdminContentContainer,
  AdminContentWrapper,
} from '../../../shared/components/admin/AdminContent.styled';
import { TableBody, TableCell, TableContainer, TableHead, TableHeadCell, TableRow, TableStyled } from '../../../shared/components/admin/AdminTable.styled';
import AppButton from '../../../shared/components/app-button/AppButton';
import { useAdminAuthCheck } from '../../../shared/hooks/use-admin-auth-check.hook';
import { adminService } from '../../../shared/services/admin/admin.service';

const AdminBlackList = () => {
  const [value, setValue] = useState('');
  const [blockedUsers, setBlockedUsers] = useState([]);
  useAdminAuthCheck();

  const { isLoading, refetch } = useQuery<any, any>(
    ['allBlockedUsers'],
    () => adminService.getAllBlockUsers(),
    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setBlockedUsers(res.data);
        console.log(res.data);
      },
      onError: err => {
        console.log(err);
      },
    }
  );

  const deleteUserFromBlacklist = useMutation(
    async (id: any) => adminService.removeUserFromBlackList(id),
    {
      onSuccess: res => {
        refetch();
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setValue(value);
  };

  const removeUserHandler = (id: number) => {
    deleteUserFromBlacklist.mutate(Number(id));
  };

  return (
    <AdminContentWrapper>
      <AdminContentContainer>
      <TableContainer>
          <TableStyled>
            <TableHead>
              <TableRow>
                <TableHeadCell>id</TableHeadCell>
                <TableHeadCell>Користувач</TableHeadCell>
                <TableHeadCell>ЧС</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blockedUsers.map((user: any) => {
               
                return (
                  <TableRow key={user?.id}>
                    <TableCell>{user?.id}</TableCell>
                    <TableCell>{user?.name}</TableCell>
                    <TableCell>
                      <button onClick={() => removeUserHandler(user?.id)}>
                        Прибрати з списку
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableStyled>
        </TableContainer>
      </AdminContentContainer>
    </AdminContentWrapper>
  );
};

export default AdminBlackList;
