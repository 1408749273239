import React, { FC, useState } from 'react';

import Modal from 'react-modal';

Modal.setAppElement('#root');

type IAppModal = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode
};

const AppModal: FC<IAppModal> = ({ isOpen, onClose, children }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
		style={{
			overlay: {
			  backgroundColor: 'rgba(0, 0, 0, 0.5)',
			  zIndex: 1000, 
			},
			content: {
			  width: '80%',
			  maxWidth: '500px',
			  height: '600px',
			  margin: 'auto',
			  background: '#fff',
			  borderRadius: '8px',
			  padding: '20px',
			  zIndex: 666,
			},
		  }}
      >
		{children}
      
      </Modal>
    </div>
  );
};

export default AppModal;
