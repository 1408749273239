import React from 'react';
import { ButtonLoaderContainer, ButtonLoaderItem } from './loader.styles';

const ButtonLoader = () => {
  return (
    <ButtonLoaderContainer>
      <ButtonLoaderItem />
    </ButtonLoaderContainer>
  );
};

export default ButtonLoader;
