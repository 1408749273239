import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

class AuthDiyaService {
    async loginSharingDiya(data: { requestId: string; }): Promise<{deeplink:string}> {
        try {
            const response = await axios.post('/auth-diya/login', data);
            return response?.data;
        } catch (e) {
            throw e;
        }
    }


}

export const authDiyaService = new AuthDiyaService();
