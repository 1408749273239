import React, { useLayoutEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../../shared/components/loader/loader';
import { STORAGE_KEYS } from '../../shared/constants/app-keys';
import { useAuthCheck } from '../../shared/hooks/use-auth-check.hook';
import { useWindowSize } from '../../shared/hooks/useScreenSize';
import { userService } from '../../shared/services/user.service';
import ActiveSubscription from './components/active-sub';
import UnActiveSubscription from './components/unactive-sub';
import UserTab from './components/user-tab';
import {
  UserContainer,
  UserHeader,
  UserHeaderLine,
  UserHeaderTitle,
  UserWrapper,
} from './index.styles';
import { fingerprintService } from '../../shared/services/fingerprint.service';
import {
  AddButton,
  ContainerAddDevice,
  ContainerAddDeviceSuccess,
  ContainerAddDeviceSuccessImg,
  ContainerLinkAddDevice,
  CopyButton,
  SideText,
} from '../auth/login/index.styles';

import tickIcon from '../../assets/icons/zelenaja-galochka-v-kruzhke-50x64.png';

const UserPage = () => {
  const navigate = useNavigate();
  const [linkAddDevice, setLinkAddDevice] = useState('');
  const [isCopy, setIsCopy] = useState(false);
  const [isHiddenButtonAdd, setIsHiddenButtonAdd] = useState(false);
  const [isHiddenCopyButtonAdd, setIsHiddenCopyButtonAdd] = useState(false);
  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME);
  const [user, setUser] = useState<any>();
  const [isSub, setIsSub] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { width } = useWindowSize();

  useAuthCheck();

  const addNewDevice = useMutation(
    async (body: any) => fingerprintService.addNewDeviceLink(body),
    {
      onSuccess: async (res: any) => {
        console.log('res data', res?.data);
        setLinkAddDevice(res?.data);
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  const addNewDeviceLinkHandler = () => {
    setIsHiddenButtonAdd(true);
    addNewDevice.mutate({
      phone: user?.phone,
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(linkAddDevice).then(
      () => {
        toast.success('Посилання скопійовано!');
        setIsCopy(true);
        setIsHiddenCopyButtonAdd(true);
      },
      () => {
        toast.error('Не вдалося скопіювати посилання');
      }
    );
  };

  useLayoutEffect(() => {
    setIsLoading(true);
  }, []);

  const { refetch } = useQuery<any, any>(
    ['user'],
    () => userService.getUser(),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setIsLoading(false);
        setIsSub(Boolean(res.data.subscription));
        setUser(res.data);
      },
      onError: () => {
        setIsLoading(false);
        navigate('/');
      },
    }
  );
  if (isLoading) return <AppLoader />;

  return (
    <UserWrapper>
      <UserContainer>
        <UserHeader>
          <UserHeaderTitle>
            Привіт, {user?.name !== undefined ? user.name : userName}!
          </UserHeaderTitle>
          <div>
            {user?.phone && user?.is_add_extra_device && (
              <SideText style={{ fontWeight: 'bold' }}>
                Ви вже підключили один додатковий пристрій, для зміни пристроїв,
                зверніться до технічної підтримки.
              </SideText>
            )}
            {user?.phone &&
              !user?.is_add_extra_device &&
              !isHiddenButtonAdd && (
                <ContainerAddDevice>
                  <SideText style={{ fontWeight: 'bold' }}>
                    Є необхідність підключити інший пристрій?{' '}
                    <AddButton onClick={addNewDeviceLinkHandler}>
                      Додати пристрій
                    </AddButton>
                  </SideText>
                </ContainerAddDevice>
              )}
            {linkAddDevice && !isHiddenCopyButtonAdd && (
              <ContainerLinkAddDevice>
                <SideText style={{ fontWeight: 'bold' }}>
                  Скопіюйте посилання:{' '}
                </SideText>
                <span
                  onClick={addNewDeviceLinkHandler}
                  style={{
                    fontSize: 14,
                    color: 'blue',
                    cursor: 'pointer',
                  }}
                >
                  {`${linkAddDevice.substring(0, 30)}...`}
                </span>
                <CopyButton onClick={copyToClipboard}>Скопіювати</CopyButton>
              </ContainerLinkAddDevice>
            )}
            {isCopy && (
              <ContainerAddDeviceSuccess>
                <SideText style={{ fontWeight: 'bold' }}>
                  Відкрийте посилання на потрібному пристрої
                </SideText>
                <ContainerAddDeviceSuccessImg src={tickIcon} />
              </ContainerAddDeviceSuccess>
            )}
          </div>
          {/*<AppOutlineButton
            text={isOpenSettings ? 'Повернутись назад' : 'Змінити профіль'}
            onClick={isOpenSettings ? closeSettings : openSettings}
            width={width < 416 ? '250px' : '350px'}
          />*/}
        </UserHeader>
        <UserHeaderLine />
        {isSub ? (
          <ActiveSubscription expirationDate={user.expiration_date} />
        ) : (
          <UnActiveSubscription />
        )}
        <UserTab userData={user} />
      </UserContainer>
      <ToastContainer />
    </UserWrapper>
  );
};

export default UserPage;
