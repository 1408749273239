import React, { FC, useState } from 'react';
import Cookies from 'js-cookie';
import { Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { userService } from 'shared/services/auth.service';
import { useAuth } from 'store/auth';
import DiiaButton from '../app-button/DiiaButton';
import {
  FormInput,
  FormInputPhoneCode,
  FormInputPassword,
} from '../form-input';
import { useNavigate } from 'react-router-dom';
import { validate } from './validate-schema';

type ResetPasswordForm = {
  phone: string;
};

const ResetPasswordForm: FC<ResetPasswordForm> = ({ phone }) => {
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(false);
  const [confirmError, setConfirmError] = useState('');

  const navigate = useNavigate();
  const changePassword = useMutation(
    async (body: any) => userService.changePasswordPhone(body),
    {
      onSuccess: async (res: any) => {
        console.log('res', res);
        // return navigate('/login');
        window.location.href = '/login';
      },
      onError: (error: any) => {
        console.log(error);

        let errorStr = '';
        error.response.data.message === 'Code does not match' &&
          console.log(
            (errorStr = 'Неправильні останні 4 цифри номера який вам дзвонив!')
          );

        setConfirmError(errorStr);
        setIsLoginBtnDisabled(false);
      },
    }
  );

  const resetPasswordHandle = async (values: any) => {
    setIsLoginBtnDisabled(true);

    changePassword.mutate({
      phone: phone,
      phoneCode: values.phoneCode,
      password: values.password,
    });
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Form
          onSubmit={resetPasswordHandle}
          initialValues={{
            password: '',
            phoneCode: '',
          }}
          validate={validate}
          render={({ handleSubmit, submitError }) => (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'end',
                  }}
                >
                  <label>Пароль </label>
                </div>
                <FormInputPassword
                  type="password"
                  name="password"
                  passedValue=""
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'end',
                  }}
                >
                  <label>Підтвердіть пароль </label>
                </div>
                <FormInputPassword
                  type="password"
                  name="repeatPassword"
                  passedValue=""
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'end',
                  }}
                >
                  <label>Код </label>
                  <label
                    style={{
                      fontSize: 12,
                      color: 'blue',
                    }}
                  >
                    * 4 останні цифри номеру телефона який ваш дзвонив
                  </label>
                </div>
                <FormInputPhoneCode name="phoneCode" passedValue="" />
                <span style={{ color: 'red' }}>{confirmError}</span>
              </div>
              <DiiaButton
                text="Змінити пароль?"
                onClick={() => {
                  handleSubmit();
                }}
                icon={false}
                disabled={isLoginBtnDisabled}
              ></DiiaButton>
            </>
          )}
        />
      </div>
    </>
  );
};

export default ResetPasswordForm;
