import React from 'react';
import PaymentComponent from '../../shared/components/payment';
const PaymentPage = () => {
  return (
    <div>
      <PaymentComponent />
    </div>
  );
};
export default PaymentPage;
