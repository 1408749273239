import React, { FC } from 'react';
import { BurgerIcon, BurgerWrapper } from './index.styles';
import burger from '../../../../assets/icons/burger.svg';

const Burger: FC<any> = ({ onClick }) => {
  return (
    <BurgerWrapper onClick={() => onClick(true)}>
      <BurgerIcon src={burger} />
    </BurgerWrapper>
  );
};

export default Burger;
