import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { adminService } from '../../../shared/services/admin/admin.service';
import Select from 'react-select';
import { FormInput } from '../../../shared/components/form-input';
import AppButton from '../../../shared/components/app-button/AppButton';
import { ToastContainer } from 'react-toastify';
import {
  showToastError,
  showToastSuccess,
} from '../../../shared/components/toast/toast';
import { useGetAllCategories } from '../../../shared/hooks/use-get-all-categories.hook';
import AppLoader from '../../../shared/components/loader/loader';
import {
  AdminContentContainer,
  AdminContentWrapper,
} from 'shared/components/admin/AdminContent.styled';
import { useAdminAuthCheck } from '../../../shared/hooks/use-admin-auth-check.hook';

const AdminCreateWebinarsPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState<any>();
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [category, setCategory] = useState<any>(null);
  useAdminAuthCheck();
  const saveMainImage = useMutation(
    async (data: any) => adminService.uploadMainImage(data),
    {
      onSuccess: res => {
        console.log(res.data.imgUrl);
        setSelectedImageUrl(res.data.imgUrl);
        showToastSuccess('картинка додана');
      },
      onError: (error: any) => {
        console.log(error);
        showToastError('щось не так');
      },
    }
  );

  const createWebinar = useMutation(
    async (data: any) => adminService.createWebinar(data),
    {
      onSuccess: res => {
        console.log(res);
        showToastSuccess('Вебінар створено');
      },
      onError: (error: any) => {
        console.log(error);
        showToastError('Щось не так');
      },
    }
  );

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(e.target.files[0]);
      const reader = new FileReader();

      reader.onload = (event: any) => {
        setSelectedImage(event.target.result);
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handleDeleteImage = () => {
    setSelectedImage(null);
    setImageFile(null);
  };

  const handleSelectImage = async () => {
    console.log(imageFile);
    const formData = new FormData();
    formData.append('file', imageFile);
    saveMainImage.mutate(formData);
  };

  const savePost = async (values: any) => {
    console.log({
      title: values.title,
      content: values.content,
      plan: values.plan,
      price: values.price,
      id_video: values.id_video,
      featured_image: selectedImageUrl,
      author: values.author,
      categories: [category.id],
    });
    createWebinar.mutate({
      title: values.title,
      content: values.content,
      plan: values.plan,
      id_video: values.id_video,
      price: Number(values.price),
      featured_image: selectedImageUrl,
      author: values.author,
      categories: [category?.id],
    });
  };

  const { options } = useGetAllCategories();

  const handleChange = (selectedOption: any) => {
    setCategory(selectedOption);
  };

  return (
    <AdminContentWrapper>
      <AdminContentContainer>
        <Form
          onSubmit={savePost}
          initialValues={{
            title: '',
            id_video: '',
            content: '',
            plan: '',
            price: '',
            author: '',
            category: 1,
          }}
          render={({ handleSubmit }) => (
            <div>
              <div style={{ margin: '20px 0 30px 0' }}>
                <label>Назва</label>
                <FormInput name="title" passedValue={''} />
              </div>
              {/*<div style={{ margin: '20px 0 30px 0' }}>
              <label>Силка на відео</label>
              <FormInput name="url_video" passedValue={''} type="text" />
            </div>*/}
              <div>
                {/*<input type="file" accept="video/*" onChange={handleFileChange} />
              <button onClick={handleUpload}>Upload Video</button>*/}
                {isLoading && <AppLoader />}
                <div style={{ margin: '20px 0 30px 0' }}>
                  <label>Відео ID</label>
                  <FormInput name="id_video" passedValue={''} type="text" />
                </div>
              </div>
              <div style={{ margin: '20px 0 30px 0' }}>
                <label>Опис</label>
                <FormInput name="content" passedValue={''} type="text" />
              </div>
              <div style={{ margin: '20px 0 30px 0' }}>
                <label>Програма</label>
                <FormInput name="plan" passedValue={''} type="text" />
              </div>
              <div style={{ margin: '20px 0 30px 0' }}>
                <label>Ціна</label>
                <FormInput name="price" passedValue={''} />
              </div>
              <div style={{ margin: '20px 0 30px 0' }}>
                <label>Автор</label>
                <FormInput name="author" passedValue={''} />
              </div>
              <div style={{ zIndex: 666, marginBottom: '20px' }}>
                <label>Категорія для вибінара</label>
                <Field
                  name="category"
                  render={({ input, meta }) => (
                    <Select
                      getOptionLabel={options => options['name']}
                      getOptionValue={options => options['id']}
                      options={options}
                      value={category}
                      onChange={values => handleChange(values)}
                    />
                  )}
                />
              </div>
              Додати привю фото
              <ToastContainer />
              <input
                style={{ margin: '20px 0' }}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {selectedImage && (
                <div>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ maxWidth: '80%', margin: '20px 0', gap: '20px' }}
                  />
                  <button onClick={handleDeleteImage}>Видалити фото</button>
                  <button onClick={handleSelectImage}>Обрати це фото</button>
                </div>
              )}
              {/*<Editor createPost={handlePost} />*/}
              <AppButton onClick={() => handleSubmit()} text={'save'} />
            </div>
          )}
        />
      </AdminContentContainer>
    </AdminContentWrapper>
  );
};

export default AdminCreateWebinarsPage;
