import { AuthEndpoints, UserEndpoints } from '../constants/endpoints-keys';
import { HttpFactoryService } from './http-factory.service';
import { HttpService } from './http.service';
import Cookies from 'js-cookie';

class UserService {
  constructor(private readonly httpService: HttpService) {}

  public async getToken() {
    const token = Cookies.get('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return config;
  }

  public async getAllUsers() {
    const res = await this.httpService.get(`${UserEndpoints.GET_ALL_USERS}`);
    return res;
  }

  public async getWebinars(limit: number, page: number) {
    const res = await this.httpService.get(
      `${UserEndpoints.GET_WEBINARS}?limit=${limit}&skip=${(page - 1) * limit}`
    );
    return res;
  }

  public async getWebinarsPurchases() {
    const res = await this.httpService.get(
      `${UserEndpoints.GET_WEBINAR_PURCHASES}`
    );
    return res;
  }

  public async getWebinarById(id: any) {
    return await this.httpService.get(
      `${UserEndpoints.GET_WEBINARS}/${id}`,
      await this.getToken()
    );
  }

  public async getWebinarsByCategory(id: any, limit: number, page: number) {
    return await this.httpService.get(
      `${UserEndpoints.GET_WEBINARS}/${id}/category?limit=${limit}&skip=${
        (page - 1) * limit
      }`,
      await this.getToken()
    );
  }

  public async getArticles(limit: number, page: number) {
    const res = await this.httpService.get(
      `${UserEndpoints.GET_ARTICLES}?limit=${limit}&skip=${(page - 1) * limit}`,
      await this.getToken()
    );
    return res;
  }

  public async getArticlesSlider() {
    return await this.httpService.get(
      `${UserEndpoints.GET_ARTICLES_SLIDER}`,
      await this.getToken()
    );
  }

  public async getArticlesByCategory(id: any, limit: number, page: number) {
    return await this.httpService.get(
      `${UserEndpoints.GET_ARTICLES}/${id}/category?limit=${limit}&skip=${
        (page - 1) * limit
      }`,
      await this.getToken()
    );
  }

  public async getArticlesById(id: any) {
    const res = await this.httpService.get(
      `${UserEndpoints.GET_ARTICLES}/${id}`,
      await this.getToken()
    );
    return res;
  }

  public async getArticlesByIdAdmin(id: any) {
    return await this.httpService.get(
      `${UserEndpoints.GET_ARTICLE_BY_ID_ADMIN}/${id}`,
      await this.getToken()
    );
  }

  public async checkSession(): Promise<any> {
    return this.httpService.get(AuthEndpoints.CHECK_SESSION);
  }

  public async logout(): Promise<any> {
    return this.httpService.get(AuthEndpoints.LOGOUT, await this.getToken());
  }

  public async getUser(): Promise<any> {
    return this.httpService.get(UserEndpoints.GET_USER, await this.getToken());
  }

  public async changePassword(body: any) {
    return this.httpService.post(UserEndpoints.CHANGE_PASSWORD, body);
  }

  public async changeNameAndMobile(body: any) {
    return this.httpService.put(UserEndpoints.GET_USER, body);
  }

  public async addFavoritesArticle(id: any) {
    return this.httpService.post(
      UserEndpoints.ADD_FAVORITES,
      {
        articleId: id,
      },
      await this.getToken()
    );
  }
}

const factory = new HttpFactoryService();
export const userService = new UserService(factory.createHttpService());
