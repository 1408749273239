import React, { useState } from 'react';
import AppModal from '../modal';
import {
  AddContainer,
  AddTextContainer,
  ContactEmail,
  ContactLink,
  ContactPhoneNumber,
  ContactsContainer,
  ModalContainer,
  ModalHeader,
} from './index.styles';

const InnovixAdd = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const phoneNumber = '+380973920998';
  const emailAddress = 'iinnovixsoft@gmail.com';

  const handlePhoneNumberClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <AppModal isOpen={isModalOpen} onClose={closeModal}>
        <ModalContainer>
          <ModalHeader>Наші контакти для звязку з нами</ModalHeader>
          <ContactsContainer>
            <ContactLink
              href="https://www.innovix.website"
              target="_blank"
              rel="noopener noreferrer"
            >
              Відвідай наш сайт
            </ContactLink>
            <ContactPhoneNumber onClick={handlePhoneNumberClick}>
              Напиши, або подзвони нам: {phoneNumber}
            </ContactPhoneNumber>
            <ContactEmail onClick={handleEmailClick}>
              Наша пошта для звязку: {emailAddress}
            </ContactEmail>
          </ContactsContainer>
        </ModalContainer>
      </AppModal>

      <AddContainer onClick={openModal}>
        <AddTextContainer>Powered by Innovix</AddTextContainer>
      </AddContainer>
    </>
  );
};

export default InnovixAdd;
