import styled from 'styled-components'; 

export const RegisterContainer = styled.div`
  width: 100%;
  flex:1;
  height: 100%;
  display: flex;
align-items: center;
justify-content: center;
text-align: center;
`;

export const LoginTitle = styled.div`
  font-size: 36px;
  margin-top: 150px;
  font-family: 'Montserrat-SemiBold';
  //margin-bottom: 20px;
  //text-align: center;
  @media only screen and (max-width: 800px) {
    margin-top: 0px;
    font-size: 28px;
  }
  @media only screen and (max-width: 528px) {
    font-size: 22px;
  }
 
`;