import styled from 'styled-components';

export const ArticlesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const ArticleContainer = styled.div`
  max-width: 1240px;
  @media only screen and (max-width: 1265px) {
    max-width: 1100px;
  }
  @media only screen and (max-width: 1140px) {
    max-width: 1000px;
  }
  @media only screen and (max-width: 1050px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 838px) {
    max-width: 600px;
    //margin-left: 20px;
    //margin-right: 20px;
    padding: 10px;
  }
  @media only screen and (max-width: 633px) {
    width: 100%;
    //margin: 20px;
    //padding: 0 60px 0 20px;
  }
  //@media only screen and (max-width: 437px) {
  //  max-width: 320px;
  //}
  //@media only screen and (max-width: 380px) {
  //  max-width: 300px;
  //}
  //@media only screen and (max-width: 333px) {
  //  max-width: 270px;
  //}
`;

export const HTMLCONTAINER = styled.div`
word-wrap: break-word;
  @media only screen and (max-width: 633px) {
    //padding: 20px;
  }
`;

export const ArticlesListContainer = styled.div`
  width: 1140px;
`;

export const ArticleTitle = styled.div`
  font-size: 26px;
  font-family: 'Montserrat-SemiBold';
  margin-bottom: 10px;
  @media only screen and (max-width: 838px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 633px) {
    font-size: 18px;
  }
`;

export const ArticleAuthor = styled.div`
  font-size: 18px;
  font-family: 'Montserrat-Regular';
  color: #03008a;
  display: flex;
  gap: 5px;
  @media only screen and (max-width: 380px) {
    font-size: 14px;
    gap: 3px;
  }
`;

export const ArticleAuthorTitle = styled.div`
  font-size: 18px;
  color: #000;
  font-family: 'Montserrat-Regular';
  @media only screen and (max-width: 380px) {
    font-size: 14px;
  }
`;

export const ArticleCategory = styled.div`
  font-size: 18px;
  font-family: 'Montserrat-Regular';
  color: #686868;
  margin: 10px 0;
  @media only screen and (max-width: 380px) {
    font-size: 14px;
  }
`;

export const ArticleSave = styled.button`
  font-size: 18px;
  display: flex;
  background: none;
  border: none;
  font-family: 'Montserrat-Regular';
  margin: 0 0 20px 0;
  gap: 5px;
  cursor: pointer;
  padding: 0;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 380px) {
    font-size: 14px;
  }
  &: hover {
    color: #0d1282;
  }
`;

export const ArticleReturnImage = styled.img`
  //width: 20px;
  height: 20px;
  transform: rotate(180deg);
  margin-right: 10px;
  //@media only screen and (max-width: 380px) {
  //  width: 15px;
  //  height: 15px;
  //}
`;

export const TextContainer = styled.div<{ ispayable: boolean }>`
  width: 100%;
  position: relative;
  margin-bottom: ${({ ispayable }) => (ispayable ? '500px' : '50px')};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari, Chrome, Opera, Samsung */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge, IE */
  user-select: none; /* Modern browsers */
  pointer-events: none;
  @media only screen and (max-width: 847px) {
    margin-bottom: 20px;
  }
`;

export const ArticleReturnBack = styled.button`
  font-size: 17px;
  display: flex;
  color: #686868;
  font-family: 'Montserrat-SemiBold';
  cursor: pointer;
  background: none;
  border: none;
  margin-bottom: 20px;
  @media only screen and (max-width: 400px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 380px) {
    font-size: 12px;
  }
  &: hover {
    color: #000;
  }
  &:hover ${ArticleReturnImage} {
    scale: 1.1;
  }
`;

export const PayWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const PaySubscriptionContainer = styled.div`
  width: 100%;
  //height: 630px;
  display: flex;
  //justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, #010101 0%, #011d45 50%, #013482 100%);
  //@media only screen and (max-width: 643px) {
  //  min-width: 500px;
  //}
`;

export const PaySubscription = styled.div`
  color: #fff;
  text-align: center;
`;

export const PaySubscriptionTitle = styled.div`
  font-size: 28px;
  font-family: 'Montserrat-SemiBold';
  margin: 36px 0 24px 0;
  @media only screen and (max-width: 667px) {
    font-size: 19px;
    margin: 24px 0 25px 0;
  }
`;
export const PaySubscriptionBoxContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  gap: 20px;
`;
export const PaySubscriptionBox = styled.div`
  border: 1px solid #a0aac2;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 40px;
  @media only screen and (max-width: 667px) {
    padding: 12px 20px;
  }
`;

export const PaySubscriptionDate = styled.div`
  font-size: 16px;
  font-family: 'Montserrat-SemiBold';
`;

export const PaySubscriptionPrice = styled.div`
  font-size: 28px;
  font-family: 'Montserrat-SemiBold';
`;
export const PaySubscriptionSocial = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #a0aac2;
  margin-top: 50px;
  padding: 40px 15px;
  gap: 15px;
`;

export const PaySubscriptionText = styled.div`
  font-size: 24px;
  font-family: 'Montserrat-Medium';
  color: #fff;
  @media only screen and (max-width: 667px) {
    font-size: 16px;
  }
`;

export const PayCover = styled.div`
  //z-index: 666;
  height: 300px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  //@media only screen and (max-width: 643px) {
  //  min-width: 500px;
  //}
  @media only screen and (max-width: 574px) {
    height: 500px;
  }
`;
