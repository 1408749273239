import React from 'react';
import {
  OfferContainer,
  OfferNumberOffset,
  OfferText,
  OfferTextTitle,
  OfferTitle,
  OfferTitleNewLine,
  OfferWrapper,
  OffetbigTitle,
} from './index.styles';

const OfferPage = () => {
  return (
    <OfferWrapper>
      <OfferContainer>
        <OffetbigTitle>
          Договір публічної оферти про надання послуг
        </OffetbigTitle>
        <OfferTitle>1. Загальні положення</OfferTitle>
        <OfferText>
          1.1. Фізична особа-підприємець Кочмарський Руслан Володимирович
          (реєстраційний номер РНОКПН 3639708456) (далі – Компанія «IQDental»,
          Виконавець), який зареєстрований та діє відповідно до законодавства
          України пропонує будь-якій фізичній особі (далі – Користувач) укласти
          цей Договір публічної оферти про надання послуг (далі – Договір).
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          1.2. Договір оферти є публічним та, згідно ст. 633, 641, 642
          Цивільного кодексу України, його умови однакові для всіх фізичних
          осіб.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          1.3. Користувач, який зайшов на Сайт Виконавця (адреса:
          https://iqdental.com.ua зобов’язаний ознайомитись з умовами Оферти.
          Якщо Користувач не згоден з умовами Оферти, він зобов’язаний припинити
          користування Сайтом та залишити сторінку.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          1.4. Якщо Користувач продовжує вчиняти активні дії з користування
          Сайтом, це свідчить про те, що Користувач прийняв умови Оферти і
          безумовно погоджується з ними.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          1.5. Використовуючи Сайт Виконавця або Послуги, Користувач приймає
          умови цього Договору, а також Політику конфіденційності Виконавця,
          опубліковану за адресою: https://iqdental.com.ua/policy.
        </OfferText>
        <OfferTitle>
          2. Такі терміни використовуються у такому значенні у цьому договорі:
        </OfferTitle>
        <OfferText>
          2.1. Послуги – набір інформаційних та консультаційних послуг з
          організації доступу до записів Продуктів Виконавця, що надаються в
          порядку і на умовах, визначених цим Договором.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          2.2. Продукт – контент Виконавця, тобто статті, наукові публікації,
          онлайн-заходи (лекції, вебінари, тренінги, інтенсиви, майстер-класи,
          онлайн-курси тощо), аудіо-та відео-уроки, а також записи
          онлайн-заходів у вигляді аудіовізуальних творів, фонограм та
          відеограм, фотографічних творів, зображень, статей, презентацій та
          будь-які інші матеріали, що пропонуються на Сайті Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          2.3. Пакет послуг – сукупність кількох Послуг, Продуктів Виконавця.
          https://iqdental.com.ua/policy
        </OfferText>
        <OfferTitle>3. Предмет договору</OfferTitle>
        <OfferText>
          3.1. За цим Договором Виконавець надає Користувачу Послуги та/або
          доступ до Продуктів Виконавця, а Користувач зобов’язується
          користуватися цими Послугами або Продуктами на умовах, які визначені у
          цьому Договорі та на Сайті Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          3.2. Детальний зміст (назви тем, формат, кількість навчальних годин
          або графік занять тощо) Продуктів Виконавця розміщуються на Сайті
          Виконавця. Читайте оферту уважно. Ця оферта є достатньою юридичною
          основою замовлення послуг та їх оплати.
        </OfferText>
        <OfferTitle>4. Умови договору</OfferTitle>
        <OfferText>
          4.1. Цей Договір складається із Загальних, Додаткових та Спеціальних
          умов (далі – Умови Договору). При цьому, Сторони узгодили, що
          Виконавець залишає за собою право змінювати Загальні, Додаткові та
          Спеціальні умови до цього Договору, які з моменту такої зміни є
          обов'язковими для сторін Договору, якщо Користувач не згодний з такими
          змінами, то він має право розірвати Договір і припинити використання
          Сайту Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          4.2. У випадку якщо між Сторонами не укладено іншого письмового
          договору, Користувач зобов’язаний керуватися положеннями, які
          викладені у тексті цього Договору (далі – Загальні умови).
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          4.3. Сторони узгодили, що Додаткові умови до цього Договору, наведені
          у відповідних розділах Сайту Виконавця на веб-сторінках замовлення
          Послуг або в надісланих електронних повідомленнях Виконавця
          (електронні листи та квитанції, рахунки та будь-які інші електронні
          повідомлення, що містять інформацію про істотні умови Договору). До
          прикладу, до Додаткових умов цього Договору можна віднести додаткові
          гарантії повернення, умови відновлювальної платної підписки або
          обмежений термін акції, тощо. Додаткові умови є невід’ємною частиною
          цього Договору.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          4.4. Сторони узгодили, що Спеціальні умови до цього Договору
          публікуються безпосередньо на веб-сторінці Сайту Виконавця з описом
          Послуги/Продукту Виконавця на Сайті або в надісланих електронних
          повідомленнях Виконавця, які також є частиною цього Договору. До
          прикладу, до Спеціальних умов цього Договору можна віднести опис
          пакета Послуг, термін та спосіб надання Послуг або їх вартість, тощо.
          Спеціальні умови до цього Договору є невід’ємною частиною цього
          Договору.
        </OfferText>
        <OfferTitle>5. Прийняття пропозиції (акцепт оферти)</OfferTitle>
        <OfferText>
          5.1. Користуючись Сайтом Виконавця та замовляючи Послуги чи Продукти,
          що пропонуються на Сайті Виконавця, Користувач робить акцепт оферти та
          укладає з Виконавцем договір, на умовах, викладених у цьому Договорі.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          5.2. Акцепт оферти рівносильний укладенню двостороннього договору у
          простій письмовій формі (ч. 2 ст. 640 Цивільного кодексу України).
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          5.3. Договір вважається укладеним з моменту вчинення однієї з дій,
          наведених нижче, залежно від того, яка з дій відбулася раніше:
        </OfferText>
        <OfferText>
          5.3.1. здійсненням дії, що свідчить про прийняття умов Договору
          (проставленням галочки в чекбоксі або аналогічною дією при реєстрації)
          на сторінці замовлення Послуги на Сайті Виконавця;
        </OfferText>
        <OfferText>
          5.3.2. оплатою виставленого Користувачу рахунку або електронного
          інвойсу платіжної системи (оплата переходом за наданим Виконавцем
          посиланням);
        </OfferText>
        <OfferText>
          5.3.3. фактичним користуванням Послугами Компанії «IQDental» або
          Продуктом Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          5.4. Акцепт оферти означає, що Користувач беззастережно приймаєте всі
          Умови Договору. Якщо Користувач не погоджується з Умовами Договору –
          Користувач залишає Сайт і припиняє використовувати Послуги чи Продукти
          Виконавця.
        </OfferText>
        <OfferTitle>6. Права та обов'язки Виконавця:</OfferTitle>
        <OfferTitle>6.1. Виконавець має право:</OfferTitle>
        <OfferText>
          6.1.1. Отримувати від Користувача інформацію, необхідну для надання
          Послуг за цим Договором;
        </OfferText>
        <OfferText>
          6.1.2. Отримувати за надані Послуги/Продукт Виконавця оплату в
          розмірах передбачених Додатковими та Спеціальними умовами до цього
          Договору;
        </OfferText>
        <OfferText>
          6.1.3. Скасовувати, переривати або переносити трансляцію будь-якого
          Продукту Виконавця, змінювати його матеріали, зміст;
        </OfferText>
        <OfferText>
          6.1.4. Відмовитися від надання Послуг в односторонньому порядку та
          скасувати доступ Користувача до обраного Продукту Виконавця у випадку
          порушення ним умов цього Договору;
        </OfferText>
        <OfferText>
          6.1.5. Інші права, встановлені умовами цього Договору.
        </OfferText>
        <OfferTitle>6.2. Виконавець зобов’язується: </OfferTitle>
        <OfferText>
          6.2.1. Надавати Користувачеві Послуги в обсязі відповідно до цього
          Договору;
        </OfferText>
        <OfferText>
          6.2.2. Надавати Користувачеві доступ до Продуктів Виконавця в
          онлайн-форматі у разі їх оплати;
        </OfferText>
        <OfferText>
          6.2.3. Інформувати Користувача про правила та вимоги щодо організації
          надання Послуг, їх якості та змісту, про права та обов'язки
          Користувача при отриманні Послуг;
        </OfferText>
        <OfferTitle>7. Права та обов'язки Користувача: </OfferTitle>
        <OfferTitle>7.1. Користувач має право: </OfferTitle>
        <OfferText>
          7.1.1. Отримати Продукт Виконавця після здійснення оплати за нього;
        </OfferText>
        <OfferText>
          7.1.2. Інші права, встановлені умовами цього Договору.
        </OfferText>
        <OfferTitle>7.2. Користувач зобов’язується: </OfferTitle>
        <OfferText>
          7.2.1. Використовувати Продукт Виконавця виключно в особистих
          некомерційних цілях;
        </OfferText>
        <OfferText>
          7.2.2. Своєчасно вносити плату за Послуги в розмірах і в терміни,
          встановлені Додатковими та Спеціальними умовами до цього Договору;
        </OfferText>
        <OfferText>
          7.2.3. Утримуватися від дій, які можуть перешкодити іншим
          Користувачам, запрошеним особам, спікерам або лекторам користуватися
          Продуктом Виконавця і отримувати інформацію в процесі та/або після
          його перегляду.
        </OfferText>
        <OfferTitle>7.3. Користувачу забороняється: </OfferTitle>
        <OfferText>
          7.3.1. Брати участь у діяльності, яка порушує функціонування Сайту
          Виконавця;
        </OfferText>
        <OfferText>
          7.3.2. Поширювати Продукт Виконавця будь-якими можливими способами, у
          тому числі, шляхом розміщення в мережі Інтернет;
        </OfferText>
        <OfferText>
          7.3.3. Поширювати Продукт Виконавця, шляхом його копіювання або у
          вигляді розшифровки, тобто перепису аудіо- або відео-матеріалів в
          текстовий формат, і перекладу на інші мови, копіювання та поширення
          статтей, на різних ресурсах;
        </OfferText>
        <OfferText>
          7.3.4. Використовувати отриману від Виконавця інформацію, матеріали,
          Продукти Виконавця у власних комерційних цілях. До прикладу, для
          самостійного проведення заходів для третіх осіб за матеріалами,
          отриманими із Продуктів Виконавця;
        </OfferText>
        <OfferText>
          7.3.5. Використовувати отриману від Виконавця інформацію, матеріали,
          Продукти Виконавця з будь-якою іншою метою, яка суперечить положенням
          цього Договору;
        </OfferText>
        <OfferText>
          7.3.6. Передавати Продукт Виконавця третім особам для спільного
          використання (навчання);
        </OfferText>
        <OfferText>
          7.3.7. Допускати поширення недостовірної, неправдивої інформації,
          інформації, яка ганьбить честь, гідність, ділову репутацію Виконавця;
        </OfferText>
        <OfferText>
          7.3.8. Інші заборони, встановлені умовами цього Договору.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          Користувач бере на себе повну відповідальність за порушення своїх
          зобов'язань, встановлених умовами цього Договору, а також за всі
          збитки, понесені Виконавцем, внаслідок такого порушення.
        </OfferText>
        <OfferTitle>8. Види та опис Послуг/Продуктів Виконавця</OfferTitle>
        <OfferText>
          8.1. Сторони узгодили, що обсяг доступних Послуг для Користувача
          залежить від придбаного ним Продукту Виконавця (або пакету
          Послуг/Продуктів Виконавця).
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          8.2. На безоплатній основі Користувач має доступ до відкритих розділів
          Сайту Виконавця. Якщо Користувач користується Послугами або
          матеріалами, доступними у відкритих розділах Сайту Виконавця, він
          отримує тільки базову інформацію з тематики, яка його цікавить.{' '}
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          8.3. У випадку придбання Користувачем платних Продуктів Виконавця, він
          отримує розширену інформацію, а також доступ до платного контенту.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          8.4. Користувач має право придбати за одноразову плату окремий Продукт
          Виконавця або пакет Послуг, що включає в себе отримання доступу
          відразу до кількох Послуг та/або Продуктів Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          8.5. Якщо можливість придбання пакету Послуг/Продуктів Виконавця на
          момент звернення Користувача до Виконавця відсутня, Користувач
          здійснює оплату обраних Послуг/Продуктів за кожну/кожен окремо.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          8.8. Доступ до Продуктів Виконавця надається Користувачам на строк, що
          не перевищує 90 днів з дати початку доступу на відео матеріал, та на
          90 або 180 днів на статті, залежно від обраного тарифу.
        </OfferText>
        <OfferTitle>9. Ціни та вартість Послуг/Продуктів Виконавця</OfferTitle>
        <OfferText>
          9.1. Вартість конкретного Продукту Виконавця, пакету Послуг/Продуктів
          Виконавця визначається Виконавцем та розміщується на веб-сторінці
          замовлення послуг на Сайті Виконавця або в електронних повідомленнях,
          надісланих ним Користувачу.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          9.2. Загальна вартість Договору складається із загальних сум,
          оплачених Користувачем.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          9.3. Надання Послуг/надання доступу до Продукту здійснюється за умови
          100% передоплати Продукту Виконавця, пакету Послуг/Продукту Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          9.4. Про можливість здійснення оплати Продукту Виконавця частинами,
          Користувачу слід уточнювати безпосередньо у Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          9.5. Вартість послуг може бути вказана у еквіваленті іноземної валюти.
          Остаточна вартість Послуг/Продукту фіксується у виставленому
          Виконавцем рахунку та відображається на веб-сторінці оплати послуг на
          Сайті Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          9.6. Оплата наданих Виконавцем Послуг здійснюється Користувачем у
          безготівковій формі у національній валюті України (UAH) або іншим
          способом, узгодженим між Сторонами.
        </OfferText>
        <OfferTitle>10.1. Списання коштів:</OfferTitle>
        <OfferText>
          10.1.1. У разі оплати Послуг на Сайті Виконавця, в момент оформлення
          замовлення відбувається разове списання суми вартості замовлених
          Послуг/Продукту Виконавця на наведених нижче умовах.{' '}
        </OfferText>
        <OfferText>
          10.1.2. Країна здійснення платежу відповідає країні реєстрації
          платника.
        </OfferText>
        <OfferText>
          10.1.3. Валюта платежу – українська гривня (UAH). Ціна може бути
          виражена в еквіваленті іноземної валюти.
        </OfferText>
        <OfferText>
          10.1.4. При оплаті Послуг з допомогою мобільного банкінгу сума
          списання визначається виходячи з курсу міжнародних платіжних систем
          VISA або MasterCard. Крім того, сума списання може включати додаткові
          комісії банків та платіжних систем.
        </OfferText>
        <OfferTitle>10.2. Невиконання платіжних зобов'язань:</OfferTitle>
        <OfferText>
          10.2.1. Виконавець залишає за собою право пред'являти претензії щодо
          порушення встановлених строків оплати Послуг Користувачем.{' '}
        </OfferText>
        <OfferText>
          10.2.2. У разі неможливості списання коштів з платіжної картки
          Користувача або чергового платежу, Виконавець має право не починати
          надання Послуг/надання доступу до Продукту або призупинити їх надання
          до моменту їх належної оплати.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          10.3. Користувач може ознайомитись із доступними способами оплати
          Послуг/Продуктів Виконавця на Сайті Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          10.4. У разі оплати послуг банківським переказом, Користувач
          зобов'язаний повідомити Виконавця про здійснений платіж і надіслати
          зображення/скрін документу, що підтверджують оплату Послуг/Продукту
          Виконавця за адресою електронної пошти, вказаною на Сайті Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          10.5. Виконавець зберігає за собою право, в будь-який момент, на свій
          розсуд, змінити спосіб оплати Послуг/Продукту Виконавця, що купується,
          і/або відмовитися від запропонованого раніше способу оплати,
          запропонувавши альтернативні способи оплати.
        </OfferText>
        <OfferTitle>
          11. Політика повернення коштів та перенесення термінів навчання
        </OfferTitle>
        <OfferText>
          11.1. Користувач має право припинити користуватись Сайтом Виконавця,
          Продуктами Виконавця, отриманими на безоплатній основі, у будь-який
          час, без вказівки причин такого припинення.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          11.2. Користувач має право припинити користуватись платними
          Послугами/Продуктами Виконавця та оформити письмовий запит на
          повернення коштів протягом двох (2) календарних днів з моменту початку
          надання Послуг, а саме:
        </OfferText>
        <OfferText>
          11.2.1. з дати отримання доступу до Продукту Виконавця;
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          11.3. Запит Користувача про оформлення повернення коштів обов'язково
          має містити: ПІБ та e-mail, вказані при оформленні замовлення; назва
          Послуги/Продукту Виконавця; дату замовлення; інформацію про платежі;
          причини відмови від Послуг.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          11.4. Якщо Виконавець отримає запит на повернення коштів у вказаний
          термін (протягом двох (2) календарних днів), незалежно від причин
          відмови Користувача, Виконавець здійснює повернення коштів Користувачу
          протягом тридцяти (30) календарних днів з дня отримання запиту за
          вирахуванням сум комісій платіжних систем, пов'язаних з поверненням.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          11.5. Повернення Виконавцем коштів Користувачу буде здійснюватися на
          окремий рахунок Виконавця. Повернені кошти Користувач може
          використовувати для оплати інших Послуг/Продуктів Виконавця протягом
          одного місяця з дати їх повернення. У випадку невикористання коштів
          протягом зазначеного терміну вони зараховуються в рахунок оплати
          витрат Виконавця, понесених на підготовку до надання Послуги/створення
          Продукту.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          11.6. У випадку пропущення Користувачем зазначеного вище строку
          направлення запиту на повернення коштів, повернення коштів не
          здійснюється.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          11.7. У випадку порушення порядку оформлення запиту на повернення
          коштів або ненадання усієї необхідної інформації, Виконавець має право
          відмовити Користувачу у поверненні коштів.
        </OfferText>
        <OfferTitle>12. Інтелектуальна власність</OfferTitle>
        <OfferText>
          12.1. Усі права інтелектуальної власності на Сайт Виконавця належать
          Виконавцю.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.2. Усі Продукти Виконавця, будь-які інші матеріали та інформація,
          отримані Користувачем, внаслідок надання Виконавцем Послуг, є
          об'єктами прав інтелектуальної власності Виконавця та підлягають
          захисту, незалежно від факту реєстрації та території їхньої дії.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.3. Права інтелектуальної власності на Продукт Виконавця, будь-які
          інші матеріали та інформацію, отримані Користувачем, внаслідок надання
          Виконавцем Послуг, належать Виконавцю. Виконавець також використовує
          об'єкти права інтелектуальної власності, що можуть належати іншим
          особам, на підставі відповідної ліцензії, договору чи на інших
          законних підставах.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.4. Виконавець не передає Користувачу будь-яких прав інтелектуальної
          власності на Продукти Виконавця, інші матеріали та інформацію,
          отриману Користувачем, внаслідок надання Виконавцем Послуг.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.5. Виконавець надає Користувачеві обмежену, персональну,
          невиключну, непередавану і відкличну ліцензію на використання Продукту
          Виконавця, інших матеріалів або інформації, отриманих від Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.6. З метою отримання доступу до Продукту Користувач погоджується
          створити і використовувати тільки один аккаунт і не передавати третім
          особам доступ або облікові дані для доступу до нього.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.7. Використання Послуг/Продукту Виконавця не дає Користувачеві
          права матеріальної або інтелектуальної власності на Продукт Виконавця,
          матеріали або будь-яку іншу інформацію, отриману від Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.8. Користувачу забороняється копіювати, відтворювати, поширювати
          або будь-яким іншим чином використовувати в комерційних чи
          некомерційних цілях Продукт Виконавця, будь-які інші матеріали та
          інформацію, отримані Користувачем, внаслідок надання Виконавцем Послуг
          чи внаслідок надання Виконавцем доступу до Продукту, створювати на їх
          основі власні інформаційні продукти, а також використовувати їх
          будь-яким іншим чином, окрім як для особистого користування.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.9. У випадку виявлення фактів незаконного використання
          інтелектуальної власності Виконавця (використання бренду; копіювання
          або переробка матеріалів Продуктів Виконавця, або їх поширення;
          надання Продуктів Виконавця третім особам для спільного використання
          та передача облікових даних для доступу до Продуктів Виконавця третім
          особам тощо) або порушення Користувачем заборон, встановлених у цьому
          Договорі, Виконавець має право припинити надання Послуг/обмежити
          доступ до Продукту та заблокувати аккаунт Користувача без повернення
          коштів, сплачених таким Користувачем.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.10. Сторони узгодили, що Виконавець має право, на свій вибір,
          вимагати від Користувача повного відшкодування збитків та упущеної
          вигоди, заподіяних Виконавцю таким порушенням.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.11. У випадку виявлення Виконавцем фактів порушення Користувачем
          заборон чи обмежень на використання Продуктів чи інших матеріалів
          Виконавця, передбачених цим Договором, окрім, збитку та упущеної
          вигоди, Користувач зобов’язується сплатити Виконавцю штраф у
          гривневому еквіваленті 10 000 дол США за курсом НБУ на дату вимоги
          Виконавця про сплату штрафу.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.12. Сторони узгодили, що факт порушення Користувачем умов Договору
          може підтверджуватись скрінами екранів, програмними засобами тощо.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          12.13. У випадку відмови порушника від добровільної виплати збитків,
          упущеної вигоди та штрафу, Виконавець залишає за собою право звернення
          до правоохоронних органів, ініціювання кримінального провадження щодо
          порушника та звернення до суду за захистом своїх порушених прав та
          законних інтересів.
        </OfferText>
        <OfferTitle>13. Відповідальність сторін</OfferTitle>
        <OfferText>
          13.1. За невиконання або неналежне виконання зобов'язань за цим
          Договором Сторони несуть відповідальність відповідно до чинного
          законодавства України та цього Договору.
        </OfferText>
        <OfferTitle>13.2. Виконавець не несе відповідальності за:</OfferTitle>
        <OfferText>
          13.2.1. Неможливість надання Послуг з причин, що не залежать від
          Виконавця;
        </OfferText>
        <OfferText>
          13.2.2. За переривання надання Послуг, пов'язане із заміною
          обладнання, програмного забезпечення або проведення інших планових
          робіт, викликаних необхідністю підтримки працездатності та розвитку
          технічних засобів;
        </OfferText>
        <OfferText>
          13.2.3. Втрату конфіденційної інформації або її частини, якщо в цьому
          відсутня вина Виконавця;
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          13.3. Ні за яких обставин сума сукупної відповідальності Виконавця за
          всіма позовами, претензіями щодо Послуг/Продуктів Виконавця не може
          перевищувати суми придбаних Користувачем Послуг/Продуктів Виконавця.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          13.4. У випадку порушення Виконавцем термінів надання Послуг, доступу
          до Продукту Виконавця, відповідальність Виконавця обмежується
          виключно:
        </OfferText>
        <OfferText>
          13.4.1. Продовженням термінів надання Послуг, доступу до Продукту
          Виконавця;
        </OfferText>
        <OfferText>
          13.4.2. Надання Послуг / доступу до Продукту у нові узгодженні із
          Користувачем терміни;
        </OfferText>
        <OfferText>
          13.4.3. Поверненням вартості послуги, наданої невчасно.
        </OfferText>
        <OfferTitle>14.5. Користувач несе відповідальність за:</OfferTitle>
        <OfferText>
          14.5.1. Достовірність та правильність реєстраційних та платіжних
          даних, зазначених у процесі реєстрації на Сайті Виконавця або
          оформлення замовлення;
        </OfferText>
        <OfferText>
          14.5.2. Використання третіми особами аккаунту Користувача, який
          використовуються для отримання доступу до Послуг/Продуктів Виконавця;
        </OfferText>
        <OfferText>
          14.5.3. Неотримання Користувачем Послуг, якщо Користувач не отримав їх
          не з вини Виконавця (наприклад, якщо Користувач пропустив одне або
          кілька занять, передбачених програмою навчання або не відвідали
          оплачену подію (воркшоп, інтенсив чи конференцію тощо);
        </OfferText>
        <OfferText>
          14.5.4. Використання контенту Компанії «IQDental», з метою подальшого
          перепродажу, розповсюдження або передачі третім особам;
        </OfferText>
        <OfferText>
          14.5.5. Порушення умов користування Сайтом Виконавця та Послугами
          Компанії «IQDental»;
        </OfferText>
        <OfferText>14.5.6. Порушення умов платежу;</OfferText>
        <OfferText>
          14.5.7. Зміст контенту, який розміщується Користувачем на Сайті
          Виконавця, у групах та чатах Компанії «IQDental».
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          14.6. У випадку виявлення Виконавцем факту порушення Користувачем
          цього Договору, Виконавець має право розірвати цей Договір в
          односторонньому порядку, заблокувати Користувачеві доступ до аккаунту
          Користувача, Продукту Виконавця, Сайту Виконавця без повернення
          сплачених Користувачем грошових коштів.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          14.7. У випадку порушення положень цього Договору Користувачем,
          Користувач зобов’язаний відшкодувати Виконавцю усі збитки, понесені
          ним у зв'язку із задоволенням претензій третіх осіб.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          14.8. Ніякі претензії щодо результативності застосування Користувачем
          знань і навичок, отриманих в результаті перегляду Продуктів Виконавця,
          не можуть бути пред'явлені Виконавцю. Користувач несе Відповідальність
          за використання цих знань і навичок, а також за будь-які результати,
          отримані в результаті використання цих знань і навичок.
        </OfferText>
        <OfferTitle>15. Обставини непереборної сили (форс-мажор)</OfferTitle>
        <OfferText>
          15.1. Сторони звільняються від відповідальності за повне або часткове
          невиконання зобов'язань, якщо це невиконання викликане обставинами,
          які не залежать від волі сторін, що унеможливлює виконання умов цього
          Договору.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          15.2. До таких обставин зокрема, але не виключно, відносяться
          надзвичайні ситуації техногенного, природного або екологічного
          характеру, аварії в системах електропостачання, руйнування цих систем,
          викликані, зокрема, землетрусами, повенями, ураганами і т.д., тривала
          відсутність електроенергії та Інтернету з незалежних від сторін
          причин, війна, військові дії, заколот, страйк, масові заворушення,
          безлади і інші протиправні дії, а також стан здоров'я лектора, спікера
          (та\або ведучого вебінару), повінь, пожежа, антитерористичні операції,
          землетрус і інші стихійні лиха, акти вандалізму третіх осіб,
          революційні дії, громадські заворушення, акти або дії органів
          державного управління, прийняття законних або підзаконних актів, які
          прямо впливають на можливість виконання сторонами умов цього Договору,
          і будь-які інші надзвичайні обставини.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          15.3. Сторона, для якої стало неможливим виконання Договору у зв’язку
          з обставинами непереборної сили, повинна негайно повідомити іншу
          Сторону як про настання таких обставин, так і про припинення таких
          обставин або їх наслідків.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          15.4. Настання форс-мажору має бути засвідчено компетентним органом,
          що визначений чинним законодавством України або повідомленнями з
          сайтів державних органів чи органів місцевого самоврядування.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          15.5. З моменту отримання такого повідомлення іншою стороною виконання
          умов цього Договору призупиняються на весь період дії форс-мажору.
        </OfferText>
        <OfferTitle>16. Термін дії Договору та зміни до нього</OfferTitle>
        <OfferText>
          16.1. Цей текст Договору, а також всі Додаткові та Спеціальні умови
          Договору, набувають чинності з моменту їх розміщення на Сайті
          Виконавця і діють до моменту їх відкликання Виконавцем.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          16.2. Виконавець має право в будь-який час внести зміни в умови
          Договору та/або відкликати оферту та/або розірвати цей Договір в
          односторонньому порядку.{' '}
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          16.3. У разі внесення Виконавцем змін до Договору, такі зміни
          набирають чинності з моменту розміщення зміненого тексту Договору на
          сайті Виконавця, якщо інший строк набрання змінами чинності не
          визначено безпосередньо в тексті зміненого Договору.
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          16.4. Припинення дії Договору не впливає на зобов'язання Користувача
          та Виконавця, які виникли до моменту припинення Договору.
        </OfferText>
        <OfferTitle>17. Прикінцеві положення</OfferTitle>
        <OfferText>
          17.1. Сторони встановили, що будь-які спори і претензії будуть
          вирішуватися шляхом переговорів.{' '}
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          17.2. Сторони усвідомлюють, що Послуги надаються Виконавцем, який
          зареєстрований і діє за законодавством України, місцезнаходження якого
          також зареєстровано в Україні.{' '}
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          17.3. Акцептуючи оферту Користувач погоджується, що всі спори,
          пов'язані з цим Договором, розглядатимуться відповідно до
          законодавства України. Користувач також погоджується, що всі такі
          спори знаходяться у виключній компетенції судів України.{' '}
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          17.4. Якщо якесь із положень цього Договору буде визнано недійсним, то
          дійсність її інших положень, у зв’язку із цим, не втрачається.{' '}
        </OfferText>
        <OfferTextTitle></OfferTextTitle>
        <OfferText>
          17.5. За наявності розбіжностей між умовами цього Договору та
          Спеціальними та Додатковими умовами до цього Договору, які
          опубліковані на Сайті Виконавця у відповідних розділах із описом
          Послуг/Продуктів Виконавця, переважну силу мають Спеціальні та
          Додаткові умови.
        </OfferText>
        <OfferTitle>18. Інформація про Виконавця</OfferTitle>
        <OfferTitle>Кочмарський Руслан Володимирович (ФОП)</OfferTitle>
        <OfferTitleNewLine>
          вул. Проспект Берестейський, 67а, корпус 1, м. Київ, Київська обл.,
          03062
        </OfferTitleNewLine>
        <OfferTitle>РНОКПП: 3639708456</OfferTitle>
        <OfferText>Телефон: +38 (068) 076-69-63</OfferText>
        <OfferNumberOffset>+38 (050) 597-10-05</OfferNumberOffset>
        <OfferText>Електронна пошта: IQDentalinfo@gmail.com</OfferText>
      </OfferContainer>
    </OfferWrapper>
  );
};

export default OfferPage;
