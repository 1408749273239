import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export const useAuthSharingDiya = (fnaAthSharingDiya: (data: {requestId:string}) => Promise<{deeplink:string}>, key: string) => {
    const [error, setError] = useState<string | null>(null);
    const queryClient = useQueryClient();
    const authSharingDiya = useMutation(fnaAthSharingDiya, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: key });
        },
        onError: (e: { response: any }) => {
            if (e && e.response?.data?.message) {
                setError(e.response?.data?.message);
            } else {
                setError('An unknown error occurred');
            }
        }
    });
    const { status } = authSharingDiya;
    return { authSharingDiya, status, error };
};
