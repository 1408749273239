import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  password: yup.string().required("Пароль є обов'язковим"),
});

type SignUpForm = {
  phone: string;
};

export const validate = async (values: any) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
    return {};
  } catch (err) {
    const errors: any = {};
    if (err instanceof yup.ValidationError) {
      err.inner.forEach((validationError: any) => {
        errors[validationError.path] = validationError.message;
      });
    }
    return errors;
  }
};
