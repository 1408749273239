import React from 'react';
import { calculateDaysLeft } from '../../../shared/utils';
import {
  SubExpire,
  SubHeader,
  SubText,
  SubWrapper,
} from './subscription.styles';

const ActiveSubscription = ({ expirationDate }: any) => {
  return (
    <SubWrapper>
      <SubHeader>Підписка активна</SubHeader>
      <SubText>Залишилося до кінця підписки </SubText>
      <SubExpire>{calculateDaysLeft(expirationDate)} дні </SubExpire>
    </SubWrapper>
  );
};

export default ActiveSubscription;
