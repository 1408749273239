import * as React from 'react';
import {  Routes } from 'react-router-dom';
import { useAuthCheck } from '../shared/hooks/use-auth-check.hook';

import { adminRoutes, privateRoutes } from './routes';

const Router: React.FunctionComponent = () => {
  //const { isAuth } = useAuth();

  //useAuthCheck();

  return (
    <Routes>
      {privateRoutes}
      {adminRoutes}
    </Routes>
  );
};
export default Router;
