import React, { FC } from 'react';
import {
  WebinarButtonContainer,
  WebinarCardAuthor,
  WebinarCardCategory,
  WebinarCardContainer,
  WebinarCardImage,
  WebinarCardPrice,
  WebinarCardTextContainer,
  WebinarCardTitle,
} from './index.styles';
import sliderImag1 from '../../../../assets/image/image1.png';
import AppButton from '../../app-button/AppButton';
import { useWindowSize } from '../../../hooks/useScreenSize';

type IWebinarCardAdmin = {
  author: string;
  title: string;
  category: string;
  price: string;
  id: string | number;
  onClick: (id: string | number) => void;
  buttonText?: string;
  showedButton?: boolean;
  imageUrl?: string;
  purchases?: number;
};

const WebinarCardAdmin: FC<IWebinarCardAdmin> = ({
  author,
  title,
  category,
  price,
  id,
  onClick,
  buttonText = 'Переглянути',
  imageUrl,
  showedButton = true,
  purchases,
}) => {
  const { width } = useWindowSize();
  return (
    <WebinarCardContainer onClick={() => onClick(id)}>
      <WebinarCardImage
        src={imageUrl ? imageUrl : sliderImag1}
        alt=""
        width={300}
      />
      <WebinarCardTextContainer>
        <div>
          <WebinarCardTitle>
            {title.length > 80 ? title.slice(0, 80) + '...' : title}
          </WebinarCardTitle>
          <WebinarCardAuthor>{author}</WebinarCardAuthor>
          <WebinarCardCategory>{category}</WebinarCardCategory>
          {`Придбали: ${purchases}`}
        </div>
        <WebinarButtonContainer>
          <WebinarCardPrice>{price} грн</WebinarCardPrice>

          {showedButton && (
            <AppButton
              onClick={() => onClick(id)}
              text={buttonText}
              width={width < 614 ? '120px' : '240px'}
            />
          )}
        </WebinarButtonContainer>
      </WebinarCardTextContainer>
    </WebinarCardContainer>
  );
};

export default WebinarCardAdmin;
