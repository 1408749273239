import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { RouterKeys } from 'router/keys';
import { NavbarLink, NavbarLogo } from 'shared/components/Navbar/Navbar.styled';
import Logo from '../../../assets/icons/logo.svg';
import {
  SideBarContainer,
  SidebarLink,
  SidebarLogo,
  SidebarSpanText,
  SidebarWrapper,
} from 'shared/components/admin/AdminSidebar.styled';

import Edit from '../../../assets/icons/edit.svg';
import Webinar from '../../../assets/icons/webinar.svg';
import Article from '../../../assets/icons/article.svg';

const Sidebar = () => {
  const navigate = useNavigate();
  return (
    <SidebarWrapper>
      {/* <SidebarLogo onClick={() => navigate(RouterKeys.ADMIN)}>
        <img src={Logo} alt="logo" />
      </SidebarLogo> */}
      <SideBarContainer>
        <NavLink to={RouterKeys.ADMIN_ARTICLES} className="no-underline">
          <SidebarLink>
            <img
              src={Article}
              alt="edit"
              style={{ width: '20px', marginRight: '4px' }}
            />
            Статті
          </SidebarLink>
        </NavLink>
        <NavLink to={RouterKeys.ADMIN_CREATE_ARTICLES} className="no-underline">
          <SidebarLink>
            <img
              src={Edit}
              alt="edit"
              style={{ width: '20px', marginRight: '4px' }}
            />
            <SidebarSpanText>{`Створення\xa0`}</SidebarSpanText>
            Статті
          </SidebarLink>
        </NavLink>

        <NavLink to={RouterKeys.ADMIN_WEBINARS} className="no-underline">
          <SidebarLink>
            <img
              src={Webinar}
              alt="edit"
              style={{ width: '20px', marginRight: '4px' }}
            />
            Вебінари
          </SidebarLink>
        </NavLink>
        <NavLink to={RouterKeys.ADMIN_CREATE_WEBINARS} className="no-underline">
          <SidebarLink>
            <img
              src={Edit}
              alt="edit"
              style={{ width: '20px', marginRight: '4px' }}
            />
            <SidebarSpanText>{`Створення\xa0`}</SidebarSpanText>
            Вебінару
          </SidebarLink>
        </NavLink>
        <NavLink to={RouterKeys.ADMIN_BLACKLIST} className="no-underline">
          <SidebarLink>
            <img
              src={Edit}
              alt="edit"
              style={{ width: '20px', marginRight: '4px' }}
            />
            Blacklist
          </SidebarLink>
        </NavLink>
      </SideBarContainer>
    </SidebarWrapper>
  );
};

export default Sidebar;
