export const COLORS = {
  primaryBlue: '#0D1282',
  greyPrimary: '#A0AAC2',
  secondaryGrey: '#686868',


  
  green: '#00BD43',
  greenSecondary: '#0B953C',
  primaryWhite: '#FDFEFE',
  background: '#FCFCFC',
  blackText: '#0D0D0D',
  purple: '#8c42f4',
  purpleSecondary: '#6A38B0',
  errorColor: '#fb2929',
  errorSecondary: '#FFE6E6',
  errorBorder: '#FF9090',
  activePurple: '#b682ff',
  disableColor: '#F2F2F2',

  boxShadow: '4px 4px 18px #e8eaef',

  white: '#ffffff',
  black: '#000000',

  primary: '#141414',
  secondary: '#141414',
  transparent: 'transparent'
};
