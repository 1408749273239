import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode.react';
import io, { Socket } from 'socket.io-client';

import {
  DiiaContainer,
  DiiaQR,
  DiiaText,
  DiiaTextContainer,
  LoginFormContainer,
  LoginTitle,
  MoveButtonContainer,
  Restore,
  TimerContainer,
  TimerLink,
  TimerText,
  CheckboxWrapper,
  DiiaRow,
  ButtonLoginContainer,
  ImageContainer,
  LoginImage,
  DiiaLink,
  AlternativeContainer,
  AlternativeButton,
  AlternativeText,
} from './index.styles';

import { USER_KEYS } from '../../../shared/constants/app-keys';
import { useAuthSharingDiya } from '../../../shared/hooks/useAuthSharingDiya';
import { authDiyaService } from '../../../shared/services/auth-diya-service';
import DiiaButton from '../../../shared/components/app-button/DiiaButton';
import AppLoader from '../../../shared/components/loader/loader';
import { useWindowSize } from '../../../shared/hooks/useScreenSize';
import { useMutation } from 'react-query';
import { userService } from '../../../shared/services/user.service';
import { useAuth } from '../../../store/auth';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// @ts-ignore
import backImage from '../../../assets/image/202312241515.gif';
import { RouterKeys } from 'router/keys';

const LoginForm = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [errorLogin, setErrorLogin] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForUser, setIsLoadingForUser] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [deepLink, setDeepLink] = useState('');
  const [deepLinkButton, setDeepLinkButton] = useState('');
  const [timer, setTimer] = useState(180);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isAgreedPhone, setIsAgreedPhone] = useState(false);
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const { authSharingDiya, error, status } = useAuthSharingDiya(
    authDiyaService.loginSharingDiya,
    USER_KEYS.LOGIN_DIYA
  );

  const getUser = useMutation(() => userService.getUser(), {
    onSuccess: () => {
      useAuth.getState().updateAuth(true)();
      navigate('/');
    },
    onError: () => {
      useAuth.getState().updateAuth(false)();
    },
  });

  useEffect(() => {
    try {
      const newSocket = io(process.env.REACT_APP_SERVER_URL ?? '');
      setSocket(newSocket);

      newSocket.on('connect', () => {
        setIsConnected(true);
        if (requestId) {
          newSocket.emit('register', { requestId });
        }
      });

      const tryReconnect = () => {
        const maxReconnectAttempts = 15;
        let attempts = 0;

        const reconnectInterval = setInterval(() => {
          if (!isConnected && attempts < maxReconnectAttempts) {
            attempts++;
            newSocket.connect();
          } else {
            clearInterval(reconnectInterval);
          }
        }, 4000);

        return () => clearInterval(reconnectInterval);
      };

      newSocket.on('disconnect', () => {
        setIsConnected(false);
        tryReconnect();
      });

      newSocket.on('data_response', message => {
        Cookies.set('token', message.token.access_token, {
          expires: 7,
          path: '/',
        });
        setIsLoadingForUser(false);
        getUser.mutate();
      });

      newSocket.on('data_error', message => {
        setErrorLogin(message);
      });

      return () => {
        newSocket.off('connect');
        newSocket.off('disconnect');
        newSocket.disconnect();
      };
    } catch (error) {}
  }, [requestId]);

  const loginDiya = async () => {
    setIsLoading(true);
    if (socket) {
      const newRequestId = uuidv4();
      setRequestId(newRequestId);

      const data = { requestId: newRequestId };
      const response = await authSharingDiya.mutateAsync(data);
      const url = `https://diia.page.link?link=${response?.deeplink}&apn=ua.gov.diia.app&isi=1489717872&ibi=ua.gov.diia.app`;

      socket.emit('register', { requestId: newRequestId });

      setDeepLink(response?.deeplink);
      setDeepLinkButton(url);
      setTimer(180);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(t => (t > 0 ? t - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleAgreed = () => {
    setIsAgreed(!isAgreed);
  };

  const handleAgreedPhone = () => {
    setIsAgreedPhone(!isAgreedPhone);
  };

  return (
    <div style={{ display: 'flex', height: '100%', gap: 20 }}>
      <LoginFormContainer>
        <LoginTitle>
          Науково-освітня платформа для лікарів-стоматологів з України
        </LoginTitle>
        {/*<button onClick={handlerVideo}>Get Video</button>*/}
        <DiiaContainer>
          <DiiaQR>
            {/*<DiiaGerb>*/}
            {/* <DiiaImage src={login_logo} alt="Diia" /> */}
            {/* <img
                src={login_logo}
                alt="evidence-based"
                style={{ width: '360px' }}
              /> */}
            {/* <DiiaImage src={gerb} width="150" alt="Описание" /> */}
            {/*</DiiaGerb>*/}

            {/*{deepLink && (*/}
            {/*  <TimerContainer>*/}
            {/*    <QRCode value={deepLink} />*/}

            {/*    <Restore>*/}
            {/*      {errorLogin && (*/}
            {/*        <p style={{ color: 'red' }}>*/}
            {/*          Щось пішло не так, спробуйте авторизуватісь через Дія*/}
            {/*          ще раз.*/}
            {/*        </p>*/}
            {/*      )}*/}
            {/*      <TimerText>*/}
            {/*        Таймер відліку дії сесії: {formatTime()}*/}
            {/*      </TimerText>*/}
            {/*      <DiiaButton*/}
            {/*        onClick={loginDiya}*/}
            {/*        text="Оновити сесію"*/}
            {/*        icon={false}*/}
            {/*      />*/}
            {/*    </Restore>*/}
            {/*  </TimerContainer>*/}
            {/*)}*/}
          </DiiaQR>
          {deepLink && width < 1000 ? (
            <>
              <Restore>
                {errorLogin && (
                  <p style={{ color: 'red' }}>
                    Щось пішло не так, спробуйте авторизуватісь через Дія ще
                    раз.
                  </p>
                )}
                <TimerText>Таймер відліку дії сесії: {formatTime()}</TimerText>
                <DiiaButton
                  onClick={loginDiya}
                  text="Оновити сесію"
                  icon={false}
                />
              </Restore>
              <MoveButtonContainer>
                <TimerLink
                  href={deepLinkButton}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DiiaButton
                    onClick={() => setIsLoadingForUser(true)}
                    width={'100%'}
                    isLoading={isLoadingForUser}
                    text="Перейти в "
                  />
                </TimerLink>
              </MoveButtonContainer>
            </>
          ) : (
            deepLink && (
              <>
                <TimerContainer>
                  <QRCode value={deepLink} />

                  <Restore>
                    {errorLogin && (
                      <p style={{ color: 'red' }}>
                        Щось пішло не так, спробуйте авторизуватісь через Дія ще
                        раз.
                      </p>
                    )}
                    <TimerText>
                      Таймер відліку дії сесії: {formatTime()}
                    </TimerText>
                    <DiiaButton
                      onClick={loginDiya}
                      text="Оновити сесію"
                      icon={false}
                    />
                  </Restore>
                </TimerContainer>
                <DiiaText>*Зчитайте QR-код сканером у застосунку Дія</DiiaText>
              </>
            )
          )}

          {!isLoading ? (
            !deepLink && (
              <ButtonLoginContainer>
                <DiiaButton
                  onClick={loginDiya}
                  width={'100%'}
                  isLoading={isLoading}
                  text="Вхід через застосунок "
                  disabled={!isAgreed}
                />
              </ButtonLoginContainer>
            )
          ) : (
            <AppLoader />
          )}
          {!isLoading ? (
            !deepLink && (
              <>
                <div style={{ display: 'flex' }}>
                  <DiiaText>*</DiiaText>
                  <DiiaText>
                    Для користування ресурсом вам потрібно надіслати копію
                    паспорта (ID-картки або закордонного) через застосунок Дія.
                  </DiiaText>
                </div>
                <DiiaRow>
                  <CheckboxWrapper className="checkbox-wrapper-30">
                    <span className="checkbox">
                      <input
                        type="checkbox"
                        style={{ width: '21.5px', height: '21.5px' }}
                        onChange={handleAgreed}
                        checked={isAgreed}
                      />
                      <svg>
                        <use
                          xlinkHref="#checkbox-30"
                          className="checkbox"
                        ></use>
                      </svg>
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ display: 'none' }}
                    >
                      <symbol id="checkbox-30" viewBox="0 0 22 22">
                        <path
                          fill="none"
                          stroke="currentColor"
                          d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                        />
                      </symbol>
                    </svg>
                  </CheckboxWrapper>

                  <DiiaLink onClick={() => navigate('/policy')}>
                    Погоджуюсь з умовами використання.
                  </DiiaLink>
                </DiiaRow>
              </>
            )
          ) : (
            <></>
          )}
          <DiiaTextContainer>
            {/*<DiiaCondition onClick={() => navigate('/policy')}>
              <DiiaConditionText>
                Перейти до умов використання
              </DiiaConditionText>
            </DiiaCondition>*/}
          </DiiaTextContainer>
          <AlternativeContainer>
            <DiiaText>Немає ID каркти чи закордоного паспорта?</DiiaText>

            <AlternativeButton
              disabled={!isAgreedPhone}
              onClick={() => navigate('/' + RouterKeys.LOGIN)}
            >
              <AlternativeText>Альтернативний вхід</AlternativeText>
            </AlternativeButton>
            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex' }}>
                <DiiaText>*</DiiaText>
                <DiiaText>
                  Авторизація через альтернативний метод можлива лише з
                  пристрою, на якому було здійснено реєстрацію. Для додавання
                  додаткового пристрою, перейдіть до налаштувань вашого
                  особистого кабінета. У разі необхідності зміни пристроїв,
                  зверніться до технічної підтримки.
                </DiiaText>
              </div>
              <DiiaRow>
                <CheckboxWrapper className="checkbox-wrapper-30">
                  <span className="checkbox">
                    <input
                      type="checkbox"
                      style={{ width: '21.5px', height: '21.5px' }}
                      onChange={handleAgreedPhone}
                      checked={isAgreedPhone}
                    />
                    <svg>
                      <use xlinkHref="#checkbox-30" className="checkbox"></use>
                    </svg>
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ display: 'none' }}
                  >
                    <symbol id="checkbox-30" viewBox="0 0 22 22">
                      <path
                        fill="none"
                        stroke="currentColor"
                        d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                      />
                    </symbol>
                  </svg>
                </CheckboxWrapper>

                <DiiaLink onClick={() => navigate('/policy')}>
                  Погоджуюсь з умовами використання.
                </DiiaLink>
              </DiiaRow>
            </div>
          </AlternativeContainer>
        </DiiaContainer>
      </LoginFormContainer>
      {width > 800 && (
        <ImageContainer>
          <LoginImage src={backImage} alt="img" />
        </ImageContainer>
      )}
    </div>
  );
};

export default LoginForm;
