import styled from 'styled-components';

export const HelpContainer = styled.div<{ $opacity?: string }>`
  background-color: rgba(255, 255, 255, 1);
  background-image: none;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  position: fixed;
  bottom: 62pt;
  right: 12px;
  border-radius: 10px;
  padding: 24px;
  transition: all 0.3s ease-out;
  opacity: ${props => props.$opacity};
`;

export const TelegramContainer = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 21;
`;

export const TelegramIcon = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const NotificationDot = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  z-index: 20;
`;

export const HelpButton = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  background-image: none;
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  position: fixed;
  bottom: 12pt;
  right: 12pt;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

export const HelpText = styled.p`
  font-family: Montserrat-Regular;
  font-size: 14px;
  span {
    font-weight: bold;
  }
`;

export const HelpTitle = styled.p`
  font-family: Montserrat-Medium;
  font-size: 16px;
`;

export const HelpTextTitle = styled.p`
  font-family: Montserrat-Medium;
  font-size: 14px;
  margin: 10px 0;
`;
