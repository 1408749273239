import * as React from 'react';

function ArrowRight({
    color,
    rotate,
    ...props
}) {
    return (
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"  transform={`rotate(${rotate})`}>
            <path d="M1 17L9 9.00003L1 1.00003" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export default ArrowRight;
