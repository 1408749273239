import React, { FC, useState } from 'react';
import {
  MobileMenuAuth,
  MobileMenuAuthContainer,
  MobileMenuAuthText,
  MobileMenuButton,
  MobileMenuClose,
  MobileMenuIcons,
  MobileMenuLink,
  MobileMenuList,
  MobileMenuLogout,
  MobileMenuSocialIcons,
  MobileMenuSocialTitle,
  MobileMenuText,
  MobileMenuTextContainer,
  MobileMenuTitle,
  MobileMenuWrapper,
} from './index.styles';
import Logo from '../../../../assets/icons/logo.svg';
import close from '../../../../assets/icons/close.svg';
import userCircle from '../../../../assets/icons/userCircle.svg';
import { NavbarLogo } from '../Navbar.styled';
import { useNavigate } from 'react-router-dom';
import { RouterKeys } from '../../../../router/keys';
import instagram from '../../../../assets/icons/Instagram.svg';
import facebook from '../../../../assets/icons/facebook.svg';
import telegram from '../../../../assets/icons/telegram.svg';
import { useAuth } from '../../../../store/auth';
import logoutIcon from '../../../../assets/icons/logout.svg';
import userIcon from '../../../../assets/icons/user.svg';
import { useMutation, useQuery } from 'react-query';
import { userService } from '../../../services/user.service';
import { STORAGE_KEYS } from '../../../constants/app-keys';
import AppOutlineButton from '../../app-button/AppOutlineButton';
import Cookies from 'js-cookie';

const MobileMenu: FC<any> = ({ isOpen, onClick }) => {
  const [user, setUser] = useState<any>();
  const navigate = useNavigate();
  const { isAuth } = useAuth();
  const userName = localStorage.getItem(STORAGE_KEYS.USER_NAME);

  const { isLoading } = useQuery<any, any>(
    ['user'],
    () => userService.getUser(),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setUser(res.data);
      },
      onError: () => {},
    }
  );

  const logout = () => {
    useAuth.getState().updateAuth(false)();
    navigateToLogin();
    Cookies.remove('token');
    localStorage.removeItem(STORAGE_KEYS.USER_NAME);
    localStorage.removeItem(STORAGE_KEYS.USER_EMAIL);
    navigate('/sing-in');
  };

  const logoutMutation = useMutation(async () => userService.logout(), {
    onSuccess: () => {
      Cookies.remove('token');
      logout();
    },
    onError: () => {
      logout();
    },
  });

  const navigateToLogin = () => {
    onClick(false);
    navigate('sign-in');
  };

  const navigateTo = (point: string) => {
    onClick(false);
    navigate(point);
  };

  const navigateToPrice = async (event: any) => {
    event.preventDefault();
    await navigate(`${RouterKeys.HOME}`);
    const element: any = document.getElementById('price');
    element.scrollIntoView({ behavior: 'smooth' });
    onClick(false);
  };

  const navigateToFAQ = async (event: any) => {
    event.preventDefault();
    await navigate(`${RouterKeys.HOME}`);
    const element: any = document.getElementById('faq');
    element.scrollIntoView({ behavior: 'smooth' });
    onClick(false);
  };

  return (
    <MobileMenuWrapper isOpen={isOpen}>
      <MobileMenuIcons>
        <MobileMenuButton onClick={() => navigate(RouterKeys.HOME)}>
          <img src={Logo} alt="logo" width={131} />
        </MobileMenuButton>
        <MobileMenuButton onClick={() => onClick(false)}>
          <MobileMenuClose src={close} alt="close" />
        </MobileMenuButton>
      </MobileMenuIcons>
      <MobileMenuAuth>
        {isAuth ? (
          <MobileMenuAuthContainer>
            <MobileMenuAuthText>
              <img src={userIcon} alt="user" />
              {user?.name !== undefined ? user.name : userName}
            </MobileMenuAuthText>
            <AppOutlineButton
              onClick={() => navigateTo(RouterKeys.USER)}
              text="Профіль"
              width="170px"
            />
          </MobileMenuAuthContainer>
        ) : (
          <>
            <img src={userCircle} alt="user" width={40} />
            <MobileMenuTextContainer>
              <MobileMenuTitle onClick={navigateToLogin}>
                Вхід | Реєстрація
              </MobileMenuTitle>
              <MobileMenuText>
                Авторизуйтесь для отримання розширених можливостей
              </MobileMenuText>
            </MobileMenuTextContainer>
          </>
        )}
      </MobileMenuAuth>
      <MobileMenuList>
        <MobileMenuLink onClick={() => navigateTo(RouterKeys.ARTICLES)}>
          Статті
        </MobileMenuLink>
        <MobileMenuLink onClick={() => navigateTo(RouterKeys.WEBINARS)}>
          Вебінари
        </MobileMenuLink>
        <MobileMenuLink onClick={navigateToPrice}>Тарифи</MobileMenuLink>
        <MobileMenuLink onClick={navigateToFAQ}>FAQ</MobileMenuLink>
      </MobileMenuList>
      <MobileMenuSocialTitle>Ми в соціальних мережах</MobileMenuSocialTitle>
      <MobileMenuSocialIcons>
        <img src={instagram} alt="instagram" />
        <img src={facebook} alt="facebook" />
        <img src={telegram} alt="telegram" />
      </MobileMenuSocialIcons>
      {isAuth && (
        <MobileMenuLogout onClick={() => logoutMutation.mutate()}>
          Вийти з акаунту{' '}
        </MobileMenuLogout>
      )}
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
