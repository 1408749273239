import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import AppButton from '../../../shared/components/app-button/AppButton';
import { FormInput } from '../../../shared/components/form-input';
import TextEditor from '../../../shared/components/editor/text-editor';
import { adminService } from '../../../shared/services/admin/admin.service';
import AppCheckbox from '../../../shared/components/checkbox';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { useGetAllCategories } from '../../../shared/hooks/use-get-all-categories.hook';
import {
  AdminContentContainer,
  AdminContentWrapper,
} from 'shared/components/admin/AdminContent.styled';
import { useAdminAuth } from '../../../store/admin-auth';
import { useNavigate } from 'react-router-dom';
import { userService } from '../../../shared/services/user.service';

const AdminUpdateArticlesPage = () => {
  const [postData, setPostData] = useState<string>('');
  const [isPayable, setIsPayable] = useState(false);
  const [html, setHtml] = useState();
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [imageFile, setImageFile] = useState<any>();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [category, setCategory] = useState<any>(null);
  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params?.id) || null;

  useEffect(() => {
    if (!id) {
      navigate('/admin/articles');
      return;
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res: any = await adminService.check();
        useAdminAuth.getState().updateAuth(true)();
        console.log(res.data);
        if (!res.data?.isVerifySession) {
          useAdminAuth.getState().updateAuth(false)();
          navigate('/admin/sign-in');
        }
      } catch (error) {
        useAdminAuth.getState().updateAuth(false)();
        navigate('/admin/sign-in');
        console.log(error);
      }
    })();
  }, []);

  const showToastError = () => {
    toast.error('Щось зламалось', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const showToast = () => {
    toast.success('Успішно збережено ', {
      position: 'bottom-left',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const { data } = useQuery<any, any>(
    'article',
    () => userService.getArticlesByIdAdmin(id),
    {
      keepPreviousData: true,
      onSuccess(res) {
        setIsPayable(res.data.is_paid);
        setTitle(res.data.title);
        setAuthor(res.data.author);
        setCategory(res.data?.categories?.[0]);
        setSelectedImageUrl(res.data.featured_image);
        setSelectedImage(res.data.featured_image);
        setHtml(res.data.content);
        setPostData(res.data.content);
        setIsLoading(false);
      },
      onError(err) {
        showToastError();
      },
    }
  );

  const { options } = useGetAllCategories();

  const mutation = useMutation(
    async (data: any) => adminService.updatePost(data),
    {
      onSuccess: () => {
        showToast();
        setTimeout(() => {
          navigate('/admin/articles');
        }, 3000);
      },
      onError: (error: any) => {
        console.log(error);
        showToastError();
      },
    }
  );

  const saveMainImage = useMutation(
    async (data: any) => adminService.uploadMainImage(data),
    {
      onSuccess: res => {
        console.log(res.data.imgUrl);
        setSelectedImageUrl(res.data.imgUrl);
        showToast();
      },
      onError: (error: any) => {
        console.log(error);
        showToastError();
      },
    }
  );

  const handlePost = (data: any) => {
    setPostData(data);
  };

  const updatePost = async (values: any) => {
    await mutation.mutateAsync({
      id,
      title: values.title,
      content: postData,
      is_paid: values.isPayable,
      featured_image: selectedImageUrl,
      author: values.author,
      categories: [category.id],
    });
  };

  const handleImageChange = (e: any) => {
    const file = e?.target?.files?.[0];
    if (file) {
      setImageFile(e?.target?.files?.[0]);
      const reader = new FileReader();

      reader.onload = (event: any) => {
        setSelectedImage(event.target.result);
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handleDeleteImage = () => {
    setSelectedImage(null);
    setImageFile(null);
  };

  const handleSelectImage = async () => {
    const formData = new FormData();
    formData.append('file', imageFile);
    saveMainImage.mutate(formData);
  };

  const handleChange = (selectedOption: any) => {
    setCategory(selectedOption);
  };

  return (
    <AdminContentWrapper>
      <ToastContainer position="bottom-center" />

      <AdminContentContainer>
        Додати привю фото
        <input
          name="featured_image"
          id="featured_image"
          style={{ margin: '20px 0' }}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
        {selectedImage && (
          <div>
            <img
              src={selectedImage}
              alt="Selected"
              style={{ maxWidth: '80%', margin: '20px 0', gap: '20px' }}
            />
            <button onClick={handleDeleteImage}>Видалити фото</button>
            <button onClick={handleSelectImage}>Обрати це фото</button>
          </div>
        )}
        <Form
          onSubmit={updatePost}
          initialValues={{
            isPayable: isPayable,
            title: title,
            category: 1,
            author: author,
          }}
          render={({ handleSubmit }) => (
            <div>
              {/* Платна стаття */}
              <div style={{ display: 'flex', gap: '20px' }}>
                <label style={{ fontSize: 20 }}> Платна стаття </label>
                <Field
                  name="isPayable"
                  type="checkbox"
                  render={({ input }) => (
                    <AppCheckbox
                      name={input.name}
                      label=""
                      checked={!!input.checked}
                      onChange={input.onChange}
                    />
                  )}
                />
              </div>
              {/* Категорія для статті */}
              <div style={{ zIndex: 666 }}>
                <label>Категорія для статті</label>
                <Field
                  name="category"
                  render={({ input, meta }) => (
                    <Select
                      getOptionLabel={options => options['name']}
                      getOptionValue={options => options['id']}
                      value={category}
                      onChange={values => handleChange(values)}
                      options={options}
                    />
                  )}
                />
              </div>
              {/* Назва */}
              <div style={{ margin: '20px 0 30px 0' }}>
                <label>Назва</label>
                <FormInput name="title" passedValue={''} />
              </div>
              {/* Автор */}
              <div style={{ margin: '20px 0 30px 0' }}>
                <label>Автор</label>
                <FormInput name="author" passedValue={''} />
              </div>
              <TextEditor handlePost={handlePost} initialContent={html} />
              {/*<Editor createPost={handlePost} />*/}
              <AppButton onClick={() => handleSubmit()} text={'save'} />
            </div>
          )}
        />
      </AdminContentContainer>
    </AdminContentWrapper>
  );
};

export default AdminUpdateArticlesPage;
