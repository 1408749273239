import React, { useLayoutEffect, useState } from 'react';
import AppButton from '../app-button/AppButton';
import {
  NavbarWrapper,
  NavbarLogo,
  NavbarLinks,
  NavbarLink,
  NavbarContainer,
  NavbarText,
  NavbarButtons,
} from './Navbar.styled';
import Logo from '../../../assets/icons/logo.svg';
import { useAuth } from '../../../store/auth';
import { NavLink, useNavigate } from 'react-router-dom';
import { RouterKeys } from '../../../router/keys';
import { STORAGE_KEYS } from '../../constants/app-keys';
import UserTools from './UserTools/UserTools';
import { useMutation } from 'react-query';
import { userService } from '../../services/user.service';
import { useWindowSize } from '../../hooks/useScreenSize';
import Burger from './burger';
import MobileMenu from './burger/mobile-menu';
import { useAuthCheck } from '../../hooks/use-auth-check.hook';
import Cookies from 'js-cookie';

const Navbar = () => {
  const [mobileMod, setMobileMod] = useState<boolean>(false);
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  useAuthCheck();
  const goToUser = () => {
    navigate('/user');
  };

  useLayoutEffect(() => {
    if (width > 616) setMobileMod(false);
    if (width <= 616) setMobileMod(true);
  }, [width]);

  const { isAuth } = useAuth();



  const mutation = useMutation(async () => userService.logout(), {
    onSuccess: (res: any) => {
      Cookies.remove('token');
      useAuth.getState().updateAuth(false)();
      localStorage.removeItem(STORAGE_KEYS.USER_NAME);
      localStorage.removeItem(STORAGE_KEYS.USER_EMAIL);
      navigate('/sing-in');
    },
    onError: (error: any) => {
      Cookies.remove('token');
      useAuth.getState().updateAuth(false)();
      localStorage.removeItem(STORAGE_KEYS.USER_NAME);
      localStorage.removeItem(STORAGE_KEYS.USER_EMAIL);
      navigate('/sing-in');
    },
  });

  const logout = () => {
    mutation.mutate()
  };

  const mobileMenuHandler = (flag: boolean) => {
    setOpenMobileMenu(flag);
  };

  const navigateToPrice = async (event: any) => {
    event.preventDefault();
    await navigate(`${RouterKeys.HOME}`);
    const element: any = document.getElementById('price');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const navigateToFAQ = async (event: any) => {
    event.preventDefault();
    await navigate(`${RouterKeys.HOME}`);
    const element: any = document.getElementById('faq');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <NavbarWrapper>
      <NavbarContainer>
        {mobileMod && (
          <MobileMenu onClick={mobileMenuHandler} isOpen={openMobileMenu} />
        )}
        {mobileMod && <Burger onClick={mobileMenuHandler} />}
        <NavbarLogo onClick={() => navigate(RouterKeys.HOME)}>
          <img src={Logo} alt="logo" />
        </NavbarLogo>
        {mobileMod && <div style={{ width: 36 }} />}
        <NavbarLinks>
          <NavLink to={'/articles'} className="no-underline">
            <NavbarText>Статті</NavbarText>
          </NavLink>
          <NavLink to={'/webinars'} className="no-underline">
            <NavbarText>Вебінари</NavbarText>
          </NavLink>
          <NavbarLink onClick={navigateToPrice}>Тарифи</NavbarLink>
          <NavbarLink onClick={navigateToFAQ}>FAQ</NavbarLink>
        </NavbarLinks>
        <NavbarButtons>
          {isAuth ? (
            <UserTools goToUser={goToUser} logoutHandler={logout} />
          ) : (
            <AppButton
              onClick={() => navigate('/sign-in')}
              text="Увійти"
              width={width < 818 ? '160' : '200px'}
            />
          )}
        </NavbarButtons>
      </NavbarContainer>
    </NavbarWrapper>
  );
};

export default Navbar;
