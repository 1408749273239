import React, { useLayoutEffect, useState } from 'react';

import {
  WebinarContainer,
  WebinarExp,
  WebinarImage,
  WebinarImageContainer,
  WebinarMobilePriceContainer,
  WebinarMobilePriceText,
  WebinarName,
  WebinarNameContainer,
  WebinarPlan,
  WebinarPlanText,
  WebinarPlanTitle,
  WebinarPrice,
  WebinarsContainer,
  WebinarsWrapper,
  WebinarTextContainer,
} from './index.styles';

import { useMutation, useQuery } from 'react-query';
import { userService } from '../../shared/services/user.service';
import { showToastError } from '../../shared/components/toast/toast';
import AppLoader from '../../shared/components/loader/loader';
import {
  ArticleAuthor,
  ArticleAuthorTitle,
  ArticleCategory,
  ArticleReturnBack,
  ArticleReturnImage,
  ArticleTitle,
} from '../article/index.styles';
import arrow from '../../assets/icons/arrow-rigth.svg';
import sliderImag1 from '../../assets/image/image1.png';
import { useNavigate, useParams } from 'react-router-dom';
import AppButton from '../../shared/components/app-button/AppButton';
import Recommendation from '../../shared/components/recommendation';
import { useWindowSize } from '../../shared/hooks/useScreenSize';
import { useAuth } from '../../store/auth';
import { paymentService } from '../../shared/services/payment.service';
import { RouterKeys } from '../../router/keys';

const WebinarPage = () => {
  let { id } = useParams<any>();
  const [webinarData, setWebinarData] = useState<any>();
  const [isBought, setIsBought] = useState<boolean>(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { isAuth } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //useAuthCheck();

  const getWebinar = useQuery<any, any>(
    ['webinarsById', { id }],
    () => userService.getWebinarById(id),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        setIsBought(!!res.data.id_video);
        setWebinarData(res.data);
        setIsLoading(false);
        //setTotalItems(res.data.total);
      },
      onError: () => {
        showToastError('Щось пішло не так');
        setIsLoading(false);
      },
    }
  );
  useLayoutEffect(() => {
    setIsLoading(true);
  }, []);

  const createPayment = useMutation(
    async (data: any) =>
      paymentService.createWebinarPaymentWebinar(data.amount, data.idWebinar),
    {
      onSuccess: response => {
        //console.log(response?.data);
        window.location.href = response?.data?.pageUrl;
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  const buyHandler = () => {
    if (isAuth)
      if (isBought) navigate(`/${RouterKeys.WEBINAR_VIDEO_BY_ID}/${id}`);
    if (!isBought)
      createPayment.mutate({ amount: webinarData.price, idWebinar: id });
    if (!isAuth) navigate('/sign-in');
  };

  if (isLoading) return <AppLoader />;
  return (
    <WebinarsWrapper>
      <WebinarsContainer>
        <ArticleReturnBack onClick={() => navigate(-1)}>
          <ArticleReturnImage src={arrow} alt="" />
          Назад
        </ArticleReturnBack>
        <ArticleTitle>{webinarData?.title && webinarData.title}</ArticleTitle>
        <ArticleAuthor>
          <ArticleAuthorTitle>Автор:</ArticleAuthorTitle>{' '}
          {webinarData?.author && webinarData?.author}
        </ArticleAuthor>
        <ArticleCategory>
          {webinarData?.categories[0].name && webinarData.categories[0].name}
        </ArticleCategory>
        <WebinarContainer>
          <WebinarImageContainer>
            <WebinarImage
              src={
                webinarData?.featured_image
                  ? webinarData?.featured_image
                  : sliderImag1
              }
              alt=""
            />
            {width >= 1050 && (
              <AppButton
                text={isBought ? 'Перейти до перегляду' : 'Купити'}
                onClick={buyHandler}
                width={'100%'}
              />
            )}
          </WebinarImageContainer>

          <WebinarTextContainer>
            <WebinarNameContainer>
              <WebinarName>
                {webinarData?.author && webinarData.author}
              </WebinarName>
              <WebinarExp>
                {webinarData?.content && webinarData.content}
              </WebinarExp>
            </WebinarNameContainer>
            {width >= 1050 ? (
              <WebinarPrice>
                Вартість: {webinarData?.price && webinarData.price} грн
              </WebinarPrice>
            ) : (
              <WebinarMobilePriceContainer>
                <WebinarMobilePriceText>
                  {webinarData?.price && webinarData.price} грн
                </WebinarMobilePriceText>
                <AppButton
                  text={isBought ? 'Перейти до перегляду' : 'Купити'}
                  onClick={buyHandler}
                  width={width <= 437 ? '130px' : '200px'}
                />
              </WebinarMobilePriceContainer>
            )}
          </WebinarTextContainer>
        </WebinarContainer>
        <WebinarPlan>
          <WebinarPlanTitle>Програма вебінару</WebinarPlanTitle>
          <WebinarPlanText>
            {webinarData?.plan && webinarData.plan}
          </WebinarPlanText>
        </WebinarPlan>
        <Recommendation />
      </WebinarsContainer>
    </WebinarsWrapper>
  );
};

export default WebinarPage;
