import React, { FC } from 'react';
import { useWindowSize } from '../../hooks/useScreenSize';
import AppButton from '../app-button/AppButton';
import { Container, PriceData, PriceText } from './index.styles';
import { paymentService } from '../../services/payment.service';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../store/auth';

type IPriceBox = {
  price: string;
  data: string;
  isWhite: boolean;
  id: number;
};

const PriceBox: FC<IPriceBox> = ({ price, data, isWhite, id }) => {
  const { width } = useWindowSize();
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const createPayment = useMutation(
    async (data: any) =>
      paymentService.createPaymentSub(data.amount, data.idSub),
    {
      onSuccess: response => {
        window.location.href = response?.data?.pageUrl;
      },
      onError: (error: any) => {
        console.log(error);
      },
    }
  );

  return (
    <Container isWhite={isWhite}>
      <PriceData>{data}</PriceData>
      <PriceText>{price} грн</PriceText>
      <AppButton
        onClick={() =>
          isAuth
            ? createPayment.mutate({ amount: Number(price), idSub: Number(id) })
            : navigate('/sign-in')
        }
        text="Обрати"
        width={width < 589 ? '120px' : '200px'}
        disabled={false}
      />
    </Container>
  );
};

export default PriceBox;
