import React from 'react';

const LiqPayFormComponent = ({
  data,
  signature,
}: {
  data: string;
  signature: string;
}) => {
  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.opacity = '0.5';
  };

  const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.opacity = '1';
  };

  const buttonStyle = {
    border: 'none',
    display: 'inline-block',
    textAlign: 'center' as 'center',
    padding: '5px 24px',
    color: '#fff',
    boxShadow:
      '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
    fontSize: '16px',
    lineHeight: '1.75',
    fontWeight: 600,
    fontFamily: "'Open Sans', sans-serif",
    cursor: 'pointer',
    borderRadius: '8px',
    background: '#77CC5D',
  };

  return (
    <form
      method="POST"
      acceptCharset="utf-8"
      target="_blank"
      action="https://www.liqpay.ua/api/3/checkout"
    >
      <input type="hidden" name="data" value={data} />
      <input type="hidden" name="signature" value={signature} />
      <button
        style={buttonStyle}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <img
          src="https://static.liqpay.ua/buttons/logo-white.svg"
          style={{ verticalAlign: 'middle' }}
        />
        <span style={{ verticalAlign: 'middle', marginLeft: '8px' }}>
          Сплатити
        </span>
      </button>
    </form>
  );
};

export default LiqPayFormComponent;
