import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../footer';
import Navbar from '../Navbar/Navbar';
import { HomeContainerWrapper } from './index.styled';

const HomeContainer = () => {
 
  return (
    <HomeContainerWrapper>
      <Navbar />
      <Outlet />
      <Footer/>
    </HomeContainerWrapper>
  );
};

export default HomeContainer;
