import styled from 'styled-components';
import { COLORS } from '../../theme';

export const ButtonWrapper = styled.button<{ width: string }>`
  width: ${({ width }) => width}; 
  font-family: 'Montserrat-Medium';
  background: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.COLORS.black};
  padding: 12px 54px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${COLORS.greyPrimary};;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Customize the shadow properties */
    background: #C5D7F3;
  }
  &:active {
    background: linear-gradient(20deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(91.33deg, #ffff 14.79%, #C5D7F3 84.18%);
  }
  @media only screen and (max-width:981px ) {
    font-size: 12px;
    padding: 9px 0px;
  }
`;

export const ButtonText = styled.p `
  
`