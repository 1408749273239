import styled from 'styled-components';

export const WebinarCardText = styled.div`
  font-size: 18px;
  font-family: 'Montserrat-Bold';
  margin-top: 10px;
  line-height: 1;
  text-align: start;
  @media only screen and (max-width: 818px) {
    margin-top: 0px;
  }
`;

export const WebinarCardTitle = styled.div`
  font-size: 18px;
  color: #000;
  font-family: 'Montserrat-SemiBold';
  @media only screen and (max-width: 818px) {
    font-size: 16px;
    font-family: 'Montserrat-SemiBold';
  }
`;

export const WebinarCardContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.5s;
  padding-bottom: 15px;
  border-bottom: 1px solid #abafc7;
  &:hover ${WebinarCardTitle} {
    text-shadow: rgba(13, 18, 130, 0.5) 1px 0 10px;
  }
  @media only screen and (max-width: 613px) {
    padding-bottom: 5px;
  }
  @media only screen and (max-width: 1030px) {
    flex-direction: column;
  }
`;

export const WebinarCardImage = styled.img`
  font-size: 18px;
  font-family: 'Montserrat-Bold';
  margin-top: 10px;
  line-height: 1;
  text-align: start;
  height: 300px;
  @media only screen and (max-width: 1030px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 818px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 613px) {
    width: 100%;
    height: auto;
  }
`;

export const WebinarCardTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
  @media only screen and (max-width: 818px) {
    margin-top: 20px;
    margin-left: 2px;
  }
`;

export const WebinarCardAuthor = styled.div`
  font-size: 18px;
  font-family: 'Montserrat-Regular';
  color: #03008a;
  display: flex;
  gap: 5px;
  @media only screen and (max-width: 613px) {
    font-size: 12px;
  }
`;

export const WebinarCardCategory = styled.div`
  font-size: 18px;
  font-family: 'Montserrat-Regular';
  color: #686868;
  margin: 10px 0;
  @media only screen and (max-width: 613px) {
    font-size: 13px;
    margin: 0;
  }
`;

export const WebinarCardPrice = styled.div`
  font-size: 18px;
  font-family: 'Montserrat-Bold';
  margin-top: 10px;
  line-height: 1;
  text-align: start;
  @media only screen and (max-width: 613px) {
    font-size: 16px;
  }
`;

export const WebinarButtonContainer = styled.div`
  margin: 30px 0 15px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 818px) {
    margin: 10px 0 15px 0;
  }
  @media only screen and (max-width: 440px) {
    align-items: center;
    gap: 6px;
    margin: 15px 0 5px 0;
  }
`;
