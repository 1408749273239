import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthWrapper from '../login';

import { ToastContainer } from 'react-toastify';
import { LoginTitle, RegisterContainer } from './index.style';

const RegisterSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <AuthWrapper>
      <RegisterContainer>
        <LoginTitle>
          Аккаунт успішно створено, очікуйте підтвердження на пошті
        </LoginTitle>
      </RegisterContainer>
      <ToastContainer />
    </AuthWrapper>
  );
};

export default RegisterSuccessPage;
