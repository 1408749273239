import styled from 'styled-components';

export const BurgerWrapper = styled.button`
  border: none;
  background: none;
`;

export const BurgerIcon = styled.img`
  //padding-left: 20px;
`;

export const MobileMenuWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 300px;
  height: 100%;
  background-color: #fcfcfc;
  transition: left 0.3s;
  z-index: 1000;
  padding: 20px 20px;
  @media only screen and (max-width:350px ) {
    width: 250px;
  }
`;

export const MobileMenuIcons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const MobileMenuButton = styled.button`
  border: none;
  background: none;
`;

export const MobileMenuAuth = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 22px;
  gap: 15px;
  padding-bottom: 22px;
  border-bottom: 1px solid #a0aac2;
`;

export const MobileMenuTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const MobileMenuTitle = styled.button`
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  border: none;
  background: none;
  margin: 0;
  padding:0;
  align-self: start;
  cursor: pointer;
`;

export const MobileMenuText = styled.div`
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  color: #686868;
  width: 80%;
`;

export const MobileMenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin-top: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #a0aac2;
`;

export const MobileMenuLink = styled.button`
  font-size: 16px;
  font-family: 'Montserrat-Medium';
  border: none;
  background: none;
  cursor: pointer;
`;

export const MobileMenuSocialTitle = styled.div`
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  color: #686868;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const MobileMenuLogout = styled.div`
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  color: #686868;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
  background: none;
`;

export const MobileMenuSocialIcons = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 22px;
  border-bottom: 1px solid #a0aac2;
`;

export const MobileMenuClose = styled.img`
  margin-right: 20px;
`;

export const MobileMenuAuthText = styled.div`
display: flex;
gap: 16px;
font-size: 16px;
font-family: 'Montserrat-SemiBold';
align-items: center;
`;

export const MobileMenuAuthContainer = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`;