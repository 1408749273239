import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  showToastError,
  showToastSuccess,
} from '../../../shared/components/toast/toast';
import {
  HomeWebinarsTitle,
  ArticlesTitleText,
} from '../../home/components/webinars/home-webinars.styles';
import { CategoriesSelect, StyledSelect } from '../../articles/index.styles';
import { articleConstButtons } from '../../../shared/utils';
import Pagination from '../../../shared/components/paginations';
import { userService } from '../../../shared/services/user.service';
import { useMutation, useQuery } from 'react-query';
import { adminService } from '../../../shared/services/admin/admin.service';
import {
  AdminContentContainer,
  AdminContentWrapper,
} from 'shared/components/admin/AdminContent.styled';
import WebinarCardAdmin from 'shared/components/admin/webinarCard';

const AdminEditWebinarsPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [listOfWebinars, setListOfWebinars] = useState<any>();
  const [listOfWebinarsPurchases, setListOfWebinarsPurchases] = useState<any>();
  const [listOfFilteredWebinars, setListOfFilteredWebinars] = useState<any>();
  const [categoryID, setCategoryID] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);

  const { isLoading: load, refetch: articleRefetch } = useQuery<any, any>(
    ['webinarsByCategory', { limit, page, categoryID }],
    () => userService.getWebinarsByCategory(categoryID, limit, page),
    {
      keepPreviousData: true,
      enabled: categoryID !== null,
      onSuccess(res: any) {
        console.log(res.data);
        setListOfFilteredWebinars(res.data.data);
        //setWebinarData(res.data);
        setTotalItems(res.data.total);
      },
      onError: () => {
        showToastError('Щось пішло не так');
      },
    }
  );

  const { isLoading, refetch } = useQuery<any, any>(
    ['webinars', { limit, page }],
    () => userService.getWebinars(limit, page),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        console.log(res.data.data);
        setListOfWebinars(res.data.data);
        setTotalItems(res.data.total);
      },
      onError: () => {
        showToastError('Щось пішло не так');
      },
    }
  );

  const { isLoading: isLoadingPurchase, refetch: refetchPurchase } = useQuery<
    any,
    any
  >(
    ['webinar-purchases'],
    () => userService.getWebinarsPurchases(),

    {
      keepPreviousData: true,
      onSuccess(res: any) {
        console.log(res.data);
        setListOfWebinarsPurchases(res.data);
      },
      onError: () => {
        showToastError('Щось пішло не так');
      },
    }
  );

  const handlePageChange = (newPage: any) => {
    setPage(newPage);
  };

  const filterHandler = (id: number) => {
    setCategoryID(id);
  };

  const handleChange = (selectedOption: any) => {
    setCategoryID(selectedOption.id);
    setCategory(selectedOption);
  };

  const deleteWebinar = useMutation(
    async (id: any) => adminService.deleteWebinarById(id),
    {
      onSuccess: res => {
        showToastSuccess('Успішно видалено');
        articleRefetch();
        refetchPurchase();
        refetch();
      },
      onError: (error: any) => {
        console.log(error);
        showToastError('не змогли видалити');
      },
    }
  );

  const handleDelete = (id: any) => {
    deleteWebinar.mutate(id);
    console.log(id);
  };

  return (
    <AdminContentWrapper>
      <ToastContainer />
      <AdminContentContainer>
        <HomeWebinarsTitle>
          <ArticlesTitleText>Вебінари</ArticlesTitleText>
        </HomeWebinarsTitle>
        <CategoriesSelect>
          <StyledSelect
            options={articleConstButtons}
            getOptionLabel={options => options['name']}
            placeholder={'Категорія'}
            getOptionValue={options => options['id']}
            value={category}
            onChange={values => handleChange(values)}
          />
        </CategoriesSelect>

        {categoryID
          ? listOfFilteredWebinars?.map((item: any) => {
              return (
                <WebinarCardAdmin
                  key={item.title}
                  onClick={() => handleDelete(item.id)}
                  id={item.id}
                  author={item.author}
                  title={item.title}
                  category={item.categories[0].name}
                  price={item.price}
                  imageUrl={item.featured_image}
                  buttonText={'Видалити'}
                />
              );
            })
          : listOfWebinars?.map((item: any) => {
              const purchases = listOfWebinarsPurchases.find(
                (purchase: any) => {
                  return (purchase.titlewebinar = item.title);
                }
              );
              return (
                <WebinarCardAdmin
                  key={item.title}
                  onClick={() => handleDelete(item.id)}
                  id={item.id}
                  author={item.author}
                  title={item.title}
                  category={item.categories[0].name}
                  price={item.price}
                  imageUrl={item.featured_image}
                  buttonText={'Видалити'}
                  purchases={purchases['purchase_count']}
                />
              );
            })}
        <div style={{ marginTop: 40 }}>
          <Pagination
            totalItems={totalItems}
            itemsPerPage={limit}
            currentPage={page}
            onPageChange={handlePageChange}
          />
        </div>
      </AdminContentContainer>
    </AdminContentWrapper>
  );
};

export default AdminEditWebinarsPage;
