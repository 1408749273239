import styled from 'styled-components';
import Select from 'react-select';

export const ArticlesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.01);
`;

export const ArticlesContainer = styled.div`
  width: 1240px;
  margin-top: 50px;
  @media only screen and (max-width: 1265px) {
    max-width: 1100px;
  }
  @media only screen and (max-width: 1120px) {
    max-width: 1000px;
  }
  @media only screen and (max-width: 1030px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 818px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 613px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 407px) {
    max-width: 100%;
    padding: 0 10px 0 10px;
  }
`;

export const ArticlesListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  width: 100%;
`;

export const ArticlesBanner = styled.div`
  width: 100%;
  height: 630px;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #010101 0%, #011d45 50%, #013482 100%);
  @media only screen and (max-width: 1336px) {
    height: 630px;
  }
  @media only screen and (max-width: 630px) {
    height: 450px;
  }
`;

export const ArticlesBannerContent = styled.div`
  display: flex;
  width: 1240px;
  padding: 168px 0;
  @media only screen and (max-width: 1336px) {
    padding: 20px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 800px;
  }
  @media only screen and (max-width: 630px) {
    padding: 0px 0;
  }
`;

export const ArticlesBannerText = styled.div`
  color: white;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 50px;
  @media only screen and (max-width: 1336px) {
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 630px) {
    gap: 20px;
  }
`;

export const ArticlesBannerHeader = styled.div`
  color: white;
  font-size: 36px;
  font-family: Montserrat-SemiBold;
  @media only screen and (max-width: 1030px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 630px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 630px) {
    font-size: 17px;
  }
  @media only screen and (max-width: 334px) {
    font-size: 14px;
  }
`;

export const ArticlesBannerPrice = styled.div`
  display: flex;
  gap: 40px;
  @media only screen and (max-width: 480px) {
    gap: 15px;
  }
`;

export const ArticlesButtons = styled.div`
  display: flex;
  gap: 20px;
  @media only screen and (max-width: 334px) {
    gap: 10px;
  }
`;

export const ArticlesButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
export const ArticlesButton = styled.div`
  border: 1px solid #a0aac2;
  color: white;
  padding: 12px;
  border-radius: 8px;
  @media only screen and (max-width: 630px) {
    font-size: 12px;
    padding: 8px;
  }
  @media only screen and (max-width: 334px) {
    font-size: 9px;
    padding: 6px;
  }
`;

export const ShowAll = styled.button`
  font-size: 20px;
  font-family: Montserrat-SemiBold;
  color: #0d1282;
  margin: 0 0 10px 10px;
  border: none;
  cursor: pointer;
  background: none;
`;

export const StyledSelect = styled(Select)`
  .react-select__control {
    border: 1px solid red;
    border-radius: 8px;
    width: 50%;
    border-right: none;
  }
  .react-select__menu {
    background-color: red;
  }
`;

export const CategoriesSelect = styled.div`
  align-items: center;
  margin: 0 10px 10px 10px;
`;

export const LoaderContainer = styled.div`
  height: 60vh;
`;
