import React, { useEffect } from 'react';
import { fingerprintHandler } from '../../shared/utils/fingerprint';
import { useMutation } from 'react-query';
import { userService } from '../../shared/services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import AppLoader from '../../shared/components/loader/loader';

const AddNewDevice = () => {
  const navigate = useNavigate();
  const params = useParams();
  const token = params?.token || '';

  useEffect(() => {
    const fetchFingerprint = async () => {
      const id = await fingerprintHandler();
      if (id && token) {
        return await addNewDeviceHandle(id, token);
      }
      return navigate('/');
    };

    fetchFingerprint();
  }, []);

  const addNewDevice = useMutation(
    async (body: any) => userService.addNewDevice(body),
    {
      onSuccess: async (res: any) => {
        if (res.data) {
          return navigate('/');
        }
      },
    }
  );

  const addNewDeviceHandle = async (visitorId: string, token: string) => {
    addNewDevice.mutate({
      visitorId: visitorId,
      token: token,
    });
  };
  return (
    <>
      <AppLoader />
    </>
  );
};

export default AddNewDevice;
