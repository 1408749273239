import styled from 'styled-components';

export const OfferWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferContainer = styled.div`
  max-width: 1240px;
  margin: 0 20px;
  //  flex-direction: column;
`;

export const OffetbigTitle = styled.p`
  font-family: Montserrat-SemiBold;
  font-size: 23px;
  margin: 10px 0 10px 0;
`;

export const OfferTitle = styled.p`
  font-family: Montserrat-SemiBold;
  font-size: 17px;
  margin: 10px 0 10px 0;
`;
export const OfferTitleNewLine = styled.p`
  font-family: Montserrat-SemiBold;
  font-size: 17px;
  margin: -10px 0 10px 0;
`;

export const OfferNumberOffset = styled.p`
font-family: Montserrat-Medium;
font-size: 14px;
margin-left: 72px;
`;

export const OfferText = styled.p`
  font-family: Montserrat-Medium;
  font-size: 14px;
`;
export const OfferTextTitle = styled.p`
  font-family: Montserrat-Medium;
  font-size: 14px;
  margin: 10px 0;
`;
