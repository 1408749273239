import { useState } from 'react';
import { useQuery } from 'react-query';
import { adminService } from '../services/admin/admin.service';
import { IOptions } from '../types';

export const useGetAllCategories = () => {
  const [options, setOptions] = useState<IOptions[]>();

  const call = useQuery<any, any>(
    'categories',
    () => adminService.getAllCategories(),
    {
      keepPreviousData: true,
      onSuccess: (data: any) => {
        if (data) setOptions(data?.data);
      },
      onError: error => {
        console.error('Query encountered an error:', error);
      },
    }
  );
  return { options: options };
};
