import React, { FC } from 'react';

import { LinkContainer } from './index.styles';

import LinkIconSvg from '../../../assets/icons/link-icon.svg';
import { Link } from 'react-router-dom';

type ILinkIcon = {
  text: string;
  href: string;
};

const LinkIcon: FC<ILinkIcon> = ({ text, href }) => {
  return (
    <LinkContainer>
      <Link to={href} style={{ color: '#0D1282' }}>
        {text}
      </Link>
      <img src={LinkIconSvg} alt="link" width="21px" />
    </LinkContainer>
  );
};

export default LinkIcon;
