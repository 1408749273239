import React, { FC } from 'react';

import {
  LoginContainer,
  LoginWrapper,
  LogoImage,
} from './index.styles';
import logo from '../../../assets/icons/IQDental.svg';

import { useWindowSize } from '../../../shared/hooks/useScreenSize';
import { useNavigate } from 'react-router-dom';
import { RouterKeys } from '../../../router/keys';

const AuthWrapper: FC<any> = ({ children }) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  return (
    <LoginContainer>
        {/* <LoginTitle>Увійти</LoginTitle> */}
        <LogoImage onClick={() => navigate(RouterKeys.HOME)}>
          <img src={logo} alt="logo" />
        </LogoImage>

      <LoginWrapper>{children}</LoginWrapper>
    </LoginContainer>
  );
};

export default AuthWrapper;
